import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "edgeThousandth",
})
export class ThousandthPipe implements PipeTransform {
    transform(value: number | string): string {
        if (value == null) {
            return "";
        }
        return value.toLocaleString().replace(/\B(\?<!\.\d*)(\?=(\d{3})\+(\?!\d))/g, ",");
    }
}
