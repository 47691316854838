import { Component, Input, ViewEncapsulation } from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ValidationService } from "@shared/services/validation.service";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "validation-error",
    templateUrl: "./validation-error.component.html",
    encapsulation: ViewEncapsulation.None,
})
export class ValidationErrorComponent {
    @Input() control: UntypedFormControl;
    @Input() customMessages: { [error: string]: string };
    // List of the error keys, which should be displayed in the first priority
    // Lower index - higher priority
    @Input() prioritizedErrors?: string[];

    constructor(private validationService: ValidationService) {}

    public get errorMessages(): string | null {
        if (!this.control || !this.control.errors || !Object.keys(this.control.errors).length) {
            return null;
        }

        if (this.prioritizedErrors) {
            for (const key of this.prioritizedErrors) {
                if (this.control.errors.hasOwnProperty(key)) {
                    return this.getSpecificErrorMessage(key);
                }
            }
        }

        const firstErrorName = Object.keys(this.control.errors)[0];
        return this.getSpecificErrorMessage(firstErrorName);
    }

    private getSpecificErrorMessage(errorName: string): string {
        if (this.customMessages && this.customMessages[errorName]) {
            return this.customMessages[errorName];
        }

        return this.validationService.getValidatorErrorMessage(
            errorName,
            this.control.errors[errorName]
        );
    }
}
