import { MatDateFormats } from "@angular/material/core";

// Interface for CustomDateAdapter
// Update this class if we will change implementation to something different
// Read more here about adapters:
// https://material.angular.io/components/datepicker/overview#choosing-a-date-implementation-and-date-format-settings
// Available formats:
// https://day.js.org/docs/en/display/format
export abstract class DateFormats implements MatDateFormats {
    parse: {
        dateInput: string | string[];
    };
    display: {
        dateInput: string;
        monthYearLabel: string;
        dateA11yLabel: string;
        monthYearA11yLabel: string;
    };
}

export const CUSTOM_DATE_FORMATS: DateFormats = {
    parse: {
        dateInput: [
            // Pay attention at sequence. Firstly pass long format, only after that short,
            // otherwise year "2024" may be parsed as "2020", because short format this is just first two numbers
            "M/D/YYYY",
        ],
    },
    display: {
        dateInput: "MM/DD/YYYY",
        monthYearLabel: "MMM YYYY",
        dateA11yLabel: "MMM DD, YYYY",
        monthYearA11yLabel: "MMMM YYYY",
    },
};
