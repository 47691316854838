<footer>
    <div class="shell llayout-footer">
        <a href="https://www.databank.com" class="logo-vertical">
            DataBank Home
        </a>
        <nav class="llayout-footer__nav">
            <ul class="llayout-footer__nav-list">
                <li class="llayout-footer__nav-item">
                    <a class="llayout-footer__nav-link"
                       href="https://www.databank.com/privacy">
                        Privacy Policy
                    </a>
                </li>
                <li class="llayout-footer__nav-item">
                    <a class="llayout-footer__nav-link"
                       href="https://www.databank.com/terms-of-use/">
                        Terms of Use
                    </a>
                </li>
            </ul>
        </nav>
        <edge-copyright></edge-copyright>
    </div>
</footer>
