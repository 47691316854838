import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "edgeJoin",
})
export class JoinPipe implements PipeTransform {
    transform(array: any[], separator: string = ", ", propertyName?: string): string {
        if (!array) {
            return "";
        }

        array = array.filter(item => item);
        if (propertyName) {
            array = array.map(item => item[propertyName]);
        }

        return array.join(separator);
    }
}
