import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { EMPTY, Observable, of } from "rxjs";
import { catchError, switchMap } from "rxjs/operators";
import { UserApiService } from "@core/services/user-api.service";
import { OrganizationStorageService } from "@core/services/storages/organization-storage.service";
import { RouterHelper } from "@core/helpers/router-helper";

@Injectable({
    providedIn: "root",
})
export class OrganizationResolver implements Resolve<void> {
    constructor(
        private userAPIService: UserApiService,
        private organizationStorageService: OrganizationStorageService,
        private router: Router
    ) {}

    resolve(route: ActivatedRouteSnapshot): Observable<void> {
        if (RouterHelper.getRouteDataValue("skipOrganizationResolver", route)) {
            return of(null);
        }

        const organizationId = route.queryParamMap.get("organizationId");
        if (organizationId) {
            const organizations = this.organizationStorageService.all;
            const organization = organizations.find(item => item.id === organizationId);

            if (organization) {
                const { userId } = organization;

                return this.userAPIService
                    .getAndSaveUserProfile({
                        userId,
                        organizationId,
                    })
                    .pipe(
                        catchError(() => {
                            return of(null);
                        }),
                        switchMap(() => EMPTY)
                    );
            }
            this.router.navigate(["access-denied"]);
            return of(null);
        }

        return of(null);
    }
}
