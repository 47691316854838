import { Injectable } from "@angular/core";
import dayjs from "dayjs";
import { TimeframeInterval } from "@core/interfaces/timeframe.interface";

@Injectable({
    providedIn: "root",
})
export class MonitoringEM7Service {
    private _monitoringLink: string;

    get monitoringLink(): string {
        return this._monitoringLink;
    }

    set monitoringLink(value: string) {
        this._monitoringLink = value;
    }

    get dashboardPage(): string {
        return `${this._monitoringLink}/em7/index.em7?exec=dashboards`;
    }

    get dashboardPageEventTab(): string {
        return `${this.monitoringLink}/em7/index.em7?exec=events`;
    }

    public getAlarmHistoryLink(did: number): string {
        return `${this.monitoringLink}/em7/index.em7?exec=device_events&did=${did}&q_sort=&show_closed=1`;
    }

    public addTimeFrameToUrl(url: string, frameInterval: TimeframeInterval): string {
        const dateFormat = "MM/DD/YYYY";
        const timeFormat = "HH:mm";

        const startDate = dayjs(frameInterval.startDate).format(dateFormat);
        const startTime = dayjs(frameInterval.startDate).format(timeFormat);
        const endDate = dayjs(frameInterval.endDate).format(dateFormat);
        const endTime = dayjs(frameInterval.endDate).format(timeFormat);

        return `${url}&q_date_start=${encodeURIComponent(startDate)}+${encodeURIComponent(
            startTime
        )}&q_date_end=${encodeURIComponent(endDate)}+${encodeURIComponent(endTime)}`;
    }
}
