import { IEnvironment } from "@core/interfaces/environment.interface";

export const environment: IEnvironment = {
    production: false,
    protocol: "https",
    hostname: "StagePortalAPI.databank.com",
    debug: false,
    idle: {
        sessionTime: 900, // 15min * 60sec = 900sec
        warningTime: 120, // 2min * 60sec = 120sec
    },
    hotjarId: 3605619
};
