/* eslint-disable @typescript-eslint/member-ordering */
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import { GlobalCacheService } from "@core/services/global-cache.service";
import { GlobalCacheKeys } from "@core/enums/global-cache-keys.enum";
import { HttpClient } from "@angular/common/http";
import { ISelectOption } from "@shared/modules/controls/select/select.interface";
import { webApiUrls } from "@core/constants/url.constant";
import { NewsItem } from "@shared/interfaces/news.interface";
import {
    ICountryRegionLookup,
    iDatacenter,
    IRoleLookup,
    ITagLookup,
    ITimezoneLookup,
    IUserWithOrgLevel,
} from "../interfaces/common-lookups.interface";

@Injectable({
    providedIn: "root",
})
export class CommonLookupsApiService {
    constructor(private http: HttpClient) { }

    /** ********************* Addresses ********************** */

    private countries: ICountryRegionLookup[] = [];
    public getCountries(): Observable<ICountryRegionLookup[]> {
        if (this.countries?.length) {
            return of(this.countries);
        }
        const path = `${webApiUrls.accountManagement}/Countries`;
        return this.http.get(path).pipe(
            map((data: ICountryRegionLookup[]) => data.sort((a, b) => a.sequence - b.sequence)),
            tap((data: ICountryRegionLookup[]) => {
                this.countries = data;
            })
        );
    }

    private regions: ICountryRegionLookup[] = [];
    public getRegions(): Observable<ICountryRegionLookup[]> {
        if (this.regions?.length) {
            return of(this.regions);
        }
        const path = `${webApiUrls.accountManagement}/Regions`;
        return this.http.get(path).pipe(
            map((data: ICountryRegionLookup[]) => data.sort((a, b) => a.sequence - b.sequence)),
            tap((data: ICountryRegionLookup[]) => {
                this.regions = data;
            })
        );
    }

    public getNews(): Observable<NewsItem[]> {
        const path = `${webApiUrls.eventManagement}/LatestNews`;
        return this.http.get<NewsItem[]>(path);
    }

    public getCountriesOptions(): Observable<ISelectOption[]> {
        return this.getCountries().pipe(
            map(items => items.map(i => ({ value: i.valueListEntryId, label: i.entry })))
        );
    }

    public getRegionsOptions(): Observable<ISelectOption[]> {
        return this.getRegions().pipe(
            map(items => items.map(i => ({ value: i.entry, label: i.description })))
        );
    }

    /** ********************* Organization Users ********************** */

    private orgLevelUsers: IUserWithOrgLevel[] = [];
    public getUsersWithOrgLevel(): Observable<IUserWithOrgLevel[]> {
        if (this.orgLevelUsers?.length) {
            return of(this.orgLevelUsers);
        }
        const path = `${webApiUrls.accountManagement}/Users/UsersWithOrgLevel`;
        return this.http.get(path).pipe(
            tap((data: IUserWithOrgLevel[]) => {
                this.orgLevelUsers = data;
            })
        );
    }

    public getRolesLookup(): Observable<IRoleLookup[]> {
        const cachedData = GlobalCacheService.getCopy(GlobalCacheKeys.Roles) as IRoleLookup[];
        if (cachedData?.length) {
            return of(cachedData);
        }

        const path = `${webApiUrls.accountManagement}/Roles`;
        return this.http
            .get(path)
            .pipe(
                tap((data: IRoleLookup[]) => GlobalCacheService.set(GlobalCacheKeys.Roles, data))
            );
    }

    private timezones: ITimezoneLookup[] = [];
    public getTimezonesLookup(): Observable<ITimezoneLookup[]> {
        if (this.timezones?.length) {
            return of(this.timezones);
        }
        const path = `${webApiUrls.accountManagement}/TimeZones`;
        return this.http.get(path).pipe(
            tap((data: ITimezoneLookup[]) => {
                this.timezones = data;
            })
        );
    }

    public getTags(): Observable<ITagLookup[]> {
        const cachedData = GlobalCacheService.getCopy(GlobalCacheKeys.Tags) as ITagLookup[];
        if (cachedData?.length) {
            return of(cachedData);
        }

        const path = `${webApiUrls.accountManagement}/Tags`;
        return this.http
            .get(path)
            .pipe(tap((data: ITagLookup[]) => GlobalCacheService.set(GlobalCacheKeys.Tags, data)));
    }

    // Available datacenters for user's organization
    public getLocations(): Observable<iDatacenter[]> {
        const cachedData = GlobalCacheService.getCopy(GlobalCacheKeys.Locations) as iDatacenter[];
        if (cachedData?.length) {
            return of(cachedData);
        }
        return this.http
            .get(`${webApiUrls.accountManagement}/Users/DataCenters/All`)
            .pipe(
                tap((data: iDatacenter[]) =>
                    GlobalCacheService.set(GlobalCacheKeys.Locations, data)
                )
            );
    }

    // Billable Ports & Assets
    public getBillablePortsAssets(): Observable<string[] | null> {
        const cachedData = GlobalCacheService.getCopy(
            GlobalCacheKeys.BillablePortsAssets
        ) as string[];
        if (cachedData?.length) {
            return of(cachedData);
        }
        return this.http
            .get(`${webApiUrls.eventManagement}/NetworkData/BillablePortsAssets`)
            .pipe(
                tap((data: string[]) =>
                    GlobalCacheService.set(GlobalCacheKeys.BillablePortsAssets, data)
                )
            );
    }

    // Prefixes
    public getPrefixes(): Observable<string[]> {
        const cachedData = GlobalCacheService.getCopy(GlobalCacheKeys.Prefixes) as string[];
        if (cachedData?.length) {
            return of(cachedData);
        }
        return this.http
            .get(`${webApiUrls.eventManagement}/NetworkData/Prefixes`)
            .pipe(tap((data: string[]) => GlobalCacheService.set(GlobalCacheKeys.Prefixes, data)));
    }

    // zColo Ports
    public getZColoPorts(): Observable<string[]> {
        const cachedData = GlobalCacheService.getCopy(GlobalCacheKeys.ZColoPorts) as string[];
        if (cachedData?.length) {
            return of(cachedData);
        }
        return this.http
            .get(`${webApiUrls.eventManagement}/NetworkData/ZColoPorts`)
            .pipe(
                tap((data: string[]) => GlobalCacheService.set(GlobalCacheKeys.ZColoPorts, data))
            );
    }
}
