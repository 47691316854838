<router-outlet #appOutlet="outlet"></router-outlet>

<ng-container *ngIf="!appOutlet.isActivated">
    <!-- Spinner will be displayed until first route get activated -->
    <div class="app-spinner-container preloader">
        <!-- Databank Logo -->
        <svg enable-background="new 0 0 356.8 83.5" viewBox="0 0 356.8 83.5" xmlns="http://www.w3.org/2000/svg" width="300"><g fill="#1F3250"><path d="m108.4 31.1h-5.9v20.4h5.9c6.3 0 10.4-4.2 10.4-10.2s-4.1-10.2-10.4-10.2zm0 25.2h-11.2v-30h11.2c9.4 0 15.9 6.5 15.9 15s-6.5 15-15.9 15z"/><path d="m143.8 32.4-5.1 12h10.3zm15.7 23.9h-5.5l-3-7.2h-14.2l-3.1 7.2h-5.4l13.2-30.2h4.9z"/><path d="m175.7 56.3h-5.3v-25.1h-9.6v-4.9h24.4v4.9h-9.5z"/><path d="m202 32.4-5.1 12h10.3zm15.8 23.9h-5.6l-3-7.2h-14.2l-3.1 7.2h-5.4l13.2-30.2h4.9z"/><path d="m244.8 47.5c0-2.6-2-4.1-6.2-4.1h-8.4v8.2h8.7c3.7 0 5.9-1.4 5.9-4.1zm-1.7-12.7c0-2.4-1.9-3.9-5.3-3.9h-7.6v8h7.2c3.4 0 5.7-1.3 5.7-4.1zm5.3-.9c0 3.7-2.1 5.6-4.3 6.7 3.5 1.2 6 3.2 6 7.3 0 5.4-4.4 8.3-11.1 8.3h-13.9v-30h13.5c6 .1 9.8 3 9.8 7.7z"/><path d="m271.1 32.4-5.1 12h10.3zm15.8 23.9h-5.6l-3-7.2h-14.2l-3.1 7.2h-5.4l13.2-30.2h4.9z"/><path d="m315.1 26.3h5.2v30h-4.4l-16.5-21.4v21.4h-5.2v-30h4.8l16.1 20.7z"/><path d="m356.8 56.3h-6.4l-10.3-13.5-4.7 4.8v8.7h-5.2v-30h5.2v15.1l14.3-15.1h6.5l-12.5 12.9z"/></g><path d="m70.6 58-25.9 19.2c-1.1.9-2.1.1-2.1.1-.7-.5-.6-1.5-.6-1.5v-16.6l19-14 1.5 1.1 8.2 6.1c1.2.8 1.8 1.8 1.8 2.8-.1.9-.8 1.9-1.9 2.8zm-62.2 19.1c-1.2.9-2.2.1-2.2.1-.8-.5-.7-1.6-.7-1.6v-67.8s-.1-1 .7-1.6c0 0 1-.8 2.2.1l28.2 20.9v2.3l-.1 26.8zm47.8-35.3-14.3 10.6v-18.9-2.2l12.7 9.4zm-14.3-19.6v-14.4s-.1-1 .6-1.5c0 0 1-.8 2.1.1l25.9 19.2c1.1.8 1.8 1.8 1.8 2.8s-.7 2-1.8 2.8l-9.7 7.2-1.5-1.1-17.4-12.9zm31.7 25.6-6.4-5-1.7-1.2 8.1-6c2.6-1.8 4.2-4.5 4.2-7.2s-1.4-5.5-4-7.3l-25.9-19.2c-2.7-2-5.6-2.4-7.9-1.3-2.3 1.2-3.6 3.8-3.6 7.1l.1 10.5v2.1l-25-18.6c-2.8-1.8-5.6-2.2-7.9-1s-3.6 3.8-3.6 7.1v67.8c0 3.4 1.3 6 3.6 7.1 2.3 1.2 5.2.8 7.9-1.1l25-18.4v12.6c0 3.4 1.3 6 3.6 7.1 2.3 1.2 5.1.7 7.9-1.3l25.9-19.2c2.6-1.9 4.1-4.5 4-7.3s-1.6-5.4-4.3-7.3z" fill="#e26030"/></svg>

        <svg class="edge-spinner" width="70" height="70" viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg">
            <circle class="path" stroke="#e26030" fill="none" stroke-width="6" stroke-linecap="round" cx="35" cy="35" r="30">
            </circle>
        </svg>
    </div>
</ng-container>