import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { ReactiveFormsModule } from "@angular/forms";
import { ValidationErrorModule } from "@shared/modules/controls/validation-error/validation-error.module";
import { CoreModule } from "@core/core.module";
import { SharedModule } from "@shared/shared.module";
import { EdgeDatePipe } from "@core/pipes/edge-date.pipe";
import { TopBarTimeframeDialogComponent } from "./components/top-bar-timeframe-dialog/top-bar-timeframe-dialog.component";
import { TopBarTimeframeComponent } from "./components/top-bar-timeframe/top-bar-timeframe.component";
import { SidebarComponent } from "./components/sidebar/sidebar.component";
import { PortalLayoutShellComponent } from "./containers/portal-layout-shell/portal-layout-shell.component";
import { PortalFooterComponent } from "./components/footer/footer.component";
import { TopBarShellComponent } from "./containers/top-bar-shell/top-bar-shell.component";
import { TopBarOrganizationComponent } from "./components/top-bar-organization/top-bar-organization.component";
import { TopBarUserinfoComponent } from "./components/top-bar-userinfo/top-bar-userinfo.component";
import { TopBarAlarmsComponent } from "./components/top-bar-alarms/top-bar-alarms.component";
import { TopBarTicketsComponent } from "./components/top-bar-tickets/top-bar-tickets.component";

@NgModule({
    declarations: [
        PortalLayoutShellComponent,
        SidebarComponent,
        TopBarTimeframeComponent,
        TopBarTimeframeDialogComponent,
        PortalFooterComponent,
        TopBarShellComponent,
        TopBarOrganizationComponent,
        TopBarUserinfoComponent,
        TopBarAlarmsComponent,
        TopBarTicketsComponent,
    ],
    imports: [
        CoreModule,
        CommonModule,
        SharedModule,
        RouterModule,
        ReactiveFormsModule,
        ValidationErrorModule,
    ],
    exports: [PortalLayoutShellComponent],
    providers: [EdgeDatePipe],
})
export class PortalLayoutModule {}
