import { IEnvironment } from "@core/interfaces/environment.interface";
import { environment } from "src/environments/environment";

function initHotJar(env: IEnvironment) {
    if (env.hotjarId) {
        (function(h,o,t,j,a,r){
            h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
            h._hjSettings={hjid:env.hotjarId,hjsv:6};
            a=o.getElementsByTagName("head")[0];
            r=o.createElement("script");r.async=1;
            r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
            a.appendChild(r);
        })(window as any,document,"https://static.hotjar.com/c/hotjar-",".js?sv=");
    }
}

initHotJar(environment);
