import { Pipe, PipeTransform } from "@angular/core";

/**
 * Convert second to human minutes/hour/days
 * Example: 90 => 1m, 9700 => 2 h,
 * @param isFloat Result with floating number
 * Example: 90 => 1.5m, 9700 => 2.69 h
 * Todo: Refactor
 */
@Pipe({ name: "edgeHumanizeSeconds" })
export class EdgeHumanizeSecondsPipe implements PipeTransform {
    transform(value: number | string, isFloat: boolean = false): string {
        const seconds = +value || 0;
        return isFloat ? this.getFloatHumanSecond(seconds) : this.getHumanSecond(seconds);
    }

    private getFloatHumanSecond(seconds: number): string {
        const minutes = +(seconds / 60).toFixed(1);
        const hours = +(seconds / 60 / 60).toFixed(2);
        const days = +(seconds / 60 / 60 / 24).toFixed(2);

        let result = "0";

        if (seconds < 60) {
            result = `${seconds} sec`;
        } else if (seconds >= 60 && seconds < 3600) {
            result = `${minutes} min`;
        } else if (seconds >= 3600 && seconds < 86400) {
            result = `${hours} hr`;
        } else if (seconds >= 86400) {
            result = `${days} d`;
        }

        return result;
    }

    private getHumanSecond(seconds: number): string {
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        let result = "";

        if (days) {
            result = `${days} d ${hours - days * 24} h`;
        } else if (hours) {
            result = `${hours} h`;
        } else if (minutes) {
            result = `${minutes} min`;
        } else if (seconds) {
            result = `${seconds} sec`;
        } else {
            result = "0";
        }

        return result;
    }
}
