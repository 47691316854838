import { Component, Input, OnInit } from "@angular/core";
import { FormGroup, Validators } from "@angular/forms";
import { merge } from "rxjs";
import { debounceTime, tap } from "rxjs/operators";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { ValidationService } from "../../services/validation.service";
import { ServerError } from "../../interfaces/password-manager.interfce";
import { UserSecurityForm } from "../../interfaces/user.interface";

enum PasswordNameEnum {
    oldPassword = "oldPassword",
    newPassword = "newPassword",
    confirmPassword = "confirmPassword",
}

@UntilDestroy()
@Component({
    selector: "edge-password-manager",
    templateUrl: "./password-manager.component.html",
    styleUrls: ["./password-manager.component.scss"],
})
export class PasswordManagerComponent implements OnInit {
    @Input() form: FormGroup<UserSecurityForm>;
    @Input() isRequired = false;
    @Input() showHints = false;
    @Input() serverError: ServerError;
    @Input() userName: string;
    @Input() oldPasswordRequired = true;

    public get f(): UserSecurityForm {
        return this.form.controls;
    }

    public ngOnInit(): void {
        if (this.isRequired) {
            this.setPasswordValidation();
        }
        this.initValidation();
    }

    private initValidation() {
        merge(
            this.f.oldPassword
                .valueChanges
                .pipe(
                    tap(() => this.serverError.oldPassword = false)
                ),
            this.f.newPassword
                .valueChanges
                .pipe(
                    tap(() => this.serverError.newPassword = false)
                ),
            this.f.confirmPassword.valueChanges
        )
            .pipe(
                untilDestroyed(this),
                debounceTime(250)
            )
            .subscribe(() => {
                if (
                    this.f.oldPassword.value
                    ||
                    this.f.newPassword.value
                    ||
                    this.f.confirmPassword.value
                    ||
                    this.isRequired
                ) {
                    this.setPasswordValidation();
                } else {
                    this.removePasswordValidation();
                }
                ValidationService.updateValueAndValidity(this.form, false);
            });
    }

    private setPasswordValidation(): void {
        if (this.oldPasswordRequired) {
            this.f.oldPassword
                .setValidators([
                    Validators.required,
                    ValidationService.oldPasswordValidator(this.serverError.oldPassword),
                ]);
        }
        this.f.newPassword
            .setValidators([
                Validators.required,
                Validators.minLength(12),
                ValidationService.passwordComplexityValidator,
                ValidationService.newPasswordValidator(this.serverError.newPassword),
            ]);
        this.f.confirmPassword
            .setValidators(
                Validators.required
            );
        this.form.setValidators(
            ValidationService.MatchPasswords(
                "oldPassword",
                PasswordNameEnum.newPassword,
                PasswordNameEnum.confirmPassword
            )
        );
    }

    private removePasswordValidation(): void {
        this.f.oldPassword.clearValidators();
        this.f.newPassword.clearValidators();
        this.f.confirmPassword.clearValidators();
        this.form.clearValidators();
    }
}
