/* eslint-disable @angular-eslint/no-input-rename */
import { Directive, ElementRef, Input, OnChanges, OnDestroy } from "@angular/core";

@Directive({
    // eslint-disable-next-line @angular-eslint/directive-selector
    selector: "[loading]",
})
export class SpinnerDirective implements OnChanges, OnDestroy {
    @Input("loading") loading: boolean;
    @Input("button-loading")
    get isButton(): boolean {
        return this.buttonState;
    }

    set isButton(value: boolean) {
        this.buttonState = value != null && `${value}` !== "false";
    }

    @Input("no-label") noLabel: boolean;
    @Input("no-background") noBackground: boolean;

    private buttonState = false;
    private spinner;
    private container;
    private loadingElement;

    private spinnerTemplate = `<svg class='edge-spinner' width='70' height='70' viewBox='0 0 70 70' xmlns='http://www.w3.org/2000/svg'>
        <circle class='path' fill='none' stroke-width='6' stroke-linecap='round' cx='35' cy='35' r='30'></circle>
        </svg> <span class='loading-label'>Loading...</span>`;

    private readonly directiveClass: string = "preloader";

    private spinnerButtonTemplate = `<svg class='edge-spinner' width='30' height='30' viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'>
        <circle class='path' fill='none' stroke-width='4' stroke-linecap='round' cx='15' cy='15' r='10'></circle>
        </svg>`;

    private readonly directiveButtonClass: string = "preloader-button";

    constructor(private element: ElementRef) {}

    ngOnChanges(): void {
        if (!this.loading && this.spinner) {
            this.stopSpinner();
        } else if (this.loading && !this.spinner) {
            this.startSpinner();
        }
    }

    ngOnDestroy(): void {
        if (this.spinner) {
            this.stopSpinner();
        }
    }

    startSpinner(): void {
        this.container = document.createElement("div");
        this.spinner = document.createElement("div");

        this.loadingElement = this.element.nativeElement;

        this.loadingElement.classList.add(
            this.isButton ? this.directiveButtonClass : this.directiveClass
        );
        this.container.classList.add("edge-spinner-container");

        if (this.noLabel) {
            this.container.classList.add("edge-spinner-container--no-label");
        }

        if (this.noBackground) {
            this.container.classList.add("edge-spinner-container--no-background");
        }

        this.spinner.innerHTML = this.isButton ? this.spinnerButtonTemplate : this.spinnerTemplate;

        this.container.appendChild(this.spinner);
        this.loadingElement.appendChild(this.container);
    }

    stopSpinner(): void {
        this.loadingElement.classList.remove(
            this.isButton ? this.directiveButtonClass : this.directiveClass
        );
        this.loadingElement.removeChild(this.container);

        this.spinner = null;
        this.container = null;
    }
}
