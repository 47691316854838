<div class="auth-layout llayout">
    <div class="bg-overlay"></div>

    <edge-header></edge-header>

    <div class="llayout__body">
        <div class="auth-page-header">
            <div class="auth-page-title">
                <small>{{ meta.title }}</small>
                <div>{{ meta.subtitle }}</div>
            </div>
            <div class="auth-page-notice">{{ meta.notice }}</div>
        </div>

        <router-outlet class="shell"></router-outlet>
    </div>

    <edge-footer></edge-footer>
</div>
