import { Pipe, PipeTransform } from "@angular/core";
import { META_DATA } from "@shared/metadata/organization.metadata";
import { INumberFormatOptions } from "@shared/interfaces/number-format-options.interface";

@Pipe({ name: "edgeNumber" })
export class EdgeNumberPipe implements PipeTransform {
    // Transforms number based on culture and format options
    // en-US: 1,034.98
    // fr-FR: 1 034.98
    // ...
    // format options specifies formatting
    //   minimumFractionDigits (default = 2)
    //   maximumFractionDigits (default = 2)
    transform(
        value: number,
        culture: string = META_DATA.defaultCultureData.currentCulture,
        options: INumberFormatOptions = META_DATA.defaultCultureData.formatOptions
    ): string {
        return new Intl.NumberFormat(culture, options).format(value);
    }
}
