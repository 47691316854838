import { Component, Input, ViewChild } from "@angular/core";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "paginator",
    templateUrl: "./paginator.component.html",
})
export class PaginatorComponent {
    @ViewChild(MatPaginator) public paginator: MatPaginator;
    @Input() dataSource: MatTableDataSource<any>;
    @Input() totalItems: number;
    @Input() itemsPerPage: number;
    @Input() withPageSize: boolean = false;
    @Input() pageSizeOptions: number[] = [10, 25, 50, 100, 150];
}
