import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "edgeNotNull",
})
export class NotNullPipe implements PipeTransform {
    transform<T>(value: T | null | undefined): T | string {
        return value || "";
    }
}
