import { Injectable } from "@angular/core";
import { AbstractControl, UntypedFormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { EdgeDatePipe } from "@core/pipes/edge-date.pipe";
import cardValidator from "card-validator";

export const VALIDATION_OPTIONS = {
    PASSWORD_MINLENGTH: 8,
    USERNAME_MINLENGTH: 8,
};

interface ValidatorValue {
    [error: string]: string;
}

@Injectable({
    providedIn: "root",
})
export class ValidationService {
    constructor(
        private datePipe: EdgeDatePipe
    ) {}

    getValidatorErrorMessage(
        validatorName: string,
        validatorValue?: ValidatorValue
    ): string {
        const config = {
            required: "This field is required",
            email: "Please enter a valid email",
            number: "Please enter numbers only",
            maxlength: `Must contain no more than ${validatorValue.requiredLength} characters`,
            minlength: `Must contain at least ${validatorValue.requiredLength} characters`,
            min: `Must be greater than or equal to ${validatorValue.min}`,
            max: `Must be less than or equal to ${validatorValue.max}`,
            passwordLength: "Password is too short",
            passwordMatch: "New password and confirm do not match",
            passwordOld: "New password cannot be the same as the old password",
            passwordRequirements:
                "Password must contain at least 1 number, 1 letter and 1 special character",
            fax: "Fax is invalid",
            phone: "Phone number is invalid",
            invalidEndDate: "Please enter a date after the start date",
            postalCode: "Please enter a valid zip code",
            ssnMismatch: "Social Security Numbers do not match",
            streetAddress: "Please enter a residential address",
            passwordsMismatch: "New password and confirm do not match",
            oldPasswordNotMatch: "Does not match current password",
            newPasswordIsDuplicated:
                "You can not reuse your previous 24 passwords. Try a different one",
            newPasswordIsDuplicatedServer:
                "You can not reuse your previous 24 passwords. Try a different one",
            creditCard: "Invalid credit card number",
            expDate: "Invalid expiration date",
            forbiddenValue: "Such value already exists",
            startTime: "Start time is higher than end time",
            endTime: "End time is lower than start time",
            pattern: "Please enter a valid value",
            ip: "Please enter a valid IP address",
            userNameDuplicated: "Username already in use. Please specify a unique username",
            incorrectDomain: "Domain to Secure has invalid domain",
            incorrectDomainWildcard:
                "A wildcard certificate domain name must begin with an asterisk",
            cidr: "The IP address/CIDR entered is not valid.",
            wordAnyInBoth:
                "DataBank security policies do not allow for the creation of any<->any rules on the firewall. Please enter either a source IP address/CIDR or a destination IP address/CIDR.",
            notDomain:
                "The value entered is not a valid domain name. Please enter a valid domain name: Example: databank.com",
            whitespace: "Please enter a value",
            securityCode: "Must be exactly 6 digits",
            usPhoneNumber: "Non US phone number",
            internationalPhoneNumber: "Phone number is invalid",
            loa: "Please upload LOA/CFA file",
            matDatepickerMax: `Maximum date is ${this.datePipe.transform(validatorValue.max)}`,
            matDatepickerMin: `Minimum date is ${this.datePipe.transform(validatorValue.min)}`,
            matDatepickerParse: "Invalid date format. Please enter the date in the format MM/DD/YYYY"
        };

        return config[validatorName];
    }

    static patternValidation(pattern: string, skipEmpty: boolean = false): ValidatorFn {
        const patterns = {
            postalCode: /\d{5}(-\d{4})?/,
            phone: /\(?\d{3}\)?-\d{3}-\d{4}|\d{10}/,
            number: /\d/,
            streetAddress: /^(\d+)\s?([A-Za-z])+\s?([A-Za-z])+/i,
            // 1+ digit, 1+ lowercase, 1+ uppercase, 1+ special
            password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])/, // (?=.{8,})/,
            // 6 digits
            securityCode: /^\d{6}$/,
            // 1 XXX XXX-XXXX
            usPhoneNumber: /^(1\s?)?((\([0-9]{3}\))|[0-9]{3})[\s-]?[0-9]{3}[\s-]?[0-9]{4}$/,
            // X{10}X{5}
            internationalPhoneNumber: /^[0-9]{11}[0-9]?[0-9]?[0-9]?[0-9]?$/,
        };

        return (control: AbstractControl): { [key: string]: any } | null => {
            if (skipEmpty && (control.value === null || control.value === "")) {
                return null;
            }
            return patterns[pattern].test(control.value)
                ? null
                : { [pattern]: { value: control.value } };
        };
    }

    static faxValidator(control: AbstractControl): ValidationErrors | null {
        const phoneRegEx = /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/;
        if (!control || !control.value) {
            return null;
        }

        return control.value.match(phoneRegEx) ? null : { fax: true };
    }

    static numberFormatValidator(control: AbstractControl): ValidationErrors | null {
        const numberRegEx = /^\d+$/;
        if (!control || !control.value) {
            return null;
        }

        return control.value.match(numberRegEx) ? null : { number: true };
    }

    static postalCodeFormatValidator(control: AbstractControl): ValidationErrors | null {
        const postalCodeRegEx = /\d{5}(-\d{4})?/;
        if (!control || !control.value) {
            return null;
        }

        return control.value.match(postalCodeRegEx) ? null : { postalCode: true };
    }

    static streetAddressFormatValidator(control: AbstractControl): ValidationErrors | null {
        const streetAddressRegEx = /^(\d+)\s?([A-Za-z])+\s?([A-Za-z])+/i;
        if (!control || !control.value) {
            return null;
        }

        return control.value.match(streetAddressRegEx) ? null : { streetAddress: true };
    }

    static passwordFormatValidator(control: AbstractControl): ValidationErrors | null {
        const passwordRegEx = "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])";
        if (!control || !control.value) {
            return null;
        }

        return control.value.match(passwordRegEx) ? null : { passwordRequirements: true };
    }

    static passwordComplexityValidator(control: AbstractControl): ValidationErrors | null {
        const validationRules = [
            "[A-Z]+", // English uppercase characters (A-Z)
            "[a-z]+", // English lowercase characters (a-z)
            "[0-9]+", // Numeric digits (0-9)
            "\\W|_+", // Non-alphanumeric characters (for example: !, $, # or %)
        ];
        if (!control || !control.value) {
            return null;
        }

        let matchesCount = 0;
        validationRules.forEach(rule => control.value.match(rule) && matchesCount++);

        return matchesCount >= 3 ? null : { passwordRequirements: true };
    }

    static LOAValidation(control: AbstractControl): ValidationErrors | null {
        if (control.value?.length && control.value.find(file => !file?.isDeleted)) {
            return null;
        }
        return { loa: true };
    }

    static MatchPasswords(
        oldPassword: string,
        newPassword: string,
        confirmPassword: string
    ): ValidatorFn {
        // eslint-disable-next-line consistent-return
        return (formGroup: UntypedFormGroup): null => {
            const oldPasswordControl = formGroup.controls[oldPassword];
            const passwordControlRef = formGroup.controls[newPassword];
            const confirmControlRef = formGroup.controls[confirmPassword];

            if (!(oldPasswordControl && passwordControlRef && confirmControlRef)) {
                return null;
            }

            if (
                passwordControlRef.value
                &&
                oldPasswordControl.value === passwordControlRef.value
            ) {
                passwordControlRef.setErrors(
                    { newPasswordIsDuplicated: true }
                );
            } else if (passwordControlRef.hasError("newPasswordIsDuplicated")) {
                passwordControlRef.setErrors(null);
            }

            if (passwordControlRef.value !== confirmControlRef.value) {
                confirmControlRef.setErrors({ passwordsMismatch: true });
            } else if (confirmControlRef.hasError("passwordsMismatch")) {
                confirmControlRef.setErrors(null);
            }
        };
    }

    static MatchNewPasswords(
        password: string,
        confirm: string
    ): ValidatorFn {
        return (formGroup: UntypedFormGroup): ValidationErrors | null => {
            const passwordControlRef = formGroup.controls[password];
            const confirmControlRef = formGroup.controls[confirm];

            if (!(passwordControlRef && confirmControlRef)) {
                return null;
            }

            if (passwordControlRef.value !== confirmControlRef.value) {
                confirmControlRef.setErrors({ passwordsMismatch: true });
                return { passwordsMismatch: true };
            }

            confirmControlRef.setErrors(null);
            return null;
        };
    }

    static CheckIpInputsForAny(firstInput: string, secondInput: string): ValidatorFn {
        // eslint-disable-next-line consistent-return
        return (formGroup: UntypedFormGroup): ValidationErrors | null => {
            const firstControl = formGroup.controls[firstInput];
            const secondControl = formGroup.controls[secondInput];

            if (!firstControl || !secondControl) {
                return null;
            }

            if (firstControl.value === "any" && secondControl.value === "any") {
                secondControl.setErrors({ wordAnyInBoth: true });
                return { wordAnyInBoth: true };
            }

            secondControl.setErrors(null);
            return null;
        };
    }

    static userNameUniqueness(controlName: string, userNames: string[]) {
        return (formGroup: UntypedFormGroup): ValidationErrors | null => {
            const userNameControl = formGroup.controls[controlName];

            if (!userNameControl) {
                return null;
            }

            if (userNames.includes(userNameControl.value)) {
                userNameControl.setErrors({ userNameDuplicated: true });
                return { userNameDuplicated: true };
            }
            userNameControl.setErrors(null);
            return null;
        };
    }

    static oldPasswordValidator(value: boolean) {
        return (): ValidationErrors => {
            return value ? { oldPasswordNotMatch: true } : null;
        };
    }

    static newPasswordValidator(value: boolean) {
        return (): ValidationErrors => {
            return value ? { newPasswordIsDuplicatedServer: true } : null;
        };
    }

    static expirationDateValidator(control: AbstractControl): ValidationErrors | null {
        if (control.value?.length >= 5) {
            const [month, year] = control.value.split(/[\s/]+/, 2);

            if (/^\d+$/.test(month) && /^\d{4}$/.test(year) && month >= 1 && month <= 12) {
                const expiry = new Date(year, month, 1);
                const currentTime = new Date();
                const maxExpiry = new Date();
                maxExpiry.setFullYear(maxExpiry.getFullYear() + 10);

                if (expiry > currentTime && expiry < maxExpiry) {
                    return null;
                }
            }
        }

        return { expDate: true };
    }

    static CCValidator(control: AbstractControl): ValidationErrors | null {
        if (control.value != null && control.value !== "") {
            const isCardValid = cardValidator.number(control.value).isValid;
            return isCardValid ? null : { creditCard: true };
        }
        return null;
    }

    static updateValueAndValidity(form: UntypedFormGroup, emitEvent: boolean = true): void {
        for (const controlName in form.controls) {
            if (Object.prototype.hasOwnProperty.call(form.controls, controlName)) {
                form.controls[controlName].updateValueAndValidity({ emitEvent });
            }
        }
    }

    static exclusionValidation(
        list: string[] = [],
        name: string // key name for getMessage()
    ): ValidatorFn {
        return (control: AbstractControl): { [key: string]: any } | null => {
            return list.find(item => {
                return item.toLowerCase() === control.value.toLowerCase();
            })
                ? { [name]: true }
                : null;
        };
    }

    static IPValidator(control: AbstractControl): ValidationErrors | null {
        const ipRegEx = "^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(\\.|$)){4}$";
        if (!control || !control.value) {
            return null;
        }

        return control.value.match(ipRegEx) ? null : { ip: true };
    }

    static IPWithOptionalCIDRListValidator(control: AbstractControl): ValidationErrors | null {
        const ipRegEx = "^([0-9]{1,3}\\.){3}[0-9]{1,3}(\\/([0-9]|[1-2][0-9]|3[0-2]))?$";
        if (!control || !control.value || control.value === "any") {
            return null;
        }

        const hasErrors = control.value
            .split(",")
            .map(ip => ip.trim())
            .find(ip => !ip.match(ipRegEx));

        return hasErrors ? { cidr: true } : null;
    }

    static IPWithCIDRListValidator(control: AbstractControl): ValidationErrors | null {
        const ipRegEx = "^([0-9]{1,3}\\.){3}[0-9]{1,3}(\\/([0-9]|[1-2][0-9]|3[0-2]))$";
        if (!control || !control.value) {
            return null;
        }

        const hasErrors = control.value
            .split(",")
            .map(ip => ip.trim())
            .find(ip => !ip.match(ipRegEx));

        return hasErrors ? { cidr: true } : null;
    }

    static IPWithCIDRValidator(control: AbstractControl): ValidationErrors | null {
        const ipRegEx = "^([0-9]{1,3}\\.){3}[0-9]{1,3}(\\/([0-9]|[1-2][0-9]|3[0-2]))$";
        if (!control || !control.value) {
            return null;
        }

        const hasErrors = control.value.match(ipRegEx);

        return !hasErrors ? { cidr: true } : null;
    }

    static domainValidator(isWildcard: boolean, isAstericsAllowed: boolean): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            let pattern = "([a-zA-Z0-9_]([a-zA-Z0-9-_]{0,61}[a-zA-Z0-9])?\\.)+";

            const wildcardPattern = `(\\*\\.)${pattern}(([a-zA-Z]{2,})|(\\*)){1}`;
            const notWildcardPattern = `${pattern}[a-zA-Z]{2,}`;
            const astericsAllowedPattern = `(\\*\\.)?${notWildcardPattern}`;
            const { value } = control;

            if (!value?.length) return null;

            pattern = notWildcardPattern;

            if (isWildcard) {
                pattern = wildcardPattern;
            }
            if (isAstericsAllowed) {
                pattern = astericsAllowedPattern;
            }

            const regexp = new RegExp(`^${pattern}$`);

            if (isWildcard) {
                return value.indexOf("*") === 0 && regexp.test(value) ? null : { notDomain: true };
            }

            return regexp.test(value) ? null : { notDomain: true };
        };
    }

    static noWhitespaceValidator(control: AbstractControl): ValidationErrors | null {
        const isWhitespace = (control?.value?.toString() || "").trim().length === 0;
        const isValid = !isWhitespace;
        return isValid ? null : { whitespace: true };
    }

    static maxLengthWithoutTags(maxLength: number): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            if (!control || !control.value) {
                return null;
            }
            const valueWithoutTags = control.value.replace(/(<([^>]+)>)/gi, "");

            return valueWithoutTags.length > maxLength
                ? { maxlength: { requiredLength: maxLength } }
                : null;
        };
    }

    static notEmptyArray: ValidatorFn = (control) => {
        return !Array.isArray(control.value) || control.value.length === 0 ? { required: true } : null;
    }
}
