import { Component, OnInit, ChangeDetectionStrategy, OnDestroy, ChangeDetectorRef } from "@angular/core";
import { Observable, of, Subject } from "rxjs";
import { catchError, exhaustMap, filter, finalize, map, pairwise, startWith, takeUntil, tap } from "rxjs/operators";
import { OrganizationStorageService } from "@core/services/storages/organization-storage.service";
import { IGroup, IOrganizationDto } from "@core/interfaces/dto/user-profile-dto.interface";
import { UserApiService } from "@core/services/user-api.service";
import { Router } from "@angular/router";
import { RouterHelper } from "@core/helpers/router-helper";
import { METADATA } from "../../top-bar.metadata";
import { componentWithUnsavedChangesExist } from "@core/decorators/unsaved-changes.decorator";
import { DialogService } from "@shared/components/dialog/dialog.service";
import { FormControl } from "@angular/forms";

@Component({
    selector: "edge-top-bar-organization",
    templateUrl: "./top-bar-organization.component.html",
    styleUrls: ["./top-bar-organization.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarOrganizationComponent implements OnInit, OnDestroy {
    public meta = METADATA;
    public all: (IOrganizationDto | IGroup)[] = [];
    public isConfigLoading = false;
    public currentOrgControl: FormControl<IOrganizationDto | IGroup>;
    public selectedOrgName$: Observable<string>;

    private destroy$ = new Subject<void>();
    private ignoreNextValueChange = false;

    constructor(
        private organizations: OrganizationStorageService,
        private userAPIService: UserApiService,
        private router: Router,
        private dialog: DialogService,
        private cdr: ChangeDetectorRef
    ) {
       this.currentOrgControl = new FormControl(this.organizations.activeGroup || this.organizations.activeOrganization);
       this.selectedOrgName$ = this.currentOrgControl.valueChanges.pipe(map(o => o.name));
    }

    static transform(organizations: IOrganizationDto[]): any[] {
        const result = [];
        organizations?.forEach(org => {
            org.isGroup = false;
            result.push(org);
            if (org.groups) {
                org.groups.forEach(group => {
                    if (group) {
                        group.isGroup = true;
                        group.name = group.groupName;
                        group.id = group.groupId;
                        result.push(group);
                    }
                });
            }
        });
        return result;
    }

    static sort(organizations: IOrganizationDto[] = []): IOrganizationDto[] {
        return organizations?.sort((a, b) => {
            if (a.name.toLowerCase().trim() < b.name.toLowerCase().trim()) {
                return -1;
            }
            if (a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) {
                return 1;
            }
            return 0;
        });
    }

    ngOnInit(): void {
        // Get organizations
        this.organizations.all$.pipe(takeUntil(this.destroy$)).subscribe((data = []) => {
            this.all = TopBarOrganizationComponent.transform(
                TopBarOrganizationComponent.sort(data)
            );
        });

        // Active organization change handler
        this.currentOrgControl.valueChanges.pipe(
            startWith(this.currentOrgControl.value),
            pairwise(),
            filter(() => {
                if (this.ignoreNextValueChange) {
                    this.ignoreNextValueChange = false;
                    return false;
                }
                return true;
            }),
            filter(([_prev, curr]) => {
                const { activeGroup, activeOrganization } = this.organizations;
                // Ignore emit if selected the same organization
                return curr.id !== (activeGroup ? activeGroup.id : activeOrganization.id);
            }),
            exhaustMap(([prev, curr]) => {
                if (componentWithUnsavedChangesExist()) {
                    // Can't use { emitEvent: false }, because we need 'pairwise' to remember this value.
                    this.ignoreNextValueChange = true;
                    // Using setValue to show on UI old value.
                    // New value will be populated after successfull organization change.
                    this.currentOrgControl.setValue(prev);

                    return this.dialog.unsavedChanges().pipe(
                        tap((discardChanges) => {
                            if (discardChanges) this.changeOrganization(curr);
                        })
                    );
                } else {
                    this.changeOrganization(curr);
                    return of();
                }
            }),
            takeUntil(this.destroy$)
        ).subscribe();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private changeOrganization(organization: IOrganizationDto | IGroup): void {
        this.isConfigLoading = true;
        this.ignoreNextValueChange = true;
        this.currentOrgControl.setValue(organization);
        this.cdr.detectChanges();

        let organizationId;
        let groupId;
        let userId;

        if (organization.isGroup) {
            const group = organization as IGroup;
            organizationId = group.organizationId;
            groupId = group.groupId;
            userId = group?.userId ?? this.organizations.getUserId();
        } else {
            organizationId = organization.id;
            userId = organization.userId;
            groupId = null;
        }

        // Request for new organization user's profile
        this.userAPIService
            .getAndSaveUserProfile({
                userId,
                organizationId,
                groupId,
            })
            .pipe(
                takeUntil(this.destroy$),
                catchError(() => {
                    return of(null);
                }),
                finalize(() => {
                    this.isConfigLoading = false;
                    this.cdr.detectChanges();
                })
            )
            .subscribe(() => {
                RouterHelper.forceReloadState(this.router);
            });
    }

    public get isOnlyOrganization(): boolean {
        return this.all.length <= 1;
    }
}
