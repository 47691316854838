import { Component, ChangeDetectionStrategy, OnChanges, SimpleChanges, Input } from "@angular/core";
import { Router } from "@angular/router";
import {
    TableData,
    ExpandableData,
} from "@shared/components/table-expandable/table-expandable.component";
import { BehaviorSubject, Observable } from "rxjs";
import { finalize, take } from "rxjs/operators";
import { PERMISSIONS, PERMISSIONS_ACTIONS } from "@core/constants/permission.constant";
import { SupportCallEvent } from "@shared/interfaces/support.interface";
import { SupportApiService } from "@shared/services/support-api.service";
import { SupportTicketMapped } from "@shared/services/tickets-history-map.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { METADATA } from "../../top-bar.metadata";
import { Ticket, TicketPaginatedData } from "../../interfaces/top-bar.interface";

@Component({
    selector: "edge-top-bar-tickets",
    templateUrl: "./top-bar-tickets.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarTicketsComponent implements OnChanges {
    @Input() isLoading: boolean;
    @Input() data: TicketPaginatedData<TableData<Ticket>>;

    public meta = METADATA;
    public permConst = PERMISSIONS;
    public permActions = PERMISSIONS_ACTIONS;

    public data$: Observable<TableData<SupportTicketMapped>>;
    private dataSubj = new BehaviorSubject<TableData<SupportTicketMapped>>(null);

    constructor(
        private router: Router,
        private support: SupportApiService,
    ) {
        this.data$ = this.dataSubj.asObservable();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.data && changes.data.currentValue) {
            this.dataSubj.next(changes.data.currentValue.paginatedItems);
        }
    }

    public onOpen(ticketNumber: number, trigger: MatMenuTrigger): void {
        this.router.navigate(["support", "ticket-details", ticketNumber]);
        trigger.closeMenu();
    }

    public onAdd(trigger: MatMenuTrigger): void {
        this.router.navigate(["support", "new-ticket"]);
        trigger.closeMenu();
    }

    public onSeeAll(trigger: MatMenuTrigger): void {
        this.router.navigate(["support", "history"]);
        trigger.closeMenu();
    }

    public onExpandChange(item: ExpandableData<SupportTicketMapped>): void {
        if (
            item.expanded &&
            !(item.element.collapsedData.lastEvents || item.element.collapsedData.isLoading)
        ) {
            item.element.collapsedData.isLoading = true;
            this.support
                .getTicketEvents(item.element.supportCallId)
                .pipe(
                    take(1),
                    finalize(() => {
                        item.element.collapsedData.isLoading = false;
                    })
                )
                .subscribe((events: SupportCallEvent[]) => {
                    item.element.collapsedData.lastEvents = events;
                });
        }
    }
}
