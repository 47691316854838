import { Pipe, PipeTransform } from "@angular/core";
import dayjs from "dayjs";

@Pipe({
    name: "utcToLocal",
})
export class UtcToLocalPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return value;
        }

        return dayjs.utc(value).toDate().toString();
    }
}
