import { Injectable } from "@angular/core";
import { MatDialog, MatDialogConfig, MatDialogRef } from "@angular/material/dialog";
import { ComponentType } from "@angular/cdk/portal";
import { Observable } from "rxjs";
import { DialogComponent } from "./dialog.component";

export interface ConfirmModalData {
    title: string;
    body: string;
    okButton: string; // TODO: Rename to confirmButton
    cancelButton: string; // TODO: Rename to rejectButton
    onReject?(); // On click reject button at the bottom of modal
    onConfirm?(); // On click confirm button at the bottom of modal
    onClose?(); // On modal close
    onCancel?(); // On click cross icon OR press Escape OR click outside the modal
    hideCancelButton?: boolean;
    loading$?: Observable<{ loading: boolean; failed?: boolean }>;
}

const defaultDialogConfig: MatDialogConfig = {
    panelClass: ["edge-dialog"],
    maxWidth: "80vw",
};

const defaultConfirmDialogConfig: MatDialogConfig<ConfirmModalData> = {
    ...defaultDialogConfig,
    data: {
        title: "Confirmation",
        body: "Are you sure?",
        okButton: "Confirm",
        cancelButton: "Cancel",
    },
};

/**
 * It must NOT be provided in root. Provide this service directly or using SharedModule.
 * Otherwise you might not have access to needed providers in the CustomComponent (MatDialog.open(CustomComponent)).
 * See discussion here (https://github.com/angular/components/issues/25073)
 *
 * P.S. Looks like correctly providing this service, eliminates the need to pass viewContainerRef
*/
@Injectable()
export class DialogService {
    constructor(public dialog: MatDialog) {}

    /** Open standard confirmation dialog */
    confirm(config?: MatDialogConfig<Partial<ConfirmModalData>>): MatDialogRef<DialogComponent, void> {
        return this.dialog.open(DialogComponent, this.mergeConfigs(defaultConfirmDialogConfig, config));
    }

    /** Open dialog using custom component */
    custom<T, D = any, R = any>(component: ComponentType<T>, config: MatDialogConfig<D>): MatDialogRef<T, R> {
        return this.dialog.open(component, this.mergeConfigs(defaultDialogConfig, config));
    }

    unsavedChanges(): Observable<boolean> {
        return new Observable<boolean>(observer => {
            this.confirm({
                data: {
                    body: "You have unsaved changes on this page. Are you sure you want to leave this page and discard your changes?",
                    title: "Confirmation",
                    onConfirm: () => {
                        observer.next(true);
                        observer.complete();
                    },
                    onReject: () => {
                        observer.next(false);
                        observer.complete();
                    },
                    onCancel: () => {
                        observer.next(false);
                        observer.complete();
                    },
                    okButton: "Ok",
                    cancelButton: "Cancel",
                },
            });
        });
    }

    private mergeConfigs(defaultConfig: MatDialogConfig, inputConfig?: MatDialogConfig): MatDialogConfig {
        // MatDialogConfig may contain objects with cycled references. Thats why we need to do shallow merge
        const config = { ...defaultConfig, ...inputConfig };
        config.data = { ...defaultConfig.data, ...inputConfig?.data };
        return config;
    }
}
