import { inject } from "@angular/core";
import {
    CanMatchFn,
    Router
} from "@angular/router";
import { PERMISSIONS, PERMISSIONS_ACTIONS } from "../constants/permission.constant";
import { IPermissionCustomDetails } from "../interfaces/permission.interface";
import { OrganizationDataAvailabilityStorageService } from "../services/storages/organization-data-availability-storage.service";
import { PermissionsStorageService } from "../services/storages/permissions-storage.service";

enum Routes {
    Home = "home",
    DatacenterAccess = "datacenter-access",
    AccessDenied = "access-denied",
}

export const AvailabilityGuard: CanMatchFn = (route) => {
    if (!route.data.availability) throw Error("Missing availability config");

    const { permissionCode, organizationFlag, permissionCustom, permissionAction } = route.data.availability;
    const action = permissionAction || PERMISSIONS_ACTIONS.view;
    const permission = permissionCustom || permissionCode;

    return checkAvailability(organizationFlag, permission, action, route.path);
}

function checkAvailability(
    organizationFlag: string,
    permission: number | IPermissionCustomDetails,
    permissionAction: number,
    route: string
) {
    if (!inject(OrganizationDataAvailabilityStorageService).checkOrganizationFlag(organizationFlag)) {
        return accessDenied(route);
    }

    if (!permission) return true;

    const permissionStorage = inject(PermissionsStorageService);
    if (typeof permission === "object") {
        return (
            permissionStorage.checkCustomPermission(permission) || accessDenied(route)
        );
    }
    return (
        permissionStorage.checkPermission(permission, permissionAction) ||
        accessDenied(route)
    );
}

function accessDenied(path: string) {
    const permissionStorage = inject(PermissionsStorageService);
    const router = inject(Router);

    if (path === Routes.Home) {
        if (permissionStorage.has(PERMISSIONS.DataCenterPhysicalAccessPage)) {
            return router.parseUrl(Routes.DatacenterAccess);
        } else {
            return router.parseUrl(Routes.AccessDenied);
        }
    }

    return router.parseUrl(Routes.Home);
}
