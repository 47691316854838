import { Injectable } from "@angular/core";
import { webApiUrls } from "@core/constants/url.constant";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ServersConfig } from "../interfaces/servers.interface";

@Injectable({
    providedIn: "root",
})
export class ServersApiService {
    constructor(private http: HttpClient) {}

    public getServersConfig(assetNumber: string): Observable<ServersConfig> {
        const path = `${webApiUrls.productManagement}/Products/ByAssetNumber/${assetNumber}/Config`;
        return this.http.get<ServersConfig>(path);
    }
}
