<mat-label class="form-control-label" *ngIf="label" [ngClass]="{
    'field-required': required && control.enabled,
    'field-disabled': control.disabled
}">{{label}}</mat-label>

<div class="multiselect-list-wrapper"
     [class.error]="isErrorVisible"
     [class.disabled]="control.disabled"
     *ngIf="{
        all: {
            items: allVisibleItems$ | async,
            checkbox: allTabCheckboxState$ | async
        },
        selected: {
            items: selectedVisibleItems$ | async,
            checkbox: selectedTabCheckboxState$ | async
        }
    } as vm"
>
    <mat-tab-group class="full-width" [selectedIndex]="selectedTabIsDefault ? 1 : 0" (selectedTabChange)="selectedTabChanged($event)" [preserveContent]="true">
        <!-- ALL -->
        <mat-tab label="All" class="mat-tab-all">
            <div class="table-options">
                <button mat-icon-button [matMenuTriggerFor]="search" [class.filter-active]="searchControl.value.length"
                    matTooltip="Search">
                    <mat-icon svgIcon="search"></mat-icon>
                </button>
                <mat-menu #search="matMenu" class="multiselect-list-search">
                    <div class="search-wrapper" (click)="$event.stopPropagation()">
                        <mat-form-field>
                            <input matInput placeholder="Search" [formControl]="searchControl">
                        </mat-form-field>
                    </div>
                </mat-menu>
            </div>
                <table class="common-table multiselect-table">
                    <thead>
                        <tr>
                            <th>
                                <mat-checkbox
                                    [matTooltip]="vm.all.checkbox?.tooltip"
                                    [checked]="vm.all.checkbox?.checked"
                                    [disabled]="vm.all.checkbox?.disabled"
                                    (change)="onCheckboxAllChange(vm.all.items, $event)"
                                ></mat-checkbox>
                            </th>
                            <th *ngFor="let column of displayedColumns; let i = index" [innerHTML]="column.title">
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        <cdk-virtual-scroll-viewport #allTabVirtualScroll edgeMultiselectVirtualScroll [items]="vm.all.items">
                            <ng-container *cdkVirtualFor="let item of vm.all.items; trackBy: trackByItemId">
                                <tr [attr.data-item-id]="item.data[idField]">
                                    <td class="checkbox-cell">
                                        <mat-checkbox
                                            [checked]="item.selected"
                                            [disabled]="control.disabled || item.disabled"
                                            (change)="onCheckboxSingleChange(item, $event)"
                                        ></mat-checkbox>
                                    </td>
                                    <td *ngFor="let column of displayedColumns"
                                        [innerHTML]="item.data[column.value] != null ? item.data[column.value] : ''"
                                    ></td>
                                </tr>
                            </ng-container>
                        </cdk-virtual-scroll-viewport>
                    </tbody>
                </table>
        </mat-tab>
        <!-- SELECTED -->
        <mat-tab class="mat-tab-selected">
            <ng-template mat-tab-label>
                Selected
                <span class="selected-count" [class.selected-count-hidden]="selectedCount === 0">
                    {{ selectedCount }}
                </span>
            </ng-template>

            <div class="table-options">
                <button mat-icon-button [matMenuTriggerFor]="search" [class.filter-active]="searchControl.value.length"
                    matTooltip="Search">
                    <mat-icon svgIcon="search"></mat-icon>
                </button>
                <mat-menu #search="matMenu" class="multiselect-list-search">
                    <div class="search-wrapper" (click)="$event.stopPropagation()">
                        <mat-form-field>
                            <input matInput placeholder="Search" [formControl]="searchControl">
                        </mat-form-field>
                    </div>
                </mat-menu>
            </div>
                <table class="common-table multiselect-table">
                    <thead>
                        <tr>
                            <th>
                                <mat-checkbox
                                    [matTooltip]="vm.selected.checkbox?.tooltip"
                                    [checked]="vm.selected.checkbox?.checked"
                                    [disabled]="vm.selected.checkbox?.disabled"
                                    (change)="onCheckboxAllChange(vm.selected.items, $event);"
                                ></mat-checkbox>
                            </th>
                            <th *ngFor="let column of displayedColumns" [innerHTML]="column.title"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <cdk-virtual-scroll-viewport #selectedTabVirtualScroll edgeMultiselectVirtualScroll [items]="vm.selected.items">
                            <ng-container *cdkVirtualFor="let item of vm.selected.items; trackBy: trackByItemId; templateCacheSize: 0">
                                <tr [attr.data-item-id]="item.data[idField]">
                                    <td class="checkbox-cell">
                                        <mat-checkbox
                                            [checked]="true"
                                            [disabled]="control.disabled || item.disabled"
                                            (change)="onCheckboxSingleChange(item, $event)"
                                        ></mat-checkbox>
                                    </td>
                                    <td *ngFor="let column of displayedColumns"
                                        [innerHTML]="item.data[column.value] != null ? item.data[column.value] : ''"
                                    ></td>
                                </tr>
                            </ng-container>
                        </cdk-virtual-scroll-viewport>
                    </tbody>
                </table>
        </mat-tab>
    </mat-tab-group>
</div>
<div class="error-container mat-error" [style.visibility]="isErrorVisible ? 'visible' : 'hidden'">
    <validation-error [control]="ngControl?.control"></validation-error>
</div>

