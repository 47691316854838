import { Injectable } from "@angular/core";
import { ISelectOption } from "@shared/modules/controls/select/select.interface";
import {
    DataCenter,
    DataCenterSelectOption,
    ILocation,
    IPersonApi,
    Person,
    PersonOption,
    Priority,
} from "@shared/interfaces/support.interface";

@Injectable()
export class SupportMapService {
    public readonly personIdPrefix = "new-user";
    public getMappedPeopleOptions(peoples: Person[]): PersonOption[] {
        return peoples.map(p => ({
            name: p.fullName,
            personId: p.personId,
            email: p.email,
            groupNumber: p.groupNumber,
            isDatabankEmployee: p.isDatabankEmployee,
        }));
    }

    public getPrioritiesOptions(priorities: Priority[]): ISelectOption[] {
        const options: ISelectOption[] = priorities.map(item => ({
            value: item.id,
            label: item.description,
        }));
        return options;
    }

    public getPeopleApi(selectedIds: string[], availablePeoples: PersonOption[]): IPersonApi[] {
        return selectedIds
            ? selectedIds.map(userId => {
                  const found = availablePeoples.find(p => p.personId === userId);
                  return {
                      personId: found.personId.includes(this.personIdPrefix)
                          ? null
                          : found.personId,
                      emailAddress: found.email,
                  };
              })
            : null;
    }

    public getMappedViewLocations(items: ILocation[]): ISelectOption[] {
        return items.map(item => ({
            value: item.displayCode,
            label:
                item.displayCode +
                (this.isGoodString(item.address1) ? ` - ${item.address1}` : "") +
                (this.isGoodString(item.address2) ? `, ${item.address2}` : "") +
                (this.isGoodString(item.address3) ? `, ${item.address3}` : "") +
                (this.isGoodString(item.city) ? `, ${item.city}` : "") +
                (item.region != null ? `, ${item.region.entry}` : "") +
                (this.isGoodString(item.postalCode) ? `, ${item.postalCode}` : ""),
        }));
    }

    public getDataCenterOptions(items: DataCenter[]): DataCenterSelectOption[] {
        return items.map(item => ({
            id: item.locationID,
            label: this.getDataCenterLabel(item),
            deviceCode: item.displayCode,
        }));
    }

    public getDataCenterLabel(item: DataCenter): string {
        return (
            item.displayCode +
            (this.isGoodString(item.address1) ? ` - ${item.address1}, ` : "") +
            (this.isGoodString(item.address2) ? `${item.address2}, ` : "") +
            (this.isGoodString(item.address3) ? `${item.address3}, ` : "") +
            (this.isGoodString(item.city) ? `${item.city}, ` : "") +
            (this.isGoodString(item.address1) ? item.postalCode : "")
        );
    }

    private isGoodString(string): boolean {
        return string != null && string !== "";
    }
}
