import { NgModule } from "@angular/core";
import { DateAdapter, MAT_DATE_FORMATS } from "@angular/material/core";
import { CustomDateAdapter } from "./custom-date-adapter";
import { CUSTOM_DATE_FORMATS } from "./custom-date-format";

@NgModule({
    providers: [
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter,
        },
        {
            provide: MAT_DATE_FORMATS,
            useValue: CUSTOM_DATE_FORMATS,
        },
    ],
})
export class CustomDateAdapterModule {}
