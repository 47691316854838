<div class="top-bar-list">
    <button mat-icon-button
            class="clean"
            [class.animate]="data?.totalItemsCount"
            [matMenuTriggerFor]="eventMenu"
            #matMenu="matMenuTrigger"
            matTooltip="Active Alarms"
            matTooltipPosition="below"
            matTooltipClass="below"
            id="top-btnActiveAlarms"
    >
        <mat-icon svgIcon="warning"></mat-icon>
    </button>
    <mat-menu #eventMenu="matMenu" xPosition="before" class="top-bar-panel">
        <mat-card (click)="$event.stopPropagation()">
            <mat-card-header>
                <div class="toolbar">
                    <div class="toolbar-left">
                        <div class="toolbar-group">
                            <mat-card-title>Alarms</mat-card-title>
                        </div>
                        <div class="toolbar-group">
                            <h4 class="widget-value">{{data?.totalItemsCount}}</h4>
                        </div>
                        <div class="toolbar-group" *ngIf="!!data?.totalItemsCount">
                            <button id="top-bar-alarm-add__button" mat-icon-button class="flat">
                                <mat-icon svgIcon="add"></mat-icon>
                            </button>
                        </div>
                    </div>

                    <div class="toolbar-right">
                        <button id="top-bar-alarm-see__button"
                                mat-stroked-button
                                class="next"
                                (click)="goToAlarms(matMenu)"
                        >See All
                            <mat-icon svgIcon="expand"></mat-icon>
                        </button>
                    </div>
                </div>
            </mat-card-header>

            <mat-card-content [loading]="isLoading">
                <ng-template [ngIf]="!data?.totalItemsCount" [ngIfElse]="table">
                    <edge-no-data></edge-no-data>
                </ng-template>

                <ng-template #table>
                    <table class="common-table alarms">
                        <thead>
                        <tr>
                            <th>Device Name</th>
                            <th>Alarm Summary</th>
                        </tr>
                        </thead>
                        <tbody>
                        <tr *ngFor="let alarm of data?.paginatedItems">
                            <td>
                                <div class="alarm-device">
                                    <a [matTooltip]="alarm.assetNumber"
                                       id="top-bar-open-alarm__link"
                                       matTooltipPosition="below"
                                       matTooltipClass="below"
                                       (click)="onClick(alarm, matMenu)"
                                    >{{alarm.assetNumber}}</a>
                                </div>
                            </td>
                            <td>
                                <div [matTooltip]="alarm.message"
                                     matTooltipPosition="below"
                                     matTooltipClass="below"
                                     class="alarm-message"
                                >
                                    <span>{{alarm.message}}</span>
                                </div>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </ng-template>
            </mat-card-content>
        </mat-card>
    </mat-menu>
</div>
