import { Component, OnInit } from "@angular/core";
import { IdleService } from "@core/services/idle.service";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { NotificationService } from "@shared/services/notification.service";
import { TitleService } from "@core/services/title.service";
import { AuthApiService } from "@core/services/auth-api.service";
import { switchMap } from "rxjs/operators";

@Component({
    selector: "edge-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
    private expiredMessage = "Your session is expired";

    constructor(
        private idleService: IdleService,
        private authStorageService: AuthStorageService,
        private authApiService: AuthApiService,
        private titleService: TitleService,
        private notificationService: NotificationService
    ) {}

    ngOnInit(): void {
        this.idleService.initIdleConfig();

        this.authStorageService.isAuthorized$().subscribe(isAuthorized => {
            if (isAuthorized) {
                this.idleService.startSession();
            } else {
                this.idleService.stopSession();
            }
        });

        this.idleService.sessionExpired$
            .pipe(switchMap(() => this.authApiService.forceLogout()))
            .subscribe(() => {
                this.titleService.setDefaultTitle();
                this.notificationService.custom({
                    duration: 0,
                    data: this.expiredMessage,
                    panelClass: ["dui-notification", "warning"],
                });
            });
    }
}
