import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "edgeBytesConverter" })
export class EdgeBytesConverterPipe implements PipeTransform {
    transform(bytes: number, decimals: number = 1): string {
        if (!bytes) {
            return "0 Bytes";
        }

        const oneKbSize = 1024;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const type = Math.floor(Math.log(bytes) / Math.log(oneKbSize));

        // eslint-disable-next-line no-restricted-properties
        return `${parseFloat((bytes / Math.pow(oneKbSize, type)).toFixed(decimals))} ${
            sizes[type]
        }`;
    }
}
