import { APP_INITIALIZER, NgModule } from "@angular/core";
import { CORE_PIPES } from "@core/constants/pipes.constant";
import { CORE_DIRECTIVES } from "@core/constants/directives.constant";
import { CurrencyPipe, DatePipe } from "@angular/common";
import { IconsRegisterService } from "./services/icons-register.service";

@NgModule({
    declarations: [...CORE_DIRECTIVES, ...CORE_PIPES],
    imports: [],
    providers: [
        IconsRegisterService,
        {
            provide: APP_INITIALIZER,
            useFactory: (iconsRegisterService: IconsRegisterService) => () => {
                return iconsRegisterService.registerIcons();
            },
            deps: [IconsRegisterService],
            multi: true,
        },
        ...CORE_PIPES,
        DatePipe,
        CurrencyPipe,
    ],
    exports: [...CORE_DIRECTIVES, ...CORE_PIPES],
})
export class CoreModule {}
