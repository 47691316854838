<ul class="edge-notification-container">
    <li class="edge-notification-icon">
        <mat-icon class="edge-notification-info-icon" svgIcon="info"></mat-icon>
        <mat-icon class="edge-notification-warning-icon" svgIcon="warning"></mat-icon>
        <mat-icon class="edge-notification-success-icon" svgIcon="success"></mat-icon>
        <mat-icon class="edge-notification-error-icon" svgIcon="error"></mat-icon>
    </li>
    <li class="edge-notification-message flexible" [innerHTML]="data"></li>
    <li class="edge-notification-ctrl">
        <a href="javascript:void(0)" class="edge-icon-link" (click)="ref.dismiss()">
            <mat-icon svgIcon="clear"></mat-icon>
        </a>
    </li>
</ul>
