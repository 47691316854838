<div class="paginator-container" *ngIf="!!paginator.length">
    <div class="paginator-size" *ngIf="withPageSize && paginator.length > pageSizeOptions[0]">
        <ng-container >
            <mat-label class="paginator-size-label">View Per Page</mat-label>
            <mat-form-field class="mat-paginator-page-size-select">
                <mat-select [value]="paginator.pageSize" (selectionChange)="paginator._changePageSize($event.value)">
                    <mat-option *ngFor="let pageSizeOption of paginator._displayedPageSizeOptions"
                        [value]="pageSizeOption">
                        {{pageSizeOption}}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </ng-container>
    </div>

    <div class="paginator-navigation" *ngIf="paginator.length > paginator.pageSize">
        <ng-container >
            <button mat-icon-button [disabled]="paginator.pageIndex === 0" (click)="paginator.firstPage()">
                <mat-icon svgIcon="navigate_first"></mat-icon>
            </button>
            <button mat-icon-button [disabled]="paginator.pageIndex === 0" (click)="paginator.previousPage()">
                <mat-icon svgIcon="navigate_previous"></mat-icon>
            </button>

            <button mat-stroked-button class="clean" *ngIf="paginator.pageIndex !== 0"
                (click)="paginator.previousPage()">{{paginator.pageIndex}}
            </button>

            <button mat-flat-button>
                {{paginator.pageIndex + 1}}
            </button>

            <button mat-stroked-button class="clean" *ngIf="paginator.pageIndex !== paginator.getNumberOfPages() - 1"
                (click)="paginator.nextPage()">{{paginator.pageIndex + 2}}
            </button>

            <button mat-icon-button [disabled]="paginator.pageIndex === paginator.getNumberOfPages() - 1"
                (click)="paginator.nextPage()">
                <mat-icon svgIcon="navigate_next"></mat-icon>
            </button>
            <button mat-icon-button [disabled]="paginator.pageIndex === paginator.getNumberOfPages() - 1"
                (click)="paginator.lastPage()">
                <mat-icon svgIcon="navigate_last"></mat-icon>
            </button>
        </ng-container>

    </div>
</div>



<mat-paginator style="display: none" #paginator [length]="totalItems" [pageSize]="itemsPerPage"
    [pageSizeOptions]="withPageSize ? pageSizeOptions : []">
</mat-paginator>
