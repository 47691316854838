import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { catchError, first, map, of, shareReplay } from "rxjs";

const API_KEY = "AIzaSyAtFmPBymgNPMBFucm5AcgAYhQOVAAk3wM";

@Injectable({
    providedIn: "root"
})
export class GoogleMapsLoaderService {
    googleMapsLoaded$ = inject(HttpClient).jsonp("https://maps.googleapis.com/maps/api/js?key=" + API_KEY, "callback").pipe(
        map(() => true),
        catchError(() => of(false)),
        shareReplay()
    );

    constructor() {
        // subscription to preload maps
        this.googleMapsLoaded$.pipe(first()).subscribe();
    }
}

