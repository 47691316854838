import { Injectable } from "@angular/core";

@Injectable({
    providedIn: "root",
})
export class LocalStorageService {
    private readonly storage: Storage;

    constructor() {
        this.storage = window.localStorage;
    }

    setItem<T>(key: string, value: T): void {
        this.storage.setItem(key, JSON.stringify(value));
    }

    get length(): number {
        return this.storage.length;
    }

    clear(): void {
        this.storage.clear();
    }

    getItem<T>(key: string): T | null {
        try {
            return JSON.parse(this.storage.getItem(key));
        } catch (err) {
            return null;
        }
    }

    key(index: number): string | null {
        return this.storage.key(index);
    }

    removeItem(key: string): void {
        this.storage.removeItem(key);
    }
}
