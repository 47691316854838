import {
    Component,
    Injector,
    Input,
    OnChanges,
    OnInit,
    SimpleChanges,
    ViewChild
} from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR, NgControl } from "@angular/forms";
import { BehaviorSubject, Subject, combineLatest, merge } from "rxjs";
import { debounceTime, filter, map, share, startWith, withLatestFrom } from "rxjs/operators";
import { MatTabChangeEvent } from "@angular/material/tabs";
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";

export type MultiselectList = MultiselectListItem[];
export type MultiselectListItem = { [key: string]: string | boolean };
export type ItemId = string | number;
export type ListItem = {
    selected: boolean;
    disabled: boolean;
    data: MultiselectListItem;
};

interface SelectionChanged {
    changedItems: ListItem[];
    isSelected: boolean;
}

interface FullItemsList {
    propagate: boolean;
    value: ListItem[];
}

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "multiselect-list",
    templateUrl: "./multiselect-list.component.html",
    styleUrls: ["./multiselect-list.component.scss"],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: MultiselectListComponent, multi: true }
    ]
})
export class MultiselectListComponent implements ControlValueAccessor, OnChanges, OnInit {
    @Input() displayedColumns: { value: string; title: string }[];
    @Input() tableData: MultiselectList;
    @Input() idField: string;
    @Input() disabledField: string;
    @Input() label: string;
    @Input() selectedTabIsDefault = false;
    // We need to have average row height for the virtual scroll to calculate the height of the scroll container.
    // This is just estimated average height of the row. It should be as precise as possible for smooth experience.
    @Input() averageRowHeight = 38.5;
    // Needed only to show required mark.
    // If you need to add actual required validation - add ValidationService.notEmptyArray in parent component
    @Input() required: boolean;

    @ViewChild("allTabVirtualScroll") allTabViewport: CdkVirtualScrollViewport;
    @ViewChild("selectedTabVirtualScroll") selectedTabViewport: CdkVirtualScrollViewport;

    selectedCount = 0;
    control = new FormControl<ItemId[]>([]);
    searchControl = new FormControl("");

    private selectionChanged$$ = new Subject<SelectionChanged>();
    private fullItemsList$$ = new BehaviorSubject<FullItemsList>({ propagate: true, value: []});


    // All items with "selected" property have correct value
    private selectedItems$ = merge(
        // Triggered when we get a new items from parent
        this.fullItemsList$$.asObservable().pipe(
            filter((allItems) => {
                const propagate = allItems.propagate
                allItems.propagate = true;
                return propagate;
            }),
            withLatestFrom(this.control.valueChanges.pipe(startWith(this.control.value))),
            // Changing places for better types
            map(([allItems, selected]) => [selected, allItems] as [ItemId[], FullItemsList])
        ),
        // Triggered when user interract with the widget (select/deselect)
        this.selectionChanged$$.asObservable().pipe(
            withLatestFrom(this.fullItemsList$$.asObservable())
        ),
        // Triggered when parent set new value
        this.control.valueChanges.pipe(startWith(this.control.value)).pipe(
            withLatestFrom(this.fullItemsList$$.asObservable())
        )
    ).pipe(
        map(([selected, allItems]) => {
            if (Array.isArray(selected)) return this.selectUnsortedItems(allItems.value, selected);
            return this.selectSortedItems(allItems.value, selected);
        })
    );

    // All items which meet search criteria
    private searchedItems$ = combineLatest([
        this.selectedItems$,
        this.searchControl.valueChanges.pipe(
            startWith(this.searchControl.value),
            debounceTime(300)
        )
    ]).pipe(
        map(([items, searchString]) => this.searchItems(items, searchString)),
        share()
    );

    // Items which should be showed in All tab
    allVisibleItems$ = this.searchedItems$;

    // Items which are showed in Selected tab
    selectedVisibleItems$ = this.searchedItems$.pipe(
        map(items => items.filter(item => item.selected))
    );

    // State of the top checkbox in All tab
    allTabCheckboxState$ = combineLatest([
        this.allVisibleItems$,
        this.control.statusChanges.pipe(startWith(this.control.status))
    ]).pipe(
        map(([items, status]) => {
            const enabled = items.length > 0 && items.some(i => !i.disabled) && status !== "DISABLED";
            const checked = items.length > 0 && items.every(i => i.disabled || i.selected) && enabled;

            let tooltip: string;
            if (!enabled) tooltip = "";
            else if (checked) tooltip = "Deselect all";
            else tooltip = "Select all";

            return { checked, disabled: !enabled, tooltip };
        })
    );

    // State of the top checkbox in Selected tab
    selectedTabCheckboxState$ = combineLatest([
        this.selectedVisibleItems$,
        this.control.statusChanges.pipe(startWith(this.control.status))
    ]).pipe(
        map(([items, status]) => {
            const enabled = items.length > 0 && items.some(i => !i.disabled) && status !== "DISABLED";
            const checked = items.length > 0 && enabled;
            const tooltip = checked ? "Deselect all" : "";

            return { checked, disabled: !enabled, tooltip }
        })
    );

    get isErrorVisible(): boolean {
        return this.ngControl?.control?.invalid && this.ngControl?.control?.touched;
    }

    private onChange = (_value: ItemId[]) => {};
    private onTouch = () => {};

    // Needed to display correct errors under the widget, in case there are any validators
    ngControl?: NgControl;

    constructor(private injector: Injector) {}

    ngOnInit() {
        // Hack to avoid circular dependency error
        this.ngControl = this.injector.get(NgControl);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.tableData && changes.tableData.currentValue) {
            const mappedItems: ListItem[] = this.tableData.map(item => ({
                selected: false,
                disabled: this.isItemDisabled(item),
                data: item,
            }));
            this.fullItemsList$$.next({ propagate: true, value: mappedItems });
            this.searchControl.setValue("");
        }
    }

    writeValue(value: ItemId[]): void {
        // Waiting until all data set and only after that setting new control value
        setTimeout(() => {
            if (Array.isArray(value)) {
                this.control.setValue(value);
            } else {
                this.control.setValue([]);
            }
        }, 0)
    }

    registerOnChange(fn: (value: ItemId[]) => void): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: () => {}): void {
        this.onTouch = () => {
            fn();
            this.control.markAllAsTouched();
        }
    }

    setDisabledState(isDisabled: boolean): void {
        if (isDisabled && this.control.enabled) this.control.disable();
        else if (!isDisabled && this.control.disabled) this.control.enable();
    }

    selectedTabChanged({ index }: MatTabChangeEvent): void {
        if (index === 0) {
            this.allTabViewport.checkViewportSize();
        } else {
            this.selectedTabViewport.checkViewportSize();
        }
    }

    onCheckboxAllChange(items: ListItem[], { checked }: { checked: boolean }): void {
        const selectedIds = new Set(this.control.value);
        const changedItems: ListItem[] = [];

        let nothingChanged = true;
        items.forEach(item => {
            if (!this.isAllowedToChange(item, checked)) return;
            changedItems.push(item);

            nothingChanged = false;
            if (checked) {
                selectedIds.add(item.data[this.idField] as ItemId);
            } else {
                selectedIds.delete(item.data[this.idField] as ItemId);
            }
        });

        if (nothingChanged) return;

        const array = Array.from(selectedIds);
        this.control.setValue(array, { emitEvent: false });
        this.selectionChanged$$.next({ changedItems, isSelected: checked });
        this.onChange(array);
        this.onTouch();
    }

    onCheckboxSingleChange(item: ListItem, { checked }: { checked: boolean }): void {
        this.onCheckboxAllChange([item], { checked });
    }

    trackByItemId = (_index: number, item: ListItem): string => {
        return item.data[this.idField] as string;
    }

    /** Triggered when new value or new items list received from the parent */
    private selectUnsortedItems(allItems: ListItem[], selected: ItemId[]): ListItem[] {
        this.selectedCount = 0;

        const selectedIdsSet = new Set(selected);

        const mappedItems = allItems.map(item => {
            const isSelected = selectedIdsSet.has(item.data[this.idField] as ItemId);

            if (isSelected) this.selectedCount++;

            return { ...item, selected: isSelected };
        });

        // Update list of items, without notice propagate
        this.fullItemsList$$.next({ propagate: false, value: mappedItems });
        return mappedItems;
    }

    /**
     * Triggered when selected items were changed after user interaction, needed for faster recalculation
     *
     * @param allItems List of all items
     * @param changedItems Items, which value should be updated. Also these items must have the same sorting as allItems array
     * @param selected Whether changedItems now should be checked or unchecked
     *
     * @returns all items with correct 'selected' value
     */
    private selectSortedItems(allItems: ListItem[], { changedItems, isSelected }: SelectionChanged): ListItem[] {
        const allItemsCopy = [ ...allItems ];

        let j = 0; // Changed items pointer
        for (let i = 0; i < allItemsCopy.length; i++) {
            if (allItemsCopy[i].data[this.idField] === changedItems[j].data[this.idField]) {
                const itemCopy = { ...allItemsCopy[i], selected: isSelected };
                allItemsCopy[i] = itemCopy;

                if (isSelected) this.selectedCount++;
                else this.selectedCount--;

                j++;
                if (changedItems.length <= j) break;
            }
        }

        if (changedItems.length < j) {
            // Some items in this array was not properly sorted, select them using slow algorithm.
            // Normally this block will be skipped. This is just a safe mechanism
            console.error("multiselect-list.component.ts: Something is wrong. changedItems are not sorted properly");

            const allItemsIndices = new Map<ItemId, number>();
            allItemsCopy.forEach((item, index) => allItemsIndices.set(item.data[this.idField] as ItemId, index));

            for (; j < changedItems.length; j++) {
                const index = allItemsIndices.get(changedItems[j].data[this.idField] as ItemId);
                if (!index) continue;

                const itemCopy = { ...allItemsCopy[index], selected: isSelected };
                allItemsCopy[index] = itemCopy;

                if (isSelected) this.selectedCount++;
                else this.selectedCount--;
            }
        }

        // Update list of items, without notice propagate
        this.fullItemsList$$.next({ propagate: false, value: allItemsCopy });
        return allItemsCopy;
    }

    private searchItems(items: ListItem[], searchString: string): ListItem[] {
        if (!searchString) return items;

        searchString = searchString.toLowerCase();

        const searchedItems = items.filter(item =>
            this.displayedColumns.some(
                col => (item.data[col.value] as string)?.toLowerCase?.().includes(searchString)
            )
        );

        return searchedItems;
    }

    private isItemDisabled(item: MultiselectListItem): boolean {
        if (this.disabledField) {
            return item[this.disabledField] as boolean;
        }
        return false;
    }

    private isAllowedToChange(item: ListItem, isChecked: boolean): boolean {
        return !item.disabled && item.selected !== isChecked;
    }
}
