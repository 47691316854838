import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve, Router } from "@angular/router";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { PasswordManagerService } from "@shared/services/password-manager.service";
import { IPasswordChangeLinkDto } from "@shared/interfaces/password-manager.interfce";

@Injectable()
export class ForgotPasswordResolver implements Resolve<IPasswordChangeLinkDto> {
    constructor(
        private router: Router,
        private api: PasswordManagerService
    ) { }

    resolve(route: ActivatedRouteSnapshot): Observable<IPasswordChangeLinkDto> {
        const requestId = route.queryParamMap.get("k") || this.processRestriction("login");

        return this.api
            .checkLinkExpiration(requestId)
            .pipe(
                map(data => !data?.isActive
                    ? this.processRestriction()
                    : data
                ),
                catchError(() => of(this.processRestriction())),
            );
    }

    private processRestriction(link?: string): null {
        if (link) {
            this.router.navigate([`/${link}`]);
        } else {
            this.router.navigate(["/invalid-link"], { queryParams: { timeout: 2 } });
        }
        return null;
    }
}
