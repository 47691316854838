<div class="table-wrapper"
    [class.scroll]="withPageSize"
>
    <table mat-table
        matSort
        multiTemplateDataRows
        *ngIf="!error"
        [matSortActive]="sortActive"
        [matSortDirection]="sortDirection"
        [matSortDisabled]="sortDisabled"
        [dataSource]="dataSource"
        class="common-table edge-table-expandable"
        [class.zebra]="!withCollapse"
    >
        <ng-container *ngFor="let column of columns; let i = index">
            <ng-container [ngSwitch]="column.value">

                <ng-container *ngIf="withCollapse">
                    <ng-container *ngSwitchCase="'collapse_action'" matColumnDef="collapse_action">
                        <th mat-header-cell *matHeaderCellDef>
                            <button mat-icon-button class="clean small" (click)="expandAllClick()">
                                <mat-icon [class.rotate-90]="allExpanded"
                                        svgIcon="double_arrow"
                                        class="expand-icon">
                                </mat-icon>
                            </button>
                        </th>
                        <td mat-cell *matCellDef="let item">
                            <ng-container
                                *ngTemplateOutlet="severityIndicator; context: { $implicit: { element: item.element, index: i } }">
                            </ng-container>
                            <button *ngIf="canExpand(item.element)"
                                    mat-icon-button
                                    class="clean small"
                                    (click)="elementExpandClick(item)"
                            >
                                <mat-icon [class.rotate-90]="item.expanded"
                                        svgIcon="double_arrow"
                                        class="expand-icon">
                                </mat-icon>
                            </button>
                        </td>
                    </ng-container>
                </ng-container>

                <ng-container *ngSwitchDefault matColumnDef="{{column.value}}">
                    <ng-container *ngIf="column.sortValue; else noSortHeader">
                        <th mat-sort-header="{{column.sortValue}}"
                            *matHeaderCellDef
                            [ngClass]="column.headerCssClasses"
                        >
                            <span [innerHTML]="column.title | safeHTML"></span>
                        </th>
                    </ng-container>
                    <ng-template #noSortHeader>
                        <th *matHeaderCellDef
                            [ngClass]="column.headerCssClasses"
                        >
                            <span [innerHTML]="column.title | safeHTML"></span>
                        </th>
                    </ng-template>
                    <td mat-cell
                        *matCellDef="let item"
                        [ngClass]="column.cellCssClasses"
                    >
                        <ng-container
                            *ngTemplateOutlet="severityIndicator; context: { $implicit: { element: item.element, index: i } }">
                        </ng-container>

                        <ng-template [ngIf]="tableCells[column.value]" [ngIfElse]="defaultTemplate">
                            <ng-container [ngTemplateOutlet]="tableCells[column.value]"
                                [ngTemplateOutletContext]="{ $implicit: item.element }">
                            </ng-container>
                        </ng-template>

                        <ng-template #defaultTemplate>
                            <span [innerHTML]="item.element[column.value] != null ? (item.element[column.value] | safeHTML) : ''"></span>
                        </ng-template>
                    </td>
                </ng-container>
            </ng-container>
        </ng-container>

        <ng-container matColumnDef="expandedDetail" *ngIf="withCollapse">
            <td mat-cell
                *matCellDef="let item"
                [attr.colspan]="columns.length"
            >
                <div class="element-detail"
                    [@detailExpand]="item.expanded ? 'expanded' : 'collapsed'"
                    *ngIf="canExpand(item.element)"
                >
                    <ng-container [ngTemplateOutlet]="expandedDetailsTemplate"
                                [ngTemplateOutletContext]="{ $implicit: item.element, expanded: item.expanded }"
                    ></ng-container>
                </div>
            </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columnNames"></tr>
        <tr mat-row
            *matRowDef="let item; columns: columnNames;"
            (click)="onRowClick(item)"
            class="element-row"
            [ngClass]="{clickable: this.rowClick?.observers?.length}"
            [class.expanded-row]="item.expanded"
        ></tr>
        <ng-container *ngIf="withCollapse">
            <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="details-row"></tr>
        </ng-container>
    </table>

    <edge-no-data *ngIf="!error && totalItems === 0" [text]="emptyMessage"></edge-no-data>
    <edge-no-data *ngIf="error" typeError="error" [text]="getErrorMessage(error)"></edge-no-data>

</div>



<paginator *ngIf="tableIsServerSide" [hidden]="isPaginatorHidden" [withPageSize]="withPageSize"></paginator>

<paginator *ngIf="!tableIsServerSide"
           [hidden]="isPaginatorHidden"
           [dataSource]="dataSource"
           [totalItems]="totalItems"
           [itemsPerPage]="itemsPerPage"
           [withPageSize]="withPageSize"
></paginator>

<ng-template #severityIndicator let-data>
    <div class="severity-wrapper"
         *ngIf="data.index === 0 && data.element && data.element.severity != null"
         [matTooltip]="data.element.severityLabel"
         matTooltipPosition="right"
         matTooltipClass="no-arrow"
    >
        <div class="severity-indicator"
             [edge-severity]="data.element.severity"
             [custom-severities]="customSeverityList"
        ></div>
    </div>
</ng-template>
