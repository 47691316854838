import { Injectable } from "@angular/core";
import { DateAdapter } from "@angular/material/core";
import dayjs from "dayjs";

/** Creates an array and fills it with values. */
function range<T>(length: number, valueFunction: (index: number) => T): T[] {
    const valuesArray = Array(length);
    for (let i = 0; i < length; i++) {
        valuesArray[i] = valueFunction(i);
    }
    return valuesArray;
}

const MONTH_FORMATS = {
    long: "MMMM",
    short: "MMM",
    narrow: "MMM", // Day.js doesn't support more short format of month (J, F, ..., D)
};

const DAY_OF_WEEK_FORMATS = {
    long: "dddd",
    short: "ddd",
    narrow: "dd",
};

// If you want to change locale or pass it dinamically, don't forget to
// update CUSTOM_DATE_FORMATS
const locale = "en";

@Injectable()
export class CustomDateAdapter extends DateAdapter<Date> {
    constructor() {
        super();
        this.setLocale(locale);
    }

    getYear(date: Date): number {
        return date.getFullYear();
    }

    getMonth(date: Date): number {
        return date.getMonth();
    }

    getDate(date: Date): number {
        return date.getDate();
    }

    getDayOfWeek(date: Date): number {
        return date.getDay();
    }

    getMonthNames(style: "long" | "short" | "narrow"): string[] {
        const pattern = MONTH_FORMATS[style];
        return range(12, i => this.format(new Date(2017, i, 1), pattern));
    }

    getDateNames(): string[] {
        const dtf =
            typeof Intl !== "undefined"
                ? new Intl.DateTimeFormat(this.locale.code, {
                      day: "numeric",
                      timeZone: "utc",
                  })
                : null;

        return range(31, i => {
            if (dtf) {
                // dayjs doesn't appear to support this functionality.
                // Fall back to `Intl` on supported browsers.
                const date = new Date();
                date.setUTCFullYear(2017, 0, i + 1);
                date.setUTCHours(0, 0, 0, 0);
                return dtf.format(date).replace(/[\u200e\u200f]/g, "");
            }

            return i + "";
        });
    }

    getDayOfWeekNames(style: "long" | "short" | "narrow"): string[] {
        const pattern = DAY_OF_WEEK_FORMATS[style];
        return range(7, i => this.format(new Date(2017, 0, i + 1), pattern));
    }

    getYearName(date: Date): string {
        return this.format(date, "YYYY");
    }

    getFirstDayOfWeek(): number {
        return this.locale.options?.weekStartsOn ?? 0;
    }

    getNumDaysInMonth(date: Date): number {
        return dayjs(date).daysInMonth();
    }

    clone(date: Date): Date {
        return new Date(date.getTime());
    }

    createDate(year: number, month: number, date: number): Date {
        // Passing the year to the constructor causes year numbers <100 to be converted to 19xx.
        // To work around this we use `setFullYear` and `setHours` instead.
        const result = new Date();
        result.setFullYear(year, month, date);
        result.setHours(0, 0, 0, 0);

        return result;
    }

    today(): Date {
        return new Date();
    }

    parse(value: any, parseFormat: string | string[]): Date | null {
        if (typeof value == "string" && value.length > 0) {
            if (Array.isArray(parseFormat) && !parseFormat.length) {
                throw Error("Formats array must not be empty.");
            }

            const date = dayjs(value, parseFormat).toDate();

            if (this.isValid(date)) return date;
            else return this.invalid();
        } else if (typeof value === "number") {
            return new Date(value);
        } else if (value instanceof Date) {
            return this.clone(value);
        }

        return null;
    }

    format(date: Date, displayFormat: string): string {
        if (!this.isValid(date)) {
            throw Error("DateFnsAdapter: Cannot format invalid date.");
        }

        return dayjs(date).locale(locale).format(displayFormat);
    }

    addCalendarYears(date: Date, years: number): Date {
        return dayjs(date).add(years, "year").toDate();
    }

    addCalendarMonths(date: Date, months: number): Date {
        return dayjs(date).add(months, "month").toDate();
    }

    addCalendarDays(date: Date, days: number): Date {
        return dayjs(date).add(days, "day").toDate();
    }

    toIso8601(date: Date): string {
        return dayjs(date).format("YYYY-MM-DD");
    }

    /**
     * Returns the given value if given a valid Date or null. Deserializes valid ISO 8601 strings
     * (https://www.ietf.org/rfc/rfc3339.txt) into valid Dates and empty string into null. Returns an
     * invalid date for all other values.
     */
    deserialize(value: any): Date | null {
        if (typeof value === "string") {
            if (!value) {
                return null;
            }
            const date = dayjs(value).toDate();
            if (this.isValid(date)) {
                return date;
            }
        }
        return super.deserialize(value);
    }

    isDateInstance(obj: any): boolean {
        return obj instanceof Date;
    }

    isValid(date: Date): boolean {
        return !isNaN(date.getTime());
    }

    invalid(): Date {
        return new Date(NaN);
    }
}
