/* eslint-disable */
import { Injectable } from "@angular/core";
import { BehaviorSubject, Subject } from "rxjs";
import { IOrganizationConfigDto } from "@core/interfaces/dto/user-profile-dto.interface";

export interface IOrganizationDataAvailabilityFlags {
    ANY: boolean;
    COLOCATION: boolean;
    COLOCATION_ONLY: boolean;
    NETWORK: boolean;
    DATACENTER_PHYSICAL_ACCESS: boolean;
    DATACENTER_PHYSICAL_ACCESS_TABS: boolean;
    MANAGED_SYSTEMS: boolean;
    MANAGED_SYSTEMS_VPC: boolean;
    MANAGED_SYSTEMS_LICENSING: boolean;
    MANAGED_SYSTEMS_SERVERS: boolean;
    MANAGED_SYSTEMS_FIREWALL: boolean;
    MANAGED_SYSTEMS_VPG: boolean;
    MANAGED_SYSTEMS_LOAD_BALANCER: boolean;
    MANAGED_SYSTEMS_STORAGE: boolean;
    MANAGED_SERVICES_CLOUDFLARE: boolean;
    SECURITY: boolean;
    ANALYTICS: boolean;
    ANALYTICS_MONITORING_DASHBOARD: boolean;
    MY_ACCOUNT: boolean;
    BKUP: boolean;
}

const DEFAULT_FLAGS: IOrganizationDataAvailabilityFlags = {
    ANY: true,
    COLOCATION: false,
    COLOCATION_ONLY: false,
    NETWORK: false,
    DATACENTER_PHYSICAL_ACCESS: false,
    DATACENTER_PHYSICAL_ACCESS_TABS: false,
    MANAGED_SYSTEMS: false,
    MANAGED_SYSTEMS_VPC: false,
    MANAGED_SYSTEMS_LICENSING: false,
    MANAGED_SYSTEMS_SERVERS: false,
    MANAGED_SYSTEMS_FIREWALL: false,
    MANAGED_SYSTEMS_VPG: false,
    MANAGED_SYSTEMS_LOAD_BALANCER: false,
    MANAGED_SYSTEMS_STORAGE: false,
    MANAGED_SERVICES_CLOUDFLARE: false,
    SECURITY: false,
    ANALYTICS: false,
    ANALYTICS_MONITORING_DASHBOARD: false,
    MY_ACCOUNT: false,
    BKUP: false,
};

@Injectable({
    providedIn: "root",
})
export class OrganizationDataAvailabilityStorageService {
    public data: Subject<IOrganizationDataAvailabilityFlags> = new BehaviorSubject(null);
    private _config: IOrganizationConfigDto;

    /** ************************************************************************
     Flags
     ************************************************************************* */

    private _flags: IOrganizationDataAvailabilityFlags = {
        ...DEFAULT_FLAGS,
    };

    private calculation = {
        NETWORK: (hasAssetsNPorts: boolean = false) => {
            let changed = false;
            if (!this._flags.NETWORK && hasAssetsNPorts) {
                this._flags.NETWORK = true;
                changed = true;
            }
            // inform that the change happened or wasn't
            return changed;
        },
    };

    constructor() {}

    /** ************************************************************************
     Configuration
     ************************************************************************* */
    get config(): IOrganizationConfigDto {
        return this._config;
    }

    /** ************************************************************************
     Flags
     ************************************************************************* */
    get flags(): IOrganizationDataAvailabilityFlags {
        return this._flags;
    }

    // update flag property value based on input parameters
    recalculateFlag(name: string, parameters: any): void {
        if (typeof this.calculation[name] === "function" && this.calculation[name](parameters)) {
            // fire update
            this.data.next(this._flags);
        }
    }

    /** ************************************************************************
     DATA
     ************************************************************************* */
    setData(value: IOrganizationConfigDto): void {
        this._config = value || null;
        // reset flags values
        const initialFlags = {
            ...DEFAULT_FLAGS,
        };
        // calculate initial flags values based on organization config
        if (value) {
            initialFlags.COLOCATION = value.hasColo;
            initialFlags.COLOCATION_ONLY = value.isColocationOnly;
            initialFlags.NETWORK = value.hasInternet;
            initialFlags.DATACENTER_PHYSICAL_ACCESS = !value.hasOnlyBWI1;
            initialFlags.DATACENTER_PHYSICAL_ACCESS_TABS =
                value.hasNonBWIActiveOrderedColo || value.hasAnyProduct;
            initialFlags.MANAGED_SYSTEMS =
                value.hasProductAnyBoolPropertyNotFalse ||
                value.hasVpcData ||
                value.hasLicensingData;
            initialFlags.MANAGED_SYSTEMS_VPC = value.hasVpcData;
            initialFlags.MANAGED_SYSTEMS_LICENSING = value.hasLicensingData;
            initialFlags.MANAGED_SYSTEMS_SERVERS =
                value.hasSAN ||
                value.hasNAS ||
                value.hasDEDI ||
                value.hasCP ||
                value.hasPVM ||
                value.hasAWS;
            initialFlags.MANAGED_SYSTEMS_FIREWALL = value.hasFW;
            initialFlags.MANAGED_SYSTEMS_VPG = value.hasZerto;
            initialFlags.MANAGED_SYSTEMS_LOAD_BALANCER = value.hasVIP;
            initialFlags.MANAGED_SYSTEMS_STORAGE = value.hasNAS || value.hasSAN;
            initialFlags.MANAGED_SERVICES_CLOUDFLARE = value.hasCloudFlare;
            initialFlags.SECURITY = value.hasFW || value.hasIPsIDs;
            initialFlags.ANALYTICS = value.hasProductAnyBoolPropertyNotFalse;
            initialFlags.ANALYTICS_MONITORING_DASHBOARD = value.hasSCLID;
            initialFlags.MY_ACCOUNT = true;
            initialFlags.BKUP = value.hasBKUP;
        }
        this._flags = initialFlags;
        // fire update
        this.data.next(initialFlags);
    }

    public checkOrganizationFlag(flag: string): boolean {
        if (flag && !this.flags[flag]) {
            return false;
        }
        return true;
    }
}
