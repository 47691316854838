import { Component, Input, TemplateRef } from "@angular/core";

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "table-cell-template",
    template: "<ng-container [ngTemplateOutlet]=\"template\"></ng-container>",
})
export class TableExpandableCellTemplateComponent {
    @Input() columName: string;
    @Input() template: TemplateRef<any>;
}
