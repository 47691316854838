<ul class="edge-progress-wrapper">
    <li class="edge-progress-header">
        <span>
            <ng-content></ng-content>
            {{titleName}}
        </span>
        <span>{{label}}</span>
    </li>
    <li class="edge-progress" *ngIf="type === 'PERCENTAGE'; else default">
        <div class="edge-progress-bar x{{width}} {{colorClass}}" matTooltip="{{progressTitle}}"></div>
    </li>
    <ng-template #default>
        <li class="edge-progress">
            <div class="edge-progress-bar x{{width}} {{colorClass}}" matTooltip="{{progressTitle}}"></div>
        </li>
    </ng-template>
</ul>