<div class="portal edge-portal">
    <div class="edge-portal-aside {{ sideBarStatusClass }}" *ngIf="(isPublic$ | async) === false">
        <edge-sidebar></edge-sidebar>
    </div>
    <div class="edge-portal-content">
        <div class="edge-container" *ngIf="{ template: subTopBarTemplate$ | async } as vm">
            <div class="top-bar-container" [class.with-sub-top-bar]="vm.template">
                <edge-top-bar-shell></edge-top-bar-shell>
            </div>

            <div class="edge-sub-top-bar" *ngIf="vm.template">
                <ng-container [ngTemplateOutlet]="vm.template"></ng-container>
            </div>
            

            <edge-navigation-indicator class="navigation-indicator"></edge-navigation-indicator>

            <div class="edge-portal-content-container">
                <div class="edge-container" id="portal-container">
                    <div class="edge-page">
                        <router-outlet></router-outlet>
                    </div>
                    <edge-portal-footer></edge-portal-footer>
                </div>
            </div>
        </div>
    </div>
</div>
