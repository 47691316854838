import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { webApiUrls } from "@core/constants/url.constant";
import { UserStorageService } from "@core/services/storages/user-storage.service";
import { IPasswordChangeLinkDto } from "@shared/interfaces/password-manager.interfce";

interface IUpdateCredentialsByRequestIdParams {
    password: string;
    requestId: string;
    userName: string;
}

interface IUpdatePasswordsParams {
    oldPassword: string;
    newPassword: string;
    username?: string;
}

@Injectable({
    providedIn: "root",
})
export class PasswordManagerService {
    constructor(
        private http: HttpClient,
        private userStorageService: UserStorageService
    ) {}

    public checkOldPasswords(
        oldPassword: string,
        username?: string
    ): Observable<boolean> {
        const path = `${webApiUrls.accountManagement}/Users/CredentialsCheck`;
        const params = {
            password: oldPassword,
            userName: username?.length ? username : this.userStorageService.getUserName(),
        };
        return this.http.post<boolean>(path, params);
    }

    public checkNewPasswords(
        newPassword: string,
        username?: string
    ): Observable<boolean> {
        const path = `${webApiUrls.accountManagement}/Users/PasswordUniqueness`;
        const params = {
            password: newPassword,
            userName: username?.length ? username : this.userStorageService.getUserName(),
        };
        return this.http.post<boolean>(path, params);
    }

    public saveNewPassword(
        params: IUpdatePasswordsParams
    ): Observable<void> {
        const path = `${webApiUrls.accountManagement}/Users/Credentials`;
        const payload = {
            oldPassword: params.oldPassword,
            password: params.newPassword,
            userName: params.username || this.userStorageService.getUserName(),
        };
        return this.http.put<void>(path, payload);
    }

    public updateCredentialsByRequestId(
        params: IUpdateCredentialsByRequestIdParams
    ): Observable<void> {
        const path = `${webApiUrls.accountManagement}/ResetPassword/${params.requestId}/Credentials`;
        return this.http.put<void>(path, params);
    }

    public checkLinkExpiration(requestId: string): Observable<IPasswordChangeLinkDto> {
        const path = `${webApiUrls.accountManagement}/ResetPassword/${requestId}`;
        return this.http.get<IPasswordChangeLinkDto>(path);
    }
}
