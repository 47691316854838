<div class="top-bar-list">
    <button mat-icon-button
            class="clean"
            [class.animate]="data?.totalItemsCount"
            [matMenuTriggerFor]="eventMenu"
            #matMenu="matMenuTrigger"
            matTooltip="Open Tickets"
            matTooltipPosition="below"
            matTooltipClass="below"
            id="top-btnOpenTickets"
    >
        <mat-icon svgIcon="email"></mat-icon>
    </button>
    <mat-menu #eventMenu="matMenu" xPosition="before" class="top-bar-panel">
        <mat-card (click)="$event.stopPropagation()">
            <mat-card-header>
                <div class="toolbar">
                    <div class="toolbar-left">
                        <div class="toolbar-group">
                            <mat-card-title>Open Tickets</mat-card-title>
                        </div>
                        <div class="toolbar-group">
                            <h4 class="widget-value">{{data?.totalItemsCount}}</h4>
                        </div>
                        <div class="toolbar-group" *edgeAvailability="permConst.SupportPageTicketHistory; action: permActions.add">
                            <button id="top-bar-tickets-add__button"
                                    mat-icon-button
                                    class="flat"
                                    matTooltip="Add New Ticket"
                                    matTooltipPosition="below"
                                    matTooltipClass="below"
                                    (click)="onAdd(matMenu)">
                                <mat-icon svgIcon="add"></mat-icon>
                            </button>
                        </div>
                    </div>

                    <div class="toolbar-right" *edgeAvailability="permConst.SupportPage">
                        <button id="top-bar-tickets-see__button"
                                mat-stroked-button
                                class="next"
                                (click)="onSeeAll(matMenu)"
                        >See All
                            <mat-icon svgIcon="expand"></mat-icon>
                        </button>
                    </div>
                </div>
            </mat-card-header>

            <mat-card-content [loading]="isLoading">
                <ng-template [ngIf]="!data?.totalItemsCount" [ngIfElse]="table">
                    <edge-no-data></edge-no-data>
                </ng-template>

                <ng-template #table>
                    <table-expandable [tableIsServerSide]="true"
                                      [displayedColumns]="meta.ticketColumns"
                                      [expandedDetailsTemplate]="rowDetails"
                                      [tableData$]="data$"
                                      (expandChange)="onExpandChange($event)"
                                      *ngIf="matMenu.menuOpen">
                        <table-cell-template columName="summary" [template]="ticketSummary"></table-cell-template>
                        <table-cell-template columName="ticketNumber" [template]="ticketNumber"></table-cell-template>
                    </table-expandable>
                </ng-template>

                <ng-template #ticketSummary let-option>
                    <div *ngIf="option?.summary" class="ticket-cell-template">
                        <ng-container *edgeAvailability="permConst.SupportTicketDetails; else: ticketSummaryText">
                            <a (click)="onOpen(option._ticketNumber, matMenu)"
                               [matTooltip]="option.summary"
                               id="top-bar-open-ticket-1__link"
                               matTooltipPosition="below"
                               matTooltipClass="below">{{option.summary}}</a>
                        </ng-container>

                        <ng-template #ticketSummaryText>
                            <span>{{option.summary}}</span>
                        </ng-template>
                    </div>
                </ng-template>

                <ng-template #ticketNumber let-option>
                    <ng-container *ngIf="option?._ticketNumber">
                        <div *edgeAvailability="permConst.SupportTicketDetails; else: ticketNumberText" class="ticket-cell-template">
                            <a id="top-bar-open-ticket-2__link"
                               (click)="onOpen(option._ticketNumber, matMenu)"
                            >{{option._ticketNumber}}</a>
                        </div>

                        <ng-template #ticketNumberText>
                            <span>{{option._ticketNumber}}</span>
                        </ng-template>
                    </ng-container>
                </ng-template>

                <ng-template #rowDetails let-option>
                    <div class="expanded-details-wrapper" *ngIf="option.collapsedData; let collapsedData">
                        <table class="common-table zebra" [loading]="collapsedData.isLoading">
                            <tbody>
                            <tr>
                                <th style="width: 25%;">Contact</th>
                                <td>{{collapsedData.contact}}</td>
                            </tr>
                            <tr>
                                <th>Email</th>
                                <td [innerHTML]="collapsedData.email"></td>
                            </tr>
                            <tr>
                                <th>Summary</th>
                                <td class="summary">{{collapsedData.summary}}</td>
                            </tr>
                            <tr>
                                <th>Devices</th>
                                <td>{{collapsedData.devices}}</td>
                            </tr>
                            <tr>
                                <th style="width: 25%; vertical-align: baseline;">Last Events</th>
                                <td>
                                    <edge-timeline-item *ngFor="let item of collapsedData.lastEvents"
                                                        [time]="item.openDate"
                                                        iconSize="small">
                                        <ng-container icon>
                                            <mat-icon svgIcon="circle" class="color-primary"></mat-icon>
                                        </ng-container>

                                        <ng-container body>
                                            <h4 class="edge-timeline-title">{{item.openName}} - {{item.summary}}</h4>
                                            <p class="edge-timeline-description">{{item.notes}}</p>
                                        </ng-container>
                                    </edge-timeline-item>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>
    </mat-menu>
</div>
