import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpParams,
} from "@angular/common/http";
import { Observable } from "rxjs";
import { OrganizationStorageService } from "@core/services/storages/organization-storage.service";

@Injectable()
export class UserParamInterceptor implements HttpInterceptor {
    constructor(private organizationStorageService: OrganizationStorageService) { }

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (request.headers.has("skip-userParam") || request.url.startsWith("assets/icons")) {
            return next.handle(request);
        }

        const organization = this.organizationStorageService.activeOrganization;
        let params = new HttpParams({ fromString: request.params.toString() });
        if (organization) {
            const group = this.organizationStorageService.activeGroup;
            const userId = this.organizationStorageService.getUserId()

            if (!request.params.has("groupId") && group?.groupId) {
                params = params.set("groupId", group.groupId);
            }

            if (!request.params.has("organizationId") && organization?.id) {
                params = params.set("organizationId", organization.id);
            }

            if (!request.params.has("userId") && userId) {
                params = params.set("userId", userId);
            }
        }

        const newReq = request.clone({ params });

        return next.handle(newReq);
    }
}
