import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class LoggerService {
    log(message: string, ...rest: any[]): void {
        if (environment.debug) {
            if (rest.length) {
                /* eslint-disable */
                console.groupCollapsed(message);
                console.log(...rest);
                console.groupEnd();
                /* eslint-enable */
            } else {
                // eslint-disable-next-line no-console
                console.log(message);
            }
        }
    }
}
