import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MAT_SELECT_CONFIG } from "@angular/material/select";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { MAT_TABS_CONFIG } from "@angular/material/tabs";
import { NgxMaskModule } from "ngx-mask";
import { CoreModule } from "@core/core.module";
import { SupportApiService } from "@shared/services/support-api.service";
import { TicketsHistoryMapService } from "@shared/services/tickets-history-map.service";
import { SHARED_COMPONENTS } from "./constants/components.constant";
import { MATERIAL_MODULES } from "./constants/material.constant";
import { MultiselectListControlModule } from "./modules/controls/multiselect-list/multiselect-list.module";
import { PaginatorControlModule } from "./modules/controls/paginator/paginator.module";
import { ValidationErrorModule } from "./modules/controls/validation-error/validation-error.module";
import { SHARED_DIRECTIVES } from "./constants/directives.constant";
import { PhonePipe } from "./pipes/phone.pipe";
import { DialogService } from "./components/dialog/dialog.service";

@NgModule({
    declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, PhonePipe],
    imports: [
        CoreModule,
        CommonModule,
        ...MATERIAL_MODULES,

        // Shared modules
        PaginatorControlModule,
        MultiselectListControlModule,
        ValidationErrorModule,

        // Third-party modules
        NgxMaskModule.forRoot({ validation: false }),
    ],
    exports: [
        CommonModule,
        ...MATERIAL_MODULES,
        ...SHARED_COMPONENTS,
        ...SHARED_DIRECTIVES,

        // Shared modules
        PaginatorControlModule,
        MultiselectListControlModule,
        ValidationErrorModule,

        // Third-party modules
        NgxMaskModule,

        PhonePipe,
    ],
    providers: [
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: { appearance: "outline", floatLabel: "always" },
        },
        {
            provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
            useValue: { horizontalPosition: "right", verticalPosition: "top" },
        },
        { provide: MAT_TABS_CONFIG, useValue: { animationDuration: "0ms" } },
        {
            provide: MAT_SELECT_CONFIG,
            useValue: { disableOptionCentering: true },
        },
        SupportApiService,
        TicketsHistoryMapService,
        DialogService,
    ],
})
export class SharedModule {}
