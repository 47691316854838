<div class="top-bar-user">
    <button mat-icon-button
            class="user-initials"
            [matMenuTriggerFor]="user"
            matTooltip="My Profile"
            matTooltipPosition="below"
            matTooltipClass="below"
            id="top-btnMyProfile"
    >
        <mat-icon svgIcon="person"></mat-icon>
    </button>
    <mat-menu #user="matMenu" xPosition="before" class="top-bar-panel user">
        <div class="user-content">
            <img [src]="userInfo.personImageUrl" (error)="imageErrorHandler($event)" class="user-photo">
            <span class="user-name">
                {{userInfo.getUserFullName()}}
            </span>
        </div>
        <div class="separator"></div>
        <div class="user-routs">
            <button id="top-bar-user-go-to-profile__button" mat-menu-item routerLink="/my-profile">
                <mat-icon svgIcon="person"></mat-icon>
                <span>my profile</span>
            </button>
            <button id="top-bar-user-go-to-alarms__button" mat-menu-item routerLink="/alarms">
                <mat-icon svgIcon="warning"></mat-icon>
                <span>active alarms</span>
            </button>
            <button id="top-bar-user-go-to-tickets__button" mat-menu-item
                routerLink="/support/history" [state]="{ filterParams: { ExcludePatching: true } }"
            >
                <mat-icon svgIcon="email"></mat-icon>
                <span>open tickets</span>
            </button>
        </div>
    </mat-menu>
</div>
