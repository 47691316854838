import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Route, Router, UrlSegment, UrlTree } from "@angular/router";

/**
 * After tech debt release there is a risk, that we will not update all links to the portal in Epicor, e-mails or any other external services.
 * To minimize the risk that something may break, we decided to check whether it is old URL or not.
 * And if yes - we redirect user to the new URL and log such redirection in Database, so we understand, that we have missed something.
 *
 * Only routes that have changed are present here.
 *
 * This file should be removed after a few monthes in production, after we see that everything works correctly.
 */

type OldUrl = string;
type NewUrl = string;
type NewUrlTreeCreationFn = (
    router: Router,
    params: Record<string, string>,
    queryParams: Record<string, string>,
    fragment?: string
) => UrlTree

const oldRoutesMap: {[key: OldUrl]: NewUrl | NewUrlTreeCreationFn } = {
    "support/new-ticket/request-disconnect": "support/request-disconnect-ticket",
    "support/new-ticket/billing-inquiry": "support/billing-inquiry",
    "video-tutorials": "support/video-tutorials",
    "support-call": "support/call",
    //"support-feedback": "support/feedback",
    "ticket-details/:ticketNumber": (router, { ticketNumber }, queryParams, fragment) => {
        return router.createUrlTree(["support/ticket-details", ticketNumber], { queryParams, fragment });
    },
    "datacenter-access/deactivate-access/:cardholderBadgeKey": (router, { cardholderBadgeKey }, queryParams, fragment) => {
        return router.createUrlTree(["datacenter-access/deactivate-access"], { queryParams: { ...queryParams, cardholderBadgeKey}, fragment });
    },
    "interconnects": (router, _urlParams, queryParams, fragment) => {
        const { tab } = popObject(queryParams, "tab");
        return router.createUrlTree([`colocation/interconnects/${tab ?? ""}`], { queryParams, fragment });
    },
    "interconnect/:orderId": (router, { orderId }, queryParams, fragment) => {
        return router.createUrlTree(["colocation/interconnects/orders", orderId], { queryParams, fragment });
    },
    "firewall/:productId": (router, { productId }, queryParams, fragment) => {
        return router.createUrlTree(["managed-systems/firewall", productId], { queryParams, fragment});
    },
    "firewall/add-vpn-tunnel/:productId": (router, { productId }, queryParams, fragment) => {
        return router.createUrlTree(["managed-systems/firewall", productId, "add-vpn-tunnel"], { queryParams, fragment});
    },
    "firewall/manage-access-rule/:productId": (router, { productId }, queryParams, fragment) => {
        return router.createUrlTree(["managed-systems/firewall", productId, "manage-access-rule"], { queryParams, fragment});
    },
    "system-server/:assetNumber": (router, { assetNumber }, queryParams, fragment) => {
        return router.createUrlTree(["managed-systems/server", assetNumber], { queryParams, fragment});
    },
    "nas/:assetNumber": (router, { assetNumber }, queryParams, fragment) => {
        return router.createUrlTree(["managed-systems/nas", assetNumber], { queryParams, fragment});
    },
    "systems": "managed-systems",
    "san/:assetNumber": (router, { assetNumber }, queryParams, fragment) => {
        return router.createUrlTree(["managed-systems/san", assetNumber], { queryParams, fragment});
    },
    "security?anchor": (router, _urlParams, queryParams) => {
        const { anchor } = popObject(queryParams, "anchor");
        return router.createUrlTree(["security"], { queryParams, fragment: anchor});
    },
    "services": "managed-services",
    "services/ssl-certificates": "managed-services/ssl-certificates",
    "services/dns-zones": "managed-services/dns-zones",
    "services/cloudflare-analytics": "managed-services/cloudflare-analytics",
    "load-balancer": "managed-systems/load-balancer",
    "load-balancer-properties/:name/:partition/:hostId": (router, { name, partition, hostId }, queryParams, fragment) => {
        return router.createUrlTree(["managed-systems/load-balancer/properties", name, partition, hostId], { queryParams, fragment});
    },
    "dns-zone-details": (router, _urlParams, queryParams, fragment) => {
        const { domainName } = popObject(queryParams, "domainName");
        return router.createUrlTree(["managed-services/dns-zones", domainName], { queryParams, fragment});
    },
    "account-management": "analytics/account-management",
    "account-management/tickets": "analytics/account-management/tickets",
    "account-management/alarms": "analytics/account-management/alarms",
    "account-management/backups": "analytics/account-management/backups",
    "account-management/restores": "analytics/account-management/restores",
    // TODO: Falls into recursion
    // "compliance": (router, _urlParams, queryParams) => {
    //     const { scrollTarget } = popObject(queryParams, "scrollTarget");
    //     return router.createUrlTree(["compliance"], { queryParams, fragment: scrollTarget});
    // },
    "order-form/:quoteId/:method": (router, { quoteId, method }, queryParams, fragment) => {
        return router.createUrlTree(["order/form", quoteId], { queryParams: { ...queryParams, method }, fragment});
    },
    "order-approval-completed": "order/approval-completed",
    "order-confirmation/:quoteId": (router, { quoteId }, queryParams, fragment) => {
        return router.createUrlTree(["order/confirmation", quoteId], { queryParams, fragment});
    },
    "order-detail": (router, _urlParams, queryParams, fragment) => {
        const { quoteID, QuoteID, quoteId } = popObject(queryParams, ["quoteID", "QuoteID", "quoteId"]);
        return router.createUrlTree(["order/detail", quoteID || QuoteID || quoteId], { queryParams, fragment});
    },
    "commercial-review": (router, _urlParams, queryParams, fragment) => {
        const { quoteID, QuoteID, quoteId } = popObject(queryParams, ["quoteID", "QuoteID", "quoteId"]);
        return router.createUrlTree(["order/commercial-review", quoteID || QuoteID || quoteId], { queryParams, fragment});
    },
    "my-account": "my-account/overview",
    "manage-users": "my-account/manage-users",
    "transactions/:transactionId": (router, { transactionId }, queryParams, fragment) => {
        return router.createUrlTree(["my-account/transaction", transactionId], { queryParams, fragment});
    },
    "manage-roles": "my-account/manage-roles",
    "manage-roles/:roleId": (router, { roleId }, queryParams, fragment) => {
        return router.createUrlTree(["my-account/manage-roles", roleId], { queryParams, fragment});
    },
    "manage-tags": "my-account/manage-tags",
    "login/simplelogin": "login",
    "login/login-help": "login-help",
    "login/register": "register",
    "login/unsubscribe": "unsubscribe",
    "login/twofactorlogin": "twofactorlogin",
    "login/wizard": "wizard",
    "login/setup-account": "setup-account"
};

const redirectToNewUrl: CanActivateFn = (route) => {
    const router = inject(Router);

    const { initialUrl } = router.getCurrentNavigation();
    const newUrl = createNewUrl(router, route, route.params.oldRouteKey);

    // TODO: Send old URL to Backend
    const oldURL = router.serializeUrl(initialUrl);

    // Perform redirect to the new URL
    const bold = "font-weight: bold";
    const normal = "font-weight: normal";
    console.warn(`Redirection from the old URL:\n%c${oldURL}%c\nto the new URL:\n%c${router.serializeUrl(newUrl)}`, bold, normal, bold);
    return newUrl;
};

export const oldRoutesRedirection: Route = {
    matcher: (segments) => {
        const route = findMatchingRoute(segments);
        if (!route) return { consumed: [] };

        return {
            consumed: segments,
            posParams: {
                oldRouteKey: new UrlSegment(route.key, {}),
                ...route.urlParams
            }
        };
    },
    loadComponent: () => null,
    canActivate: [redirectToNewUrl],
};

// Helper functions

function findMatchingRoute(segments: UrlSegment[]): { key: string, urlParams: Record<string, UrlSegment> } | null {
    const simpleSegments = segments.map(s => s.path);
    let urlParams: Record<string, UrlSegment>;

    const foundRoute = Object.keys(oldRoutesMap).find(route => {
        const routeSegments = route.split("/");
        if (routeSegments.length !== simpleSegments.length) return false;

        urlParams = {};
        for (let [index, segment] of routeSegments.entries()) {
            // Check if segment is a dynamic parameter
            if (segment.startsWith(":") && !!simpleSegments[index]) {
                const paramKey = segment.slice(1);
                urlParams[paramKey] = new UrlSegment(simpleSegments[index], {});
            } else if (segment !== simpleSegments[index]) return false; // Segment is incorrect, whole URL is incorrect
        }

        return true;
    });

    return foundRoute ? { key: foundRoute, urlParams } : null;
}

function createNewUrl(router: Router, route: ActivatedRouteSnapshot, oldRouteKey: string): UrlTree {
    const newRoute = oldRoutesMap[oldRouteKey];
    const { fragment, queryParams, params } = route;

    if (typeof newRoute === "string") {
        return router.createUrlTree([newRoute], { fragment, queryParams });
    }
    return newRoute(router, { ...params }, { ...queryParams }, fragment);
}

function popObject(object: Record<string, any>, properties: string | string[]): Record<string, any> {
    const output: Record<string, any> = {};
    (typeof properties === "string" ? [properties] : properties).forEach(p => {
        output[p] = object[p];
        delete object[p];
    });
    return output;
}
