export const META_DATA = {
    _default: {
        panelClass: "info",
        data: "Notification",
        duration: 4000,
    },
    info: {
        panelClass: "info",
        data: "Notification",
        duration: 4000,
    },
    warning: {
        panelClass: "warning",
        data: "Warning!",
        duration: 4000,
    },
    success: {
        panelClass: "success",
        data: "Success!",
        duration: 4000,
    },
    error: {
        panelClass: "error",
        data: "Error!",
        duration: null,
    },
};
