export enum TimeframeSettingsOption {
    Today = 0,
    Yesterday = 1,
    Last7Days = 2,
    Last30Days = 3,
    ThisMonth = 4,
    LastMonth = 5,
    Custom = 6,
    Last1hour = 7,
    Last6hours = 8,
    Last12hours = 9,
    Last24hours = 10,
    Last48hours = 11,
    ThisWeek = 12,
    LastWeek = 13,
    ThisQuarter = 14,
    LastQuarter = 15,
    ThisYear = 16,
    LastYear = 17,
    Last30Minutes = 18,
}

export enum TimeframeLabel {
    Today = "Today",
    Yesterday = "Yesterday",
    Last1hour = "1 hour",
    Last6hours = "6 hours",
    Last12hours = "12 hours",
    Last24hours = "24 hours",
    Last48hours = "48 hours",
    Last7Days = "Last 7 days",
    Last30Days = "Last 30 days",
    Custom = "Custom",
    ThisWeek = "This Week",
    ThisMonth = "This Month",
    ThisQuarter = "This Quarter",
    ThisYear = "This Year",
    LastWeek = "Last Week",
    LastMonth = "Last Month",
    LastQuarter = "Last Quarter",
    LastYear = "Last Year",
}
