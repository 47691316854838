import {
    Component,
    OnInit,
    ChangeDetectionStrategy,
    OnDestroy,
    ChangeDetectorRef,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Observable, Subject, of } from "rxjs";
import { catchError, finalize, map, take, takeUntil } from "rxjs/operators";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { AuthApiService } from "@core/services/auth-api.service";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { IQuickLink, ISetting } from "@core/interfaces/settings.interface";
import { PermissionsStorageService } from "@core/services/storages/permissions-storage.service";
import { PERMISSIONS } from "@core/constants/permission.constant";
import { ProductAlarmsApiService } from "@shared/services/product-alarms.service";
import { TableData } from "@shared/components/table-expandable/table-expandable.component";
import { SupportApiService } from "@shared/services/support-api.service";
import { SupportTicket } from "@shared/interfaces/support.interface";
import { EdgeDatePipe } from "@core/pipes/edge-date.pipe";
import { RouterHelper } from "@core/helpers/router-helper";
import { NewsItem } from "@shared/interfaces/news.interface";
import {
    AlarmPaginatedData,
    CollapsedData,
    Ticket,
    TicketPaginatedData,
} from "../../interfaces/top-bar.interface";
import { METADATA } from "../../top-bar.metadata";

@Component({
    selector: "edge-top-bar-shell",
    templateUrl: "./top-bar-shell.component.html",
    styleUrls: ["./top-bar-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarShellComponent implements OnInit, OnDestroy {
    public news: NewsItem[] = [];
    public isLogoutInProgress = false;

    public settings: ISetting | null = null;

    public isAlarmsLoading = true;
    public alarms$: Observable<AlarmPaginatedData>;

    public isTicketsLoading = true;
    public tickets$: Observable<TicketPaginatedData<TableData<Ticket>>>;

    private destroy$: Subject<void> = new Subject<void>();

    /* #48000 - Hide functionality until further notice
    private localStorageKey = "ngStorage-hiddenNews"; */

    constructor(
        private router: Router,
        /* #48000 - Hide functionality until further notice
        private commonLookupsApiService: CommonLookupsApiService, */
        private datePipe: EdgeDatePipe,
        private support: SupportApiService,
        private authService: AuthApiService,
        private productAlarms: ProductAlarmsApiService,
        private permissions: PermissionsStorageService,
        private settingsService: SettingStorageService,
        private authStorageService: AuthStorageService,
        private route: ActivatedRoute,
        private cdr: ChangeDetectorRef
    ) { }

    static getCollapsedData(item: SupportTicket): CollapsedData {
        return {
            contact: item.openByUserFullName,
            email: `<a href='mailto:${item.openedByUserEmail}'>${item.openedByUserEmail}</a>`,
            summary: item.summary,
            devices: item?.products.map(value => value.assetNumber).join(", ") || "",
            lastEvents: null,
            isLoading: false,
        };
    }

    ngOnInit(): void {
        this.settingsService
            .getCurrentUserSetting$()
            .pipe(takeUntil(this.destroy$))
            .subscribe(settings => {
                this.settings = settings;
                this.cdr.detectChanges();
            });

        if (this.isUserAuthorized) {

            this.alarms$ = this.productAlarms
                .getProductAlarms()
                .pipe(
                    takeUntil(this.destroy$),
                    catchError(() => of(METADATA.defaultValue)),
                    finalize(() => {
                        this.isAlarmsLoading = false;
                    })
                )

            /* #48000 - Hide functionality until further notice
            this.getNews(); */

            this.tickets$ = this.support
                .getTicketsHistory(METADATA.ticketReqParams)
                .pipe(
                    take(1),
                    catchError(() => {
                        return of(METADATA.defaultValue)
                    }),
                    map((data) => (
                        {
                            paginatedItems: {
                                dataset: this.getMappedDataset(data.paginatedItems),
                                totalItems: 6,
                                options: {
                                    page: 0,
                                    itemsPerPage: 6,
                                },
                            },
                            totalItemsCount: data.totalItemsCount,
                        }
                    )),
                    finalize(() => {
                        this.isTicketsLoading = false;
                    })
                )
        }
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    get isUserAuthorized(): boolean {
        return this.authStorageService.isAuthorized();
    }

    get hasHistoryAccess(): boolean {
        return this.permissions.has(PERMISSIONS.SupportPageTicketHistory);
    }

    get hasAlarmsAccess(): boolean {
        return this.permissions.has(PERMISSIONS.HeaderAlarms);
    }

    public goHome(): void {
        this.router.navigate(["/home"]);
    }

    public backTo(): void {
        if (!this.settings) return;

        const backPathParts = this.settings.back.path.split("/").map(part => {
            if (part.startsWith(":")) {
                return RouterHelper.getRouteParam(part.slice(1), this.route.snapshot);
            }
            return part;
        });

        this.router.navigate(backPathParts);
    }

    public goTo(link: IQuickLink): void {
        if (link?.path?.length) {
            this.router.navigate([link.path, link.parameters || {}]);
        }
    }

    public logout(): void {
        this.isLogoutInProgress = true;

        this.authService
            .safeLogout()
            .pipe(
                takeUntil(this.destroy$),
                finalize(() => {
                    this.isLogoutInProgress = false;
                    this.cdr.detectChanges();
                })
            )
            .subscribe();
    }

    /*
    #48000 - Hide functionality until further notice
    public get showNewsSection(): boolean {
        return (
            this.route.snapshot.data.isNewsSectionShown &&
            this.news.length &&
            this.news.some(item => !item.hidden)
        );
    }

    public close(item: NewsItem): void {
        item.hidden = true;
        const hiddenNews: string[] = JSON.parse(localStorage.getItem(this.localStorageKey)) || [];
        hiddenNews.push(item.latestNewsId);
        localStorage.setItem(this.localStorageKey, JSON.stringify(hiddenNews));
    }

    private getNews() {
        this.commonLookupsApiService
            .getNews()
            .pipe(
                take(1),
                map(response => {
                    if (!response) {
                        return [];
                    }

                    const hiddenNewsIds = JSON.parse(
                        localStorage.getItem(this.localStorageKey)
                    ) as string[];

                    if (!hiddenNewsIds) {
                        return response;
                    }

                    return response.map(item => {
                        const itemToHide = hiddenNewsIds.find(
                            hiddenItemId => hiddenItemId === item.latestNewsId
                        );

                        if (!itemToHide) {
                            return item;
                        }

                        return {
                            ...item,
                            hidden: true,
                        };
                    });
                })
            )
            .subscribe(res => {
                this.news = res;
            });
    }
*/

    private getMappedDataset(value: SupportTicket[]): Ticket[] {
        return value.map((item: SupportTicket): Ticket => {
            return {
                supportCallId: item.supportCallId,
                openedDate: this.datePipe.transform(item.openDate),
                ticketNumber: `<a>${item.ticketNumber}</a>`,
                _ticketNumber: item.ticketNumber,
                assignedTo: item.openByUserFullName || "",
                summary: item.summary,
                collapsedData: TopBarShellComponent.getCollapsedData(item),
            };
        });
    }
}
