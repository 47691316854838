import { Injectable } from "@angular/core";
import { Resolve } from "@angular/router";
import { Observable } from "rxjs";
import { GeneralSettings, GeneralSettingsService } from "@core/services/general-settings.service";

@Injectable({
    providedIn: "root",
})
export class GeneralSettingsResolver implements Resolve<GeneralSettings> {
    constructor(
        private generalSettingsService: GeneralSettingsService,
    ) {}

    resolve(): Observable<GeneralSettings> {
        return this.generalSettingsService.getGeneralSettings();
    }
}
