<div
    [class.clickable]="clickable"
    [ngClass]="{
        'info-widget-classic': type === typeEnum.classic,
        'info-widget-link-based': type === typeEnum.linkBased,

        'small': small,

        'default': colorType === colorTypeEnum.default,
        'error': colorType === colorTypeEnum.error,
        'info': colorType === colorTypeEnum.info,
        'success': colorType === colorTypeEnum.success,
        'primary': colorType === colorTypeEnum.primary,
        'disable': colorType === colorTypeEnum.disable,
        'dark': colorType === colorTypeEnum.dark,
        'light-blue': colorType === colorTypeEnum.lightBlue,
        'turquoise': colorType === colorTypeEnum.turquoise
    }"
    >
    <div class="icon-section" *ngIf="type === typeEnum.classic">
        <ng-content select="[icon]"></ng-content>
    </div>
    <div class="body-section">
        <ng-content select="[body]"></ng-content>
    </div>
</div>
