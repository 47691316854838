export enum SupportCallStatuses {
    Closed = "Closed",
    Resolved = "Resolved",
    Implemented = "Implemented",
    Assigned = "Assigned",
    ReOpened = "Re-Opened",
    Disabled = "Disabled",
    InProcess = "In Process",
    Implementation = "Implementation",
    New = "New",
}

export enum TransactionTypeEnum {
    Invoice = "invoice",
    Payment = "payment",
    CreditMemo = "credit memo",
    CreditMemoLegacy = "credit memo legacy",
    Journal = "journal",
}

export const TRANSACTION_TYPE_LABELS = {
    [TransactionTypeEnum.Invoice]: "Invoice",
    [TransactionTypeEnum.Payment]: "Payment",
    [TransactionTypeEnum.CreditMemo]: "Credit Memo",
    [TransactionTypeEnum.CreditMemoLegacy]: "Credit Memo Legacy",
    [TransactionTypeEnum.Journal]: "Journal",
};

export enum TransactionStatusEnum {
    Credit = "Credit",
    Paid = "Paid",
    Partial = "Partial",
    PastDuePartial = "Past Due - Partial",
    PastDueUnpaid = "Past Due - Unpaid",
    Applied = "applied",
    Unapplied = "unapplied",
    Unpaid = "Unpaid",
    ACHPending = "ACH Pending",
    UnderReview = "Under Review"
}

export const TRANSACTION_STATUS_LABELS = {
    [TransactionStatusEnum.Credit]: "Credit",
    [TransactionStatusEnum.Paid]: "Paid",
    [TransactionStatusEnum.Partial]: "Partial",
    [TransactionStatusEnum.PastDuePartial]: "Past Due - Partial",
    [TransactionStatusEnum.PastDueUnpaid]: "Past Due - Unpaid",
    [TransactionStatusEnum.Applied]: "Applied",
    [TransactionStatusEnum.Unapplied]: "Unapplied",
    [TransactionStatusEnum.Unpaid]: "Unpaid",
    [TransactionStatusEnum.ACHPending]: "ACH Pending",
    [TransactionStatusEnum.UnderReview]: "Under Review"
};

export enum ComplianceReportStatusEnum {
    NeedsReview = "needsReview",
    Verified = "verified",
    Warning = "warning",
    Critical = "critical",
}

export enum SSLStatusEnum {
    COMPLETE = "COMPLETE",
    EXPIRED = "EXPIRED",
    PENDING = "PENDING",
    CANCELLED = "CANCELLED",
}

export enum SSLHistoryEnum {
    EmailSent = "Email Sent",
    EmailFailed = "Email Failed",
}
