import { Component } from "@angular/core";
import { GeneralSettings, GeneralSettingsService } from "@core/services/general-settings.service";
import { Observable } from "rxjs";

@Component({
    selector: "edge-portal-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
})
export class PortalFooterComponent {
    public settings$: Observable<GeneralSettings> = this.generalSettingsService.settings$;
    constructor(
        private generalSettingsService: GeneralSettingsService
    ) { }
}
