<div [formGroup]="form">
    <div class="edge-form-group">
        <!-- TODO: we can change username -->
        <div class="form-group-ctrl">
            <mat-form-field>
                <mat-label>Username</mat-label>
                <input matInput
                       readonly
                       id="ma-mu-pm-username"
                       formControlName="userName"
                       placeholder="Input Username"
                />
            </mat-form-field>
        </div>
        <div class="form-group-ctrl" *ngIf="oldPasswordRequired">
            <mat-form-field class="old-password">
                <mat-label>Old Password</mat-label>
                <input matInput
                       id="ma-mu-pm-old-password"
                       type="password"
                       placeholder="Input Old Password"
                       maxlength="100"
                       autocomplete="off"
                       formControlName="oldPassword"
                />
                <mat-hint *ngIf="showHints">The account password will not change if these fields are left empty</mat-hint>
                <mat-error>
                    <validation-error [control]="f.oldPassword"></validation-error>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
    <div class="edge-form-group">
        <div class="form-group-ctrl">
            <mat-form-field>
                <mat-label>New Password</mat-label>
                <input matInput
                       type="password"
                       id="ma-mu-pm-new-password"
                       placeholder="Input New Password"
                       maxlength="100"
                       autocomplete="off"
                       formControlName="newPassword"
                />
                <mat-error>
                    <validation-error [control]="f.newPassword"></validation-error>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group-ctrl">
            <mat-form-field>
                <mat-label>Confirm New Password</mat-label>
                <input matInput
                       id="ma-mu-pm-confirm-new-password"
                       type="password"
                       placeholder="Confirm New Password"
                       maxlength="100"
                       autocomplete="off"
                       formControlName="confirmPassword"
                />
                <mat-error>
                    <validation-error [control]="f.confirmPassword"></validation-error>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</div>
