import { Component, Input } from "@angular/core";
import { ThousandthPipe } from "../../../core/pipes/edge-thousandth.pipe";

import { METADATA } from "./progress.metadata";

// declaration
@Component({
    selector: "edge-progress",
    templateUrl: "./progress.component.html",
})
export class ProgressComponent {
    @Input() value?: number = METADATA.value;
    @Input() limit?: number = METADATA.limit;
    @Input() type?: string = METADATA.type;
    @Input() staticColor?: string = METADATA.staticColor;
    @Input() titleName?: string = METADATA.title;
    @Input() customLabel?: string;
    @Input() progressTitle?: string;

    constructor(private thousandthPipe: ThousandthPipe) {}

    get label(): string {
        return this.customLabel
            ? this.customLabel
            : METADATA.label(this.type)(
                  this.thousandthPipe.transform(this.value),
                  this.thousandthPipe.transform(this.limit)
              );
    }

    get width(): number {
        const width = Math.round((this.value * 100) / this.limit);
        return width > this.limit ? this.limit : width;
    }

    get colorClass(): string {
        if (this.type !== "PERCENTAGE" || this.staticColor) {
            return this.staticColor;
        }

        if (this.width <= 60) {
            return "progress-color-success";
        }

        if (this.width > 60 && this.width <= 80) {
            return "progress-color-warn";
        }

        return "progress-color-error";
    }
}
