import { Component, Input } from "@angular/core";

type IconSize = "small" | "normal";

@Component({
    selector: "edge-timeline-item",
    templateUrl: "./timeline.component.html",
})
export class TimelineComponent {
    @Input() time: string;
    @Input() simpleView: boolean;
    @Input() iconSize: IconSize = "normal";
}
