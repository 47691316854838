import { Component, ChangeDetectionStrategy, Input } from "@angular/core";
import { Router } from "@angular/router";
import { ServersApiService } from "@shared/services/servers.service";
import { finalize, take } from "rxjs/operators";
import { MatMenuTrigger } from "@angular/material/menu";
import { Alarm, AlarmPaginatedData } from "../../interfaces/top-bar.interface";
import { METADATA } from "../../top-bar.metadata";

@Component({
    selector: "edge-top-bar-alarms",
    templateUrl: "./top-bar-alarms.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarAlarmsComponent {
    @Input() data: AlarmPaginatedData;
    @Input() isLoading: boolean;

    public meta = METADATA;

    constructor(private router: Router, private servers: ServersApiService) {}

    public onClick(alarm: Alarm, trigger: MatMenuTrigger): void {
        const deviceNumber = alarm.assetNumber.trim();
        const productId = alarm.productId.trim();
        this.isLoading = true;
        this.servers
            .getServersConfig(deviceNumber)
            .pipe(
                take(1),
                finalize(() => {
                    this.isLoading = false;
                    trigger?.closeMenu();
                })
            )
            .subscribe(res => {
                if (res.isSAN) {
                    this.router.navigate([
                        "/managed-systems",
                        "san",
                        deviceNumber,
                    ]);
                } else if (res.isNAS) {
                    this.router.navigate([
                        "/managed-systems",
                        "nas",
                        deviceNumber,
                    ]);
                } else if (res.isFW) {
                    this.router.navigate(["/managed-systems", "firewall", productId]);
                } else if (res.isCP || res.isPVM || res.isDEDI || res.isAWS) {
                    this.router.navigate([
                        "/managed-systems",
                        "server",
                        deviceNumber,
                    ]);
                }
            });
    }

    public goToAlarms(trigger: MatMenuTrigger): void {
        this.router.navigate(["alarms"]);
        trigger?.closeMenu();
    }
}
