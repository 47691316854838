import { Component, ChangeDetectionStrategy } from "@angular/core";
import { META_DATA } from "./auth-layout-shell.metadata";

@Component({
    selector: "edge-auth-layout",
    templateUrl: "./auth-layout-shell.component.html",
    styleUrls: ["./auth-layout-shell.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthLayoutShellComponent {
    meta = META_DATA;
}
