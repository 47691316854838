export const META_DATA = {
    cultureData: [
        { currentCulture: "en-US", code: "USD", dateFormat: "MM/dd/y" },
        { currentCulture: "fr-FR", code: "EUR", dateFormat: "dd/MM/y" },
        { currentCulture: "en-FR", code: "EUR", dateFormat: "dd/MM/y" },
        { currentCulture: "en-GB", code: "GBP", dateFormat: "dd/MM/y" },
        { currentCulture: "en-CA", code: "CAD", dateFormat: "y/MM/dd" },
    ],
    defaultCultureData: {
        currentCulture: "en-US",
        code: "USD",
        formatOptions: {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        },
    },
};
