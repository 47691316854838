import { environment } from "../../../environments/environment";

const hostname = `${environment.protocol}://${environment.hostname}`;

export const webApiUrls = {
    authBase: `${hostname}/OAuth`,
    auth: `${hostname}/OAuth/api`,
    eventManagement: `${hostname}/EventsManagement/api`,
    accountManagement: `${hostname}/AccountManagement/api`,
    productManagement: `${hostname}/ProductManagement/api`,
};

