import { ActivatedRouteSnapshot, Router } from "@angular/router";

export class RouterHelper {
    static hasRouteDataValue(parameter: string, route: ActivatedRouteSnapshot): boolean {
        // eslint-disable-next-line no-prototype-builtins
        if (route.data.hasOwnProperty(parameter)) {
            return true;
        }
        if (!route.children.length) {
            return false;
        }
        return RouterHelper.hasRouteDataValue(parameter, route.firstChild);
    }

    static getRouteDataValue<T>(parameter: string, route: ActivatedRouteSnapshot): T {
        // eslint-disable-next-line no-prototype-builtins
        if (route.data.hasOwnProperty(parameter)) {
            return route.data[parameter];
        }
        if (!route.children.length) {
            return undefined;
        }
        return RouterHelper.getRouteDataValue(parameter, route.firstChild);
    }

    static getRouteParam<T>(parameter: string, route: ActivatedRouteSnapshot): T | undefined {
        // eslint-disable-next-line no-prototype-builtins
        if (route.params.hasOwnProperty(parameter)) {
            return route.params[parameter];
        }
        if (!route.children.length) {
            return undefined;
        }

        return RouterHelper.getRouteParam(parameter, route.firstChild);
    }

    static forceReloadState(router: Router): void {
        // Save initial router parameters
        const fn = router.routeReuseStrategy.shouldReuseRoute;
        const type = router.onSameUrlNavigation;
        // Set up force state reloading
        router.routeReuseStrategy.shouldReuseRoute = () => false;
        router.onSameUrlNavigation = "reload";
        // Force reload current route
        router.navigate([router.url]).finally(() => {
            // Restore saved initial settings
            router.routeReuseStrategy.shouldReuseRoute = fn;
            router.onSameUrlNavigation = type;
        });
    }
}
