/* eslint-disable guard-for-in */
import { Injectable } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";
import { IconsEnum } from "../enums/icons-names.enum";

@Injectable()
export class IconsRegisterService {
    private iconsEnum = IconsEnum;

    constructor(private matIconRegistry: MatIconRegistry, private domSanitizer: DomSanitizer) {}

    public registerIcons(): void {
        this.loadIcons(this.iconsEnum, "assets/icons");
    }

    private loadIcons(iconObj: Object, iconUrl: string): void {
        for (const key in iconObj) {
            const iconSvgName = iconObj[key];
            this.matIconRegistry.addSvgIcon(
                key,
                this.domSanitizer.bypassSecurityTrustResourceUrl(`${iconUrl}/${iconSvgName}.svg`)
            );
        }
    }
}
