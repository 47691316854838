import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { IAuthData } from "@core/interfaces/auth-data.interface";
import { LocalStorageService } from "@core/services/local-storage.service";

const STORAGE_KEY = "edge-auth";

@Injectable({
    providedIn: "root",
})
export class AuthStorageService {
    public auth$: Observable<IAuthData>;
    private authData$: BehaviorSubject<IAuthData> = new BehaviorSubject(null);

    constructor(private localStorageService: LocalStorageService) {
        this.auth$ = this.authData$.asObservable();
        this.initAuthData();
    }

    /**
     * Init data from local storage
     */
    public initAuthData(): void {
        const storageData = this.localStorageService.getItem(STORAGE_KEY);
        if (storageData) {
            this.authData$.next(storageData as IAuthData);
        }
    }

    public updateAuthData(authData: Partial<IAuthData>): void {
        const updatedData = {
            ...this.authData$.getValue(),
            ...authData,
        };
        this.authData$.next(updatedData);
        this.localStorageService.setItem(STORAGE_KEY, updatedData);
    }

    public clearAuthData(): void {
        this.authData$.next(null);
        this.localStorageService.removeItem(STORAGE_KEY);
    }

    public getAuth(): IAuthData {
        return this.authData$.getValue();
    }

    public isBackdoorUser(): boolean {
        return this.authData$.getValue()?.isBackdoorUser;
    }

    /**
     * Duo feature
     */
    public isDuoUser(): boolean {
        return this.authData$.getValue()?.duoEnabled;
    }

    public authorizeDuo(): void {
        this.updateAuthData({
            duoAuthorized: true,
        });
    }

    /**
     * Announcements feature
     */
    public getAnnouncements(): boolean {
        return this.authData$.getValue()?.showAnnouncements;
    }

    public updateAnnouncements(state: boolean): void {
        this.updateAuthData({
            showAnnouncements: state,
        });
    }

    public getSessionId(): string {
        return this.authData$.getValue()?.sessionId;
    }

    public isAuthorized$(): Observable<boolean> {
        return this.auth$.pipe(map(() => this.isAuthorized()));
    }

    public isAuthorized(): boolean {
        const auth = this.getAuth();
        return this.isDuoUser()
            ? !!auth?.access_token && auth?.duoAuthorized && !auth?.isPasswordExpired
            : !!auth?.access_token && !auth?.isPasswordExpired;
    }
}
