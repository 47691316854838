import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: "edgeDash",
})
export class EdgeDashPipe implements PipeTransform {
    transform(value: string): string {
        return value?.trim() ? value : "-";
    }
}
