import { Injectable } from "@angular/core";
import { GlobalCacheKeys } from "@core/enums/global-cache-keys.enum";

@Injectable({
    providedIn: "root",
})
export class GlobalCacheService {
    private static cache: { [key in GlobalCacheKeys]?: any } = {};

    public static get<T>(key: GlobalCacheKeys): T {
        return this.cache[key];
    }

    public static getCopy<T>(key: GlobalCacheKeys): T {
        return this.cache[key] ? JSON.parse(JSON.stringify(this.cache[key])) : undefined;
    }

    public static set<T>(key: GlobalCacheKeys, value: T): void {
        this.cache[key] = value;
    }

    public static clear(key: GlobalCacheKeys | GlobalCacheKeys[]): void {
        if (Array.isArray(key)) {
            key.forEach(value => delete this.cache[value]);
        } else {
            delete this.cache[key];
        }
    }

    public static clearAll(): void {
        this.cache = {};
    }
}
