import { Pipe, PipeTransform } from "@angular/core";
import { DatePipe } from "@angular/common";

import { META_DATA } from "@shared/metadata/organization.metadata";

@Pipe({
    name: "edgeDateTime",
})
export class EdgeDateTimePipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(value: string | number | Date, culture?: string): string {
        const dateFormat = culture
            ? META_DATA.cultureData.find(({ currentCulture }) => currentCulture === culture)
                  .dateFormat
            : META_DATA.cultureData.find(({ currentCulture }) => currentCulture === "en-US")
                  .dateFormat;

        return `${this.datePipe.transform(value, dateFormat)} ${this.datePipe.transform(
            value,
            "hh:mm a"
        )}`;
    }
}
