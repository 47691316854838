import { Injectable } from "@angular/core";
import { DEFAULT_INTERRUPTSOURCES, Idle } from "@ng-idle/core";
import { Keepalive } from "@ng-idle/keepalive";
import { MatDialogRef } from "@angular/material/dialog";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { TitleService } from "@core/services/title.service";
import { LoggerService } from "@core/services/logger.service";
import { IdleDialogComponent } from "@shared/components/idle-dialog/idle-dialog.component";
import { convertSecondToMMSS } from "@shared/utils/second-to-mmss.utils";
import { DialogService } from "@shared/components/dialog/dialog.service";
import { environment } from "../../../environments/environment";

@Injectable({
    providedIn: "root",
})
export class IdleService {
    timeOutCountdown$: Observable<number>;
    sessionExpired$: Observable<void>;
    private timeOutCountdownSbj = new BehaviorSubject(environment.idle.warningTime);
    private sessionExpiredSbj = new Subject<void>();
    private keepAliveInterval = environment.idle.sessionTime / 2.5; // Ping interval
    private modalRef: MatDialogRef<IdleDialogComponent>;

    constructor(
        private dialog: DialogService,
        private idle: Idle,
        private keepalive: Keepalive,
        private titleService: TitleService,
        private loggerService: LoggerService
    ) {
        this.timeOutCountdown$ = this.timeOutCountdownSbj.asObservable();
        this.sessionExpired$ = this.sessionExpiredSbj.asObservable();
    }

    public initIdleConfig(): void {
        this.loggerService.log("[IDLE] Init Idle");

        // how long can they be inactive before considered idle, in seconds
        this.idle.setIdle(environment.idle.sessionTime);

        // how long can they be idle before considered timed out, in seconds
        this.idle.setTimeout(environment.idle.warningTime);

        // provide sources that will "interrupt" aka provide events indicating the user is active
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        // Idle time is finish, show modal
        this.idle.onIdleStart.subscribe(() => {
            this.loggerService.log("[IDLE] Show idle dialog");

            this.idle.clearInterrupts();

            this.modalRef = this.dialog.custom(IdleDialogComponent, {
                data: {
                    timeLeft: environment.idle.warningTime,
                    countdown$: this.timeOutCountdown$,
                    onContinueSession: () => {
                        this.onContinueSession();
                    },
                },
            });
        });

        // do something when the user has timed out
        this.idle.onTimeout.subscribe(() => {
            this.loggerService.log("[IDLE] No activity. Logout.");
            this.onTimeOutSession();
        });

        // do something as the timeout countdown does its thing
        this.idle.onTimeoutWarning.subscribe(seconds => {
            this.timeOutCountdownSbj.next(seconds);
            this.titleService.setTitle(
                `${convertSecondToMMSS(seconds)} until your session times out!`
            );
        });

        this.keepalive.interval(this.keepAliveInterval); // will ping at this interval while not idle, in seconds
    }

    public stopSession(): void {
        this.loggerService.log("[IDLE] Stop Idle");
        this.idle.stop();
    }

    public startSession(): void {
        this.loggerService.log("[IDLE] Start Idle");
        this.idle.watch();
    }

    private onTimeOutSession() {
        this.modalRef?.close();
        this.stopSession();
        this.sessionExpiredSbj.next();
    }

    private onContinueSession() {
        this.loggerService.log("[IDLE] Continue session");

        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
        this.titleService.setDefaultTitle();
        this.idle.watch();
    }
}
