<div class="top-bar-select" *ngIf="!isOnlyOrganization">
    <span class="select-name">Organization/BAN:</span>

    <mat-spinner
        *ngIf="isConfigLoading"
        [diameter]="20"
        [strokeWidth]="2"
    ></mat-spinner>

    <mat-form-field [matTooltip]="selectedOrgName$ | async"
                    matTooltipPosition="left"
    >
        <mat-select
            panelClass="top-bar-select-panel"
            [formControl]="currentOrgControl"
            id="top-org-ban-ddl"
        >
            <mat-option *ngFor="let organization of all"
                        [class.group]="organization.isGroup"
                        [value]="organization"
                        [matTooltip]="organization.name"
                        matTooltipPosition="left"
            >{{organization.name}}</mat-option>
        </mat-select>
    </mat-form-field>
</div>
