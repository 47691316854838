import { Injectable } from "@angular/core";
import dayjs, { Dayjs } from "dayjs";
import { BehaviorSubject, Observable } from "rxjs";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { TimeframeSettings } from "@core/interfaces/settings.interface";
import { TimeframeInterval } from "@core/interfaces/timeframe.interface";
import { TimeframeSettingsOption } from "@core/enums/timeframe.enum";
import { ActivatedRoute } from "@angular/router";

interface TimeframeIntervals {
    [key: number]: () => TimeframeInterval;
}

const DEFAULT_TIMEFRAME_SETTINGS: TimeframeSettings = {
    settings: TimeframeSettingsOption.Last1hour,
    startDate: null,
    endDate: null,
    isDefault: true,
};

@Injectable({
    providedIn: "root",
})
export class TimeframeService {
    settings$: Observable<TimeframeSettings>;

    private readonly frameIntervals: TimeframeIntervals = {};
    private settingsData$ = new BehaviorSubject<TimeframeSettings>(DEFAULT_TIMEFRAME_SETTINGS);

    constructor(
        private settingStorageService: SettingStorageService,
        private route: ActivatedRoute
    ) {
        this.settings$ = this.settingsData$.asObservable();
        this.initFrameIntervals();
        this.initDefaultsCustomInterval();
    }

    getFrameInterval(timeframe?: TimeframeSettingsOption): TimeframeInterval {
        return this.frameIntervals[timeframe || this.getSettings().settings]();
    }

    getSettings(): TimeframeSettings {
        return this.settingStorageService.getSetting()?.timeframe;
    }

    setSettings(
        setting: TimeframeSettingsOption,
        startDate: Date,
        endDate: Date,
        isDefault: boolean
    ): void {
        this.updateSettings(setting, startDate, endDate, isDefault);
        this.settingsData$.next(this.getSettings());
    }

    private initDefaultsCustomInterval() {
        const from = this.route.snapshot.queryParamMap.get("from");
        const to = this.route.snapshot.queryParamMap.get("to");
        const widget = this.route.snapshot.queryParamMap.get("widget");

        if (!widget && from && to) {
            this.setSettings(TimeframeSettingsOption.Custom, new Date(from), new Date(to), true);
        }
    }

    private today() {
        return this.getTimeInterval();
    }

    private yesterday(): TimeframeInterval {
        const startDate = dayjs().subtract(1, "day");
        const endDate = dayjs().subtract(1, "day");
        return this.getTimeInterval(startDate, endDate);
    }

    private last7Days(): TimeframeInterval {
        const startDate = dayjs().subtract(6, "day");
        return this.getTimeInterval(startDate);
    }

    private last30days(): TimeframeInterval {
        const startDate = dayjs().subtract(29, "day");
        return this.getTimeInterval(startDate);
    }

    private thisMonth(): TimeframeInterval {
        return this.getTimeInterval(dayjs().startOf("month"));
    }

    private lastMonth(): TimeframeInterval {
        const startDate = dayjs().subtract(1, "month").startOf("month");
        const endDate = dayjs().subtract(1, "month").endOf("month");
        return this.getTimeInterval(startDate, endDate);
    }

    private lastHourPeriod(numberOfHours: number): TimeframeInterval {
        return {
            startDate: dayjs().subtract(numberOfHours, "hours").toDate(),
            endDate: dayjs().toDate(),
        };
    }

    private lastMinutePeriod(numberOfMinutes: number): TimeframeInterval {
        const startDate = dayjs().subtract(numberOfMinutes, "minutes");
        const endDate = dayjs();
        return {
            startDate: startDate.toDate(),
            endDate: endDate.toDate(),
        };
    }

    private thisWeek(): TimeframeInterval {
        const startDate = dayjs().startOf("week");
        return this.getTimeInterval(startDate);
    }

    private lastWeek(): TimeframeInterval {
        const startDate = dayjs().subtract(1, "week").startOf("week");
        const endDate = dayjs().subtract(1, "week").endOf("week");
        return this.getTimeInterval(startDate, endDate);
    }

    private thisYear(): TimeframeInterval {
        const startDate = dayjs().startOf("year");
        return this.getTimeInterval(startDate);
    }

    private lastYear(): TimeframeInterval {
        const startDate = dayjs().subtract(1, "year").startOf("year");
        const endDate = dayjs().subtract(1, "year").endOf("year");
        return this.getTimeInterval(startDate, endDate);
    }

    private thisQuarter(): TimeframeInterval {
        dayjs().startOf("quarter");
        const startDate = dayjs().startOf("quarter");
        return this.getTimeInterval(startDate);
    }

    private lastQuarter(): TimeframeInterval {
        const startDate = dayjs().subtract(1, "quarter").startOf("quarter");
        const endDate = dayjs().subtract(1, "quarter").endOf("quarter");
        return this.getTimeInterval(startDate, endDate);
    }

    private customFrameInterval(): TimeframeInterval {
        const settings = this.getSettings();
        return this.getTimeInterval(dayjs(settings.startDate), dayjs(settings.endDate));
    }

    private getTimeInterval(startDate?: Dayjs, endDate?: Dayjs): TimeframeInterval {
        startDate = startDate || dayjs();
        endDate = endDate || dayjs();
        return {
            startDate: startDate.startOf("day").toDate(),
            endDate: endDate.endOf("day").toDate(),
        };
    }

    private updateSettings(settings, startDate, endDate, isDefault) {
        this.settingStorageService.updateSetting({
            timeframe: {
                settings,
                startDate,
                endDate,
                isDefault,
            },
        });
    }

    private initFrameIntervals() {
        this.frameIntervals[TimeframeSettingsOption.Today] = this.today.bind(this);
        this.frameIntervals[TimeframeSettingsOption.Yesterday] = this.yesterday.bind(this);
        this.frameIntervals[TimeframeSettingsOption.Last7Days] = this.last7Days.bind(this);
        this.frameIntervals[TimeframeSettingsOption.Last30Days] = this.last30days.bind(this);
        this.frameIntervals[TimeframeSettingsOption.ThisMonth] = this.thisMonth.bind(this);
        this.frameIntervals[TimeframeSettingsOption.LastMonth] = this.lastMonth.bind(this);
        this.frameIntervals[TimeframeSettingsOption.Last30Minutes] = this.lastMinutePeriod.bind(
            this,
            30
        );
        this.frameIntervals[TimeframeSettingsOption.Last1hour] = this.lastHourPeriod.bind(this, 1);
        this.frameIntervals[TimeframeSettingsOption.Last6hours] = this.lastHourPeriod.bind(this, 6);
        this.frameIntervals[TimeframeSettingsOption.Last12hours] = this.lastHourPeriod.bind(
            this,
            12
        );
        this.frameIntervals[TimeframeSettingsOption.Last24hours] = this.lastHourPeriod.bind(
            this,
            24
        );
        this.frameIntervals[TimeframeSettingsOption.Last48hours] = this.lastHourPeriod.bind(
            this,
            48
        );
        this.frameIntervals[TimeframeSettingsOption.LastWeek] = this.lastWeek.bind(this);
        this.frameIntervals[TimeframeSettingsOption.ThisWeek] = this.thisWeek.bind(this);
        this.frameIntervals[TimeframeSettingsOption.LastYear] = this.lastYear.bind(this);
        this.frameIntervals[TimeframeSettingsOption.ThisYear] = this.thisYear.bind(this);
        this.frameIntervals[TimeframeSettingsOption.LastQuarter] = this.lastQuarter.bind(this);
        this.frameIntervals[TimeframeSettingsOption.ThisQuarter] = this.thisQuarter.bind(this);
        this.frameIntervals[TimeframeSettingsOption.Custom] = this.customFrameInterval.bind(this);
    }
}
