import { EdgeBytesConverterPipe } from "@core/pipes/edge-bytes-converter.pipe";
import { EdgeCCMaskPipe } from "@core/pipes/edge-cc-mask.pipe";
import { EdgeCelsiusPipe } from "@core/pipes/edge-celsius.pipe";
import { EdgeCurrencyPipe } from "@core/pipes/edge-currency.pipe";
import { EdgeDashPipe } from "@core/pipes/edge-dash.pipe";
import { EdgeDateTimePipe } from "@core/pipes/edge-date-time.pipe";
import { EdgeDatePipe } from "@core/pipes/edge-date.pipe";
import { EdgeHeightPipe } from "@core/pipes/edge-height.pipe";
import { EdgeHumanizeSecondsPipe } from "@core/pipes/edge-humanize-seconds.pipe";
import { EdgeNumbersNamePipe } from "@core/pipes/edge-number-name.pipe";
import { EdgeNumberPipe } from "@core/pipes/edge-number.pipe";
import { ThousandthPipe } from "@core/pipes/edge-thousandth.pipe";
import { EdgeTimePipe } from "@core/pipes/edge-time.pipe";
import { JoinPipe } from "@core/pipes/join.pipe";
import { NotNullPipe } from "@core/pipes/not-null.pipe";
import { SafeHtmlPipe } from "@core/pipes/safe-html.pipe";
import { UtcToLocalPipe } from "@core/pipes/utc-to-local.pipe";

export const CORE_PIPES = [
    EdgeBytesConverterPipe,
    EdgeCCMaskPipe,
    EdgeCelsiusPipe,
    EdgeCurrencyPipe,
    EdgeDatePipe,
    EdgeDateTimePipe,
    EdgeHeightPipe,
    EdgeHumanizeSecondsPipe,
    EdgeNumberPipe,
    EdgeNumbersNamePipe,
    EdgeTimePipe,
    JoinPipe,
    NotNullPipe,
    SafeHtmlPipe,
    ThousandthPipe,
    UtcToLocalPipe,
    EdgeDashPipe,
];
