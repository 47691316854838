import { Injectable } from "@angular/core";
import { webApiUrls } from "@core/constants/url.constant";
import {
    IPermissionsDto,
    IUserParams,
    IUserProfileDto,
} from "@core/interfaces/dto/user-profile-dto.interface";
import { IOrganizationPermission } from "@core/interfaces/organization-permission.interface";
import { HttpClient } from "@angular/common/http";
import { OrganizationStorageService } from "@core/services/storages/organization-storage.service";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { UserStorageService } from "@core/services/storages/user-storage.service";
import { Observable, of } from "rxjs";
import { map, share, tap } from "rxjs/operators";
import { MonitoringEM7Service } from "./monitoring-em7.service";

@Injectable({
    providedIn: "root",
})
export class UserApiService {
    public getAndSaveUserProfile$ = this.getAndSaveUserProfile();

    constructor(
        private http: HttpClient,
        private settingStorageService: SettingStorageService,
        private userStorageService: UserStorageService,
        private organizationStorageService: OrganizationStorageService,
        private monitoringEM7Service: MonitoringEM7Service,
    ) {}

    public getAndSaveUserProfile(params?: IUserParams): Observable<IUserProfileDto> {
        return this.getUserProfile(params).pipe(
            tap(data => {
                this.userStorageService.updateUserData({
                    fileToken: data.fileToken,
                    monitoringLink: data.monitoringLink,
                    user: data.user,
                    userName: this.settingStorageService.getUserName(),
                });

                this.monitoringEM7Service.monitoringLink = data.monitoringLink;

                // organization
                const permissions = this.mapPermissions(data.permissions);
                this.organizationStorageService.setData(data.organizations);
                this.organizationStorageService.setPermissions(permissions);
                this.organizationStorageService.setFlags(data.organizationConfig);
            }),
            share()
        );
    }

    checkUserProfile(): Observable<boolean> {
        return this.userStorageService.getUser()
            ? of(true)
            : this.getAndSaveUserProfile$.pipe(map(data => !!data));
    }

    private getUserProfile(params?: IUserParams): Observable<IUserProfileDto> {
        return this.http.get<IUserProfileDto>(`${webApiUrls.auth}/auth/userprofile/`, { params: { ...params } });
    }

    private mapPermissions(permissions: IPermissionsDto[]): IOrganizationPermission[] {
        return permissions.map(value => {
            return {
                id: value.permissionId,
                name: value.permission,
                permission: value.action,
            };
        });
    }
}
