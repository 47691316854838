import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from "@angular/common/http";
import { Observable } from "rxjs";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {
    constructor(
        private authStorageService: AuthStorageService
    ) {}

    intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (
            request.headers.has("skip-token")
            ||
            request.url.startsWith("assets/icons")
        ) {
            return next.handle(request);
        }

        const token = this.authStorageService.getAuth()?.access_token;

        const newReq = request.clone({
            setHeaders: {
                Authorization: `Bearer ${token}`,
            },
        });

        return next.handle(token ? newReq : request);
    }
}
