import { Component, Input } from "@angular/core";
import { ErrorType } from "@shared/interfaces/no-data.interface";

// declaration

const Message = {
    [ErrorType.Default]: "No data to display",
    [ErrorType.Error]: "We’re sorry, the service is temporarily unavailable.",
};
@Component({
    selector: "edge-no-data",
    template: `<div class="edge-no-data-container">
        <mat-icon svgIcon="warning_outlined"></mat-icon>
        <span>{{ text || message[typeError] || defaultText }}</span>
    </div>`,
})
export class NoDataComponent {
    @Input() text: string;
    @Input() typeError: ErrorType;
    public message = Message;
    public defaultText = Message.default;
}
