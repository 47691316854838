import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, catchError, filter, of, tap } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { webApiUrls } from "@core/constants/url.constant";
import { AuthStorageService } from "./storages/auth-storage.service";

export interface GeneralSettings {
    versionNumber: string;
    releaseNotesUrl: string;
    trustUrl: string;
}

@Injectable({
    providedIn: "root",
})
export class GeneralSettingsService {
    private settings = new BehaviorSubject<GeneralSettings | null>(null);
    readonly settings$ = this.settings
        .asObservable()
        .pipe(filter(() => this.authStorageService.isAuthorized()));

    constructor(private http: HttpClient, private authStorageService: AuthStorageService) {}

    public getGeneralSettings(): Observable<GeneralSettings> {
        if (!this.authStorageService.isAuthorized()) return of(null);

        const path = `${webApiUrls.eventManagement}/GeneralSettings`;
        return this.http.get<GeneralSettings>(path).pipe(
            catchError(() => {
                return of(null);
            }),
            tap(data => this.settings.next(data))
        );
    }
}
