import { Injectable } from "@angular/core";
import { NotificationService } from "./notification.service";
import { ExportFileTypes } from "@shared/interfaces/api.interface";

@Injectable({
    providedIn: "root"
})
export class DownloadService {
    constructor(private notificationService: NotificationService) { }

    /**
     *  @param fileType ExportFileTypes or file extension (ex. ".png")
     */
    public downloadFile(
        file: Blob,
        fileName: string,
        fileType: ExportFileTypes | string = null
    ): void {
        if (!file || !file.size) {
            this.notificationService.error("The file is empty.");
            return;
        }

        // Add fileType to fileName
        if (fileType) {
            const mappedType: string = this.getFileExtension(fileType as ExportFileTypes);
            fileName += mappedType || (fileType as string);
        }

        const link = document.createElement("a");
        link.download = fileName;
        link.href = URL.createObjectURL(file);
        link.click();
        URL.revokeObjectURL(link.href);
    }

    private getFileExtension(fileType: ExportFileTypes): string {
        switch (fileType) {
            case ExportFileTypes.PDF:
                return ".pdf"; // TODO: exportDCAccessReportList method using not xlsx event if it chosen
            case ExportFileTypes.XLS:
                return ".xlsx";
            case ExportFileTypes.DOCX:
                return ".docx";
            case ExportFileTypes.DOC:
                return ".doc";
            case ExportFileTypes.XLSX:
                return ".xlsx";
            case ExportFileTypes.PPTX:
                return ".pptx";
            case ExportFileTypes.PPT:
                return ".ppt";
            case ExportFileTypes.CSV:
                return ".csv";
            case ExportFileTypes.NESSUS:
                return ".nessus";
            default:
                return "";
        }
    }
}
