import { Component } from "@angular/core";

@Component({
    selector: "edge-copyright",
    templateUrl: "./copyright.component.html",
})
export class CopyrightComponent {
    currentDate: Date;

    constructor() {
        this.currentDate = new Date();
    }
}
