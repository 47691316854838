import { Injectable } from "@angular/core";
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { AuthApiService } from "@core/services/auth-api.service";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { MicroAgent } from "@newrelic/browser-agent/loaders/micro-agent"
import { environment } from "src/environments/environment";
export interface IHttpError {
    status: number;
    message: string;
    errorContent: { [key: string]: any };
}

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {

    private microAgent: MicroAgent;
    private isNewRelicEnabled: boolean = environment?.newRelic?.isEnabled;

    constructor(
        private authApiService: AuthApiService,
        private authStorageService: AuthStorageService
    ) {
        // Use MicroAgent for sending HTTP errors to a New Relic.
        this.microAgent = this.isNewRelicEnabled ? new MicroAgent(environment.newRelic.options) : null
    }

    intercept(
        request: HttpRequest<unknown>,
        next: HttpHandler
    ): Observable<HttpEvent<unknown>> {
        return next
            .handle(request)
            .pipe(
                catchError((err: HttpErrorResponse | ErrorEvent | any) => {

                    if (err instanceof HttpErrorResponse) {
                        if (
                            err.status === 401
                            ||
                            err.status === 403
                        ) {
                            // Redirects only in case 401 is for login
                            !this.authStorageService.isAuthorized() && this.handleUnauthorized();
                        }
                    }

                    const errorObj: IHttpError = this.handleErrorMessage(err);

                    if (this.microAgent) {
                        this.microAgent.noticeError(err, errorObj);
                    }

                    return throwError(errorObj);
                })
            );
    }

    private handleUnauthorized() {
        this.authApiService.clearStoragesAndNavigate();
    }

    private handleErrorMessage(err): IHttpError {
        let message: string;
        let status: number;

        if (err.error instanceof ErrorEvent) {
            message = `An error occurred: ${err.error.message}`;
            status = err.error.status;
        } else if (err.error && err.error.message) {
            message = `${err.error.message}`;
            status = err.status;
        } else {
            message = `Backend returned code ${err.status}: ${err.message}`;
            status = err.status;
        }

        // eslint-disable-next-line no-console
        console.error(message);

        return {
            message,
            status,
            errorContent: err.error || null,
        };
    }
}
