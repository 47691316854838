import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";
import { IUserDto } from "@core/interfaces/dto/user-profile-dto.interface";
import { webApiUrls } from "@core/constants/url.constant";
import * as uuid from "uuid";

export interface IUser {
    fileToken: string;
    monitoringLink: string;
    user: IUserDto;
    userName: string;
    userId: string;
}

@Injectable({
    providedIn: "root",
})
export class UserStorageService {
    urlSalt: string;
    user$: Observable<IUser>;
    private userData$ = new BehaviorSubject<IUser>(null);

    constructor() {
        this.user$ = this.userData$.asObservable();
    }

    /**
     * User data
     */
    updateUserData(userData: Partial<IUser>): void {
        this.userData$.next({
            ...this.getUser(),
            ...userData,
        });
    }

    getUser(): IUser {
        return this.userData$.getValue();
    }

    getUserName(): string {
        return this.getUser().userName;
    }

    getUserFullName(): string {
        if (!this.getUser()?.user) return "";
        return `${this.getUser().user.firstName} ${this.getUser().user.lastName}`;
    }

    selectUser(): Observable<IUser> {
        return this.user$;
    }

    clearUserData(): void {
        this.userData$.next(null);
    }

    /**
     * File Token
     */
    getFileToken(): string {
        return this.userData$.getValue()?.fileToken || "";
    }

    setFileToken(fileToken: string): void {
        this.updateUserData({
            fileToken,
        });
        this.updatePersonImage();
    }

    /**
     * User Image
     */
    get personImageUrl(): string {
        let token = this.getFileToken();
        if (token) {
            token = encodeURIComponent(token);
            return `${webApiUrls.accountManagement}/UserPhoto?token=${token}&urlSalt=${this.urlSalt}`;
        }
        return null;
    }

    updatePersonImage(): void {
        this.urlSalt = uuid.v4();
    }
}
