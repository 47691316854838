import { SortDirection } from "@angular/material/sort";

export interface PaginatedData<T> {
    paginatedItems: T[];
    totalItemsCount: number;
}

export interface SortingOptions {
    orderProperty: string;
    orderDirection: SortDirection;
}

export interface PagingOptions {
    page: number;
    itemsPerPage: number;
}

export enum ExportFileTypes {
    PDF = 1,
    XLS = 2,
    CSV = 3,
    DOCX = 4,
    DOC = 5,
    XLSX = 6,
    PPTX = 7,
    PPT = 8,
    NESSUS = 9,
}
