import {
    IDuoUserInputDto,
    IDuoUserResponseInputDto,
    INewDuoUserInputDto,
} from "@core/interfaces/dto/duo-user-dto";

export class AuthMapService {
    // Get DUO user info
    static getMappedDuoUser(data: IDuoUserInputDto): IDuoUserResponseInputDto | null {
        let result = null;
        if (data?.response?.length) {
            // eslint-disable-next-line prefer-destructuring
            result = data.response[0];
        }
        return result;
    }

    // Map newly created DUO user info
    static getMappedNewDuoUser(data: INewDuoUserInputDto): IDuoUserResponseInputDto | null {
        return data?.response || null;
    }

    // Map standard DUO response
    // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
    static getMappedResponse(data: any): any {
        return data?.response || null;
    }
}
