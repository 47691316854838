import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { webApiUrls } from "@core/constants/url.constant";
import { map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import {
    IDuoUserInputDto,
    IDuoUserPhoneInputDto,
    IDuoUserResponseInputDto,
    IPhone2UserOutputDto,
} from "@core/interfaces/dto/duo-user-dto";
import { AuthMapService } from "@core/services/auth-map.service";

const duoDeviceTypes = {
    mobile: "Mobile",
    tablet: "Tablet",
};

@Injectable({
    providedIn: "root",
})
export class AuthDuoService {
    constructor(private http: HttpClient) {}

    /**
     *  HTTP GET Duo User. With token.
     */
    getDuoUser(userName: string): Observable<IDuoUserResponseInputDto> {
        return this.http
            .get<IDuoUserInputDto>(`${webApiUrls.auth}/DuoUser`, {
                params: { userName },
            })
            .pipe(
                map(data => {
                    return AuthMapService.getMappedDuoUser(data);
                })
            );
    }

    /**
     *  HTTP GET Masked Devices
     */
    getMaskedDevices(): Observable<IDuoUserPhoneInputDto[]> {
        return this.http
            .get<IDuoUserPhoneInputDto[]>(`${webApiUrls.auth}/DuoDevices/MaskedPhones/`, { observe: "response" })
            .pipe(map(data => this.duoDevicesNormalize(data.body)));
    }

    duoDevicesNormalize(devices: IDuoUserPhoneInputDto[]): IDuoUserPhoneInputDto[] {
        let i = 1;
        return devices.map(device => {
            if (!device.number) {
                if (device.type === duoDeviceTypes.tablet) {
                    device.number = device.name || `Tablet ${i++}`;
                } else if (device.type === duoDeviceTypes.mobile) {
                    device.number = device.name || `Mobile ${i++}`;
                }
            }
            return device;
        });
    }

    /**
     *  HTTP POST Create Duo User on first step wizard
     */
    createDuoUser(userName: string): Observable<any> {
        return this.http.post<any>(`${webApiUrls.auth}/DuoUser`, { username: userName }).pipe(
            // TODO: Replace response in string by JSON on BE side!
            // TODO: This solution is temporary and to be removed
            map(data => AuthMapService.getMappedNewDuoUser(JSON.parse(data)))
        );
    }

    /**
     *  HTTP POST Duo User. Wizard STEP #5
     */
    associatePhoneWithDuoUser(data: IPhone2UserOutputDto): Observable<any> {
        return this.http
            .post(`${webApiUrls.auth}/DuoUser/Devices`, data)
            .pipe(map(response => AuthMapService.getMappedResponse(response)));
    }

    /**
     *  Check if user activated duo
     */
    userHasActivatedPhoneCheck(userName: string): Observable<boolean> {
        return this.getDuoUser(userName).pipe(
            map(user => {
                return Boolean(user?.phones && user.phones.some(phone => phone.activated));
            })
        );
    }
}
