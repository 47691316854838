import {
    AfterViewInit,
    Component,
    ContentChildren,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    QueryList,
    SimpleChanges,
    TemplateRef,
    ViewChild,
} from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { animate, state, style, transition, trigger } from "@angular/animations";

import { Observable } from "rxjs";

import { MatSort, SortDirection } from "@angular/material/sort";

import { filter, map } from "rxjs/operators";
import { SeverityList } from "@core/directives/severity.directive";
import { EXPAND_TABLE_CONFIG } from "./table-expandable.metadata";

import { TableExpandableCellTemplateComponent } from "./table-expandable-cell-template.component";
import { PaginatorComponent } from "../../modules/controls/paginator/paginator.component";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";

export interface ExpandableData<T> {
    element: T;
    expanded: boolean;
}

export interface ExpandableAllData<T> {
    elements: ExpandableData<T>[],
    expanded: boolean,
}

export interface TableData<T> {
    dataset: T[];
    totalItems: number;
    options: TableOptions;
    error?: boolean;
}

export interface TableDefaultOptions {
    filters?: TableFilter;
    sort?: TableSorting;
    itemsPerPage?: number;
    page?: number;
}

export interface TableOptions extends TablePaging {
    filters?: TableFilter;
    sort?: TableSorting;
}

export interface TableFilter {
    [key: string]: any;
}

export interface TablePaging {
    page: number;
    itemsPerPage: number;
}

export interface TableSorting {
    property: string;
    direction: SortDirection;
}

export interface TableColumnConfig {
    value: string;
    sortValue?: string;
    title: string;
    horizontalAlign?: AlignOptions;
    verticalAlign?: AlignOptions;
}

export enum AlignOptions {
    Start = "start",
    Center = "center",
    End = "end",
}

interface AdditionalProperties {
    [key: string]: any;
    severity?: number;
    severityLabel?: string;
    expandedByDefault?: boolean;
}

interface ExtendedColumnConfig extends TableColumnConfig {
    cellCssClasses?: { [cssClass: string]: boolean };
    headerCssClasses?: { [cssClass: string]: boolean };
}

@UntilDestroy()
@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: "table-expandable",
    templateUrl: "./table-expandable.component.html",
    styleUrls: ["./table-expandable.component.scss"],
    animations: [
        trigger("detailExpand", [
            state("collapsed, void", style({ height: "0px" })),
            state("expanded", style({ height: "*" })),
            transition("expanded <=> collapsed", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
            transition("expanded <=> void", animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")),
        ]),
    ],
})
export class TableExpandableComponent<T extends AdditionalProperties>
    implements OnInit, AfterViewInit, OnChanges
{
    @Input() displayedColumns: TableColumnConfig[];
    @Input() expandedDetailsTemplate: TemplateRef<any>;
    @Input() tableIsServerSide = false;
    @Input() tableData$: Observable<TableData<T>>;
    @Input() withCollapse = true;
    @Input() emptyMessage: string;
    @Input() error: boolean | string = false ;
    @Input() canExpandCheck: (data: T) => boolean;
    @Input() customSorting;
    @Input() sortDisabled = false;
    @Input() customSeverityList: SeverityList;
    @Input() defaultOptions?: TableDefaultOptions;

    @Input() withPageSize?: boolean = false;
    // Using withPageSize as a flag for possibility to chang count of items on a page
    // and adding a scroll to a table


    @Output() pageChange = new EventEmitter<number | TablePaging>();
    @Output() sortChange = new EventEmitter<TableSorting>();
    @Output() expandChange = new EventEmitter<ExpandableData<T>>();
    @Output() expandAllChange = new EventEmitter<ExpandableAllData<T>>();
    @Output() rowClick = new EventEmitter<T>();

    @ViewChild(PaginatorComponent)
    public paginatorComponent: PaginatorComponent;

    @ViewChild(MatSort) public sort: MatSort;

    @ContentChildren(TableExpandableCellTemplateComponent)
    cellTemplates: QueryList<TableExpandableCellTemplateComponent>;

    public allExpanded = false;
    public sortActive: string;
    public sortDirection: SortDirection;
    public dataSource: MatTableDataSource<ExpandableData<T>>;
    public columns: ExtendedColumnConfig[];
    public columnNames: string[];
    public tableCells = {};
    public totalItems: number;
    public options: TableOptions;
    public itemsPerPage = 6;
    public errorMessage = "Sorry, something went wrong!";

    ngOnInit(): void {
        this.setDefaultOptions();
        if (this.tableData$) {
            this.tableData$
                .pipe(
                    untilDestroyed(this),
                    filter(data => !!data?.options),
                    map(data => data.options.sort)
                )
                .subscribe(sortOptions => {
                    this.sortActive = sortOptions?.property || null;
                    this.sortDirection = sortOptions?.direction || null;
                });
        }
        this.setFullListOfColumns();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.displayedColumns?.currentValue) {
            this.setFullListOfColumns();
        }
    }

    ngAfterViewInit(): void {
        if (this.tableData$) {
            this.tableData$
                .pipe(
                    untilDestroyed(this),
                    filter(data => !!data)
                )
                .subscribe(data => {
                    this.dataSource = this.getMappedDataSource(data.dataset);

                    this.paginatorComponent.paginator.pageIndex = data.options.page - 1;
                    this.paginatorComponent.paginator.pageSize = data.options.itemsPerPage;
                    this.paginatorComponent.paginator.length = data.totalItems;
                    this.itemsPerPage = data.options.itemsPerPage;
                    this.totalItems = data.totalItems;

                    if (!this.tableIsServerSide) {
                        if (this.sort) {
                            this.dataSource.sort = this.sort;
                        }

                        if (this.customSorting) {
                            this.dataSource.sortData = (items, sort: MatSort) => {
                                return this.customSorting(items, sort);
                            };
                        }

                        this.dataSource.sortingDataAccessor = (item, property) => {
                            if (typeof item.element[property] === "string") {
                                return item.element[property].toLocaleLowerCase();
                            }

                            return item.element[property];
                        };
                        this.dataSource.paginator = this.paginatorComponent.paginator;
                    }
                });
            this.cellTemplates.forEach(template => {
                this.tableCells[template.columName] = template.template;
            });
        }

        this.sort.sortChange.pipe(untilDestroyed(this)).subscribe(() => {
            this.sortChange.emit({
                property: this.sort.active,
                direction: this.sort.direction,
            });
        });

        this.paginatorComponent.paginator.page
            .pipe(untilDestroyed(this))
            .subscribe(() => {
                if(this.withPageSize) {
                    this.pageChange.emit({
                        page: this.paginatorComponent.paginator.pageIndex + 1,
                        itemsPerPage: this.paginatorComponent.paginator.pageSize
                    });
                } else {
                    this.pageChange.emit(this.paginatorComponent.paginator.pageIndex + 1);
                }
            });
    }

    public setDefaultOptions(): void {
        if (this.defaultOptions?.sort) {
            this.sortActive = this.defaultOptions.sort?.property;
            this.sortDirection = this.defaultOptions.sort?.direction;
        }

        if (this.defaultOptions?.itemsPerPage) {
            this.itemsPerPage = this.defaultOptions.itemsPerPage;
        }
    }

    get isPaginatorHidden(): boolean {
        return !!this.error;
    }

    public onRowClick(item: ExpandableData<T>): void {
        this.rowClick.emit(item.element);
    }

    public expandAllClick(): void {
        if (this.dataSource?.data?.length) {
            this.allExpanded = !this.allExpanded;

            this.dataSource.data.forEach(item => {
                item.expanded = this.allExpanded;
                if (!this.expandAllChange.observers.length) {
                    this.expandChange.emit(item);
                }
            });

            if (this.expandAllChange.observers.length) {
                this.expandAllChange.emit({
                    expanded: this.allExpanded,
                    elements: this.dataSource.data,
                });
            }
        }
    }

    public canExpand(item: T): boolean {
        return this.canExpandCheck && item ? this.canExpandCheck(item) : true;
    }

    public elementExpandClick(item: ExpandableData<T>): void {
        item.expanded = !item.expanded;
        this.expandChange.emit(item);
        if (item.expanded) {
            this.allExpanded = true;
        } else {
            let isAllCollapsed = true;
            this.dataSource.data.forEach(d => {
                if (d.expanded) {
                    isAllCollapsed = false;
                }
            });
            if (isAllCollapsed) {
                this.allExpanded = false;
            }
        }
    }

    // For use with client-side tables only (sorting and paging is performed on client side),
    // You should NOT use this method for server-side tables!!!
    //
    // Call this method to reset sorting and paging to default
    public resetOptionsForClientTable(): void {
        if (!this.tableIsServerSide) {
            this.paginatorComponent.paginator.firstPage();
        }

        const id = this.sort?.active === null ? "" : null;
        // Use two variant default id, method sort before update sorting compare previous and current id,
        // if Id the same then method sort won't reset direction

        this.sort?.sort({ id, start: "desc", disableClear: false });
        // First page in MatPaginator class is 0, but this component emits 1 as the first page
        // Backend also uses 1 as the first page
        this.pageChange.emit(1);
    }

    public getErrorMessage(error: string | boolean): string {
        return typeof error === "string" && error !== "" ? error : this.errorMessage;
    }

    private getExtendedColumnsConfig(
        displayedColumns: TableColumnConfig[]
    ): ExtendedColumnConfig[] {
        return displayedColumns.map(column => ({
            ...column,
            cellCssClasses: {
                "cell-align-left": column.horizontalAlign === AlignOptions.Start,
                "cell-align-center": column.horizontalAlign === AlignOptions.Center,
                "cell-align-right": column.horizontalAlign === AlignOptions.End,
                "cell-align-top": column.verticalAlign === AlignOptions.Start,
                "cell-align-middle": column.verticalAlign === AlignOptions.Center,
                "cell-align-bottom": column.verticalAlign === AlignOptions.End,
            },
            headerCssClasses: {
                [`mat-header-${column.value}`]: true,
                "header-align-left": column.horizontalAlign === AlignOptions.Start,
                "header-align-center": column.horizontalAlign === AlignOptions.Center,
                "header-align-right": column.horizontalAlign === AlignOptions.End,
            },
        }));
    }

    private setFullListOfColumns(): void {
        const allTableColumns: ExtendedColumnConfig[] = [];

        // 'Collapse' column
        if (this.withCollapse) allTableColumns.push(EXPAND_TABLE_CONFIG.collapseColumn);

        // The rest columns
        const extendedColumnsConfig = this.getExtendedColumnsConfig(this.displayedColumns);
        allTableColumns.push(...extendedColumnsConfig);

        this.columns = allTableColumns;
        this.columnNames = this.columns.map(col => col.value);
    }

    private getMappedDataSource(data: T[]): MatTableDataSource<ExpandableData<T>> {
        const dataSource = new MatTableDataSource(
            data.map(elem => ({
                element: elem,
                expanded: elem.expandedByDefault || false,
            }))
        );
        this.allExpanded = dataSource.data.some(item => item.expanded);

        return dataSource;
    }
}
