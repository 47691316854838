import { Component, Input } from "@angular/core";

@Component({
    selector: "edge-info-progress-widget",
    templateUrl: "./info-progress-widget.component.html",
})
export class InfoProgressWidgetComponent {
    @Input() title = "";
    @Input() value: number;
    @Input() limit: number;
    @Input() percentInput?: number;

    public get percent(): number {
        return this.percentInput ? this.percentInput : Math.round((this.value * 100) / this.limit);
    }
}
