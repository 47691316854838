import {
    Component,
    ContentChild,
    ElementRef,
    Input,
    OnChanges,
    SimpleChanges,
} from "@angular/core";

import {
    SupportCallStatuses,
    TransactionTypeEnum,
    TRANSACTION_TYPE_LABELS,
    TransactionStatusEnum,
    TRANSACTION_STATUS_LABELS,
    ComplianceReportStatusEnum,
    SSLHistoryEnum,
    SSLStatusEnum,
} from "../../interfaces/status-badge.interface";

enum StatusTypes {
    SupportTicket = "supportTicket",
    TransactionType = "transactionType",
    TransactionStatus = "transactionStatus",
    ComplianceReport = "complianceReport",
    SSLStatus = "sslStatus",
    SSLHistory = "sslHistory",
}

@Component({
    selector: "edge-status-badge",
    templateUrl: "./status-badge.component.html",
})
export class StatusBadgeComponent implements OnChanges {
    @Input() statusName: string;
    @Input() statusType: StatusTypes;
    @Input() isSolid = false;

    @ContentChild("content") content: ElementRef<HTMLElement>;

    public statusColor = "";

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.statusName?.currentValue || changes.statusType?.currentValue) {
            this.updateStatus();
        }
    }

    private updateStatus(): void {
        switch (this.statusType) {
            case StatusTypes.SupportTicket:
                this.updateSupportCallColor();
                break;
            case StatusTypes.TransactionType:
                this.updateTransactionTypeColor();
                break;
            case StatusTypes.TransactionStatus:
                this.updateTransactionStatusColor();
                break;
            case StatusTypes.ComplianceReport:
                this.updateComplianceReportColor();
                break;
            case StatusTypes.SSLStatus:
                this.updateSSLTypeColor();
                break;
            case StatusTypes.SSLHistory:
                this.updateSSLHistoryTypeColor();
                break;
            default:
                break;
        }
    }

    private updateSupportCallColor(): void {
        switch (this.statusName as SupportCallStatuses) {
            case SupportCallStatuses.Closed:
                this.statusColor = "";
                break;
            case SupportCallStatuses.Resolved:
                this.statusColor = "success-color";
                break;
            case SupportCallStatuses.Implemented:
                this.statusColor = "success-color";
                break;
            case SupportCallStatuses.Assigned:
                this.statusColor = "success-color";
                break;
            case SupportCallStatuses.ReOpened:
                this.statusColor = "warning-color";
                break;
            case SupportCallStatuses.Disabled:
                this.statusColor = "error-color";
                break;
            case SupportCallStatuses.InProcess:
                this.statusColor = "info-color";
                break;
            case SupportCallStatuses.Implementation:
                this.statusColor = "info-color";
                break;
            case SupportCallStatuses.New:
                this.statusColor = "success-color";
                break;
            default:
                this.statusColor = "";
        }
    }

    private updateTransactionTypeColor(): void {
        switch (this.statusName as TransactionTypeEnum) {
            case TRANSACTION_TYPE_LABELS[TransactionTypeEnum.Invoice]:
                this.statusColor = "error-color";
                break;
            case TRANSACTION_TYPE_LABELS[TransactionTypeEnum.CreditMemo]:
                this.statusColor = "warning-color";
                break;
            case TRANSACTION_TYPE_LABELS[TransactionTypeEnum.CreditMemoLegacy]:
                this.statusColor = "warning-color";
                break;
            case TRANSACTION_TYPE_LABELS[TransactionTypeEnum.Journal]:
                this.statusColor = "info-color";
                break;
            case TRANSACTION_TYPE_LABELS[TransactionTypeEnum.Payment]:
                this.statusColor = "success-color";
                break;
            default:
                this.statusColor = "primary-color";
        }
    }

    private updateTransactionStatusColor(): void {
        switch (this.statusName as TransactionStatusEnum) {
            case TRANSACTION_STATUS_LABELS[TransactionStatusEnum.Credit]:
                this.statusColor = "info-color";
                break;
            case TRANSACTION_STATUS_LABELS[TransactionStatusEnum.Paid]:
                this.statusColor = "success-color";
                break;
            case TRANSACTION_STATUS_LABELS[TransactionStatusEnum.Partial]:
                this.statusColor = "info-color";
                break;
            case TRANSACTION_STATUS_LABELS[TransactionStatusEnum.PastDuePartial]:
                this.statusColor = "error-color";
                break;
            case TRANSACTION_STATUS_LABELS[TransactionStatusEnum.PastDueUnpaid]:
                this.statusColor = "error-color";
                break;
            case TRANSACTION_STATUS_LABELS[TransactionStatusEnum.Applied]:
                this.statusColor = "default-color";
                break;
            case TRANSACTION_STATUS_LABELS[TransactionStatusEnum.Unapplied]:
                this.statusColor = "default-color";
                break;
            case TRANSACTION_STATUS_LABELS[TransactionStatusEnum.Unpaid]:
                this.statusColor = "error-color";
                break;
            case TRANSACTION_STATUS_LABELS[TransactionStatusEnum.ACHPending]:
                this.statusColor = "default-color";
                break;
            default:
                this.statusColor = "primary-color";
        }
    }

    private updateComplianceReportColor(): void {
        switch (this.statusName as ComplianceReportStatusEnum) {
            case ComplianceReportStatusEnum.NeedsReview:
                this.statusColor = "default-color";
                break;
            case ComplianceReportStatusEnum.Verified:
                this.statusColor = "success-color";
                break;
            case ComplianceReportStatusEnum.Warning:
                this.statusColor = "warning-color";
                break;
            case ComplianceReportStatusEnum.Critical:
                this.statusColor = "error-color";
                break;
            default:
                this.statusColor = "primary-color";
        }
    }

    private updateSSLTypeColor(): void {
        switch (this.statusName as SSLStatusEnum) {
            case SSLStatusEnum.COMPLETE:
                this.statusColor = "success-color";
                break;
            case SSLStatusEnum.EXPIRED:
                this.statusColor = "error-color";
                break;
            case SSLStatusEnum.CANCELLED:
                this.statusColor = "error-color";
                break;
            case SSLStatusEnum.PENDING:
                this.statusColor = "warning-color";
                break;
            default:
                this.statusColor = "primary-color";
        }
    }

    private updateSSLHistoryTypeColor(): void {
        switch (this.statusName as SSLHistoryEnum) {
            case SSLHistoryEnum.EmailSent:
                this.statusColor = "success-color";
                break;
            case SSLHistoryEnum.EmailFailed:
                this.statusColor = "error-color";
                break;
            default:
                this.statusColor = "primary-color";
        }
    }
}
