import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "edgeCCMask" })
export class EdgeCCMaskPipe implements PipeTransform {
    transform(value: string | null): string {
        if (value == null) return;

        const start = value.substr(0, 1);
        const end = value.substr(value.length - 4, 4);
        const mask = "*** **** **** ";

        // eslint-disable-next-line consistent-return
        return `${start}${mask}${end}`;
    }
}
