import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Observable, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { METADATA } from "./idle-dialog.metadata";

export interface DialogData {
    timeLeft: number;
    countdown$: Observable<number>;
    onContinueSession(): void;
}

@Component({
    selector: "edge-idle-dialog",
    templateUrl: "./idle-dialog.component.html",
})
export class IdleDialogComponent implements OnInit, OnDestroy {
    meta = METADATA;

    private onDestroy = new Subject<void>();

    constructor(
        public ref: MatDialogRef<IdleDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData
    ) {}

    ngOnInit(): void {
        this.ref
            .backdropClick()
            .pipe(takeUntil(this.onDestroy))
            .subscribe(() => {
                this.onClose();
            });
    }

    onClose(): void {
        this.data.onContinueSession();
        this.ref.close();
    }

    ngOnDestroy(): void {
        this.onDestroy.next();
        this.onDestroy.complete();
    }
}
