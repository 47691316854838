import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { webApiUrls } from "@core/constants/url.constant";
import { GlobalCacheService } from "@core/services/global-cache.service";
import { GlobalCacheKeys } from "@core/enums/global-cache-keys.enum";
import { DatacenterLocation } from "../interfaces/datacenter-location.interface";

@Injectable({
    providedIn: "root",
})
export class DCPhysicalAccessAPIService {
    constructor(private http: HttpClient) {}

    public getPhysicalAccessIntegrationComplete(): Observable<DatacenterLocation[]> {
        const cachedData = GlobalCacheService.get(
            GlobalCacheKeys.DCLocations
        ) as DatacenterLocation[];
        if (cachedData) {
            return of(cachedData);
        }

        return this.http
            .get<DatacenterLocation[]>(
                `${webApiUrls.eventManagement}/DatacenterAccess/getPhysicalAccessIntegrationComplete`
            )
            .pipe(tap(data => GlobalCacheService.set(GlobalCacheKeys.DCLocations, data)));
    }
}
