<div class="edge-footer">
    <div class="edge-container">
        <edge-copyright></edge-copyright>
        <div class="edge-nav">
            <a href="https://www.databank.com/privacy" class="edge-nav-link">Privacy Policy</a>
            <a href="https://www.databank.com/terms-of-use/" class="edge-nav-link">Terms of Use</a>

            <ng-container *ngIf="(settings$ | async) as settings">
                <a [href]="settings.releaseNotesUrl" target="_blank" class="edge-nav-link">
                    Version: {{settings.versionNumber}}
                </a>
            </ng-container>
        </div>
    </div>
</div>
