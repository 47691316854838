import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    OnDestroy,
    OnInit,
} from "@angular/core";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { TimeframeService } from "@shared/services/timeframe.service";
import { DialogService } from "@shared/components/dialog/dialog.service";
import { EdgeDatePipe } from "@core/pipes/edge-date.pipe";
import { TimeframeLabel, TimeframeSettingsOption } from "@core/enums/timeframe.enum";
import { EdgeRouterData } from "@core/interfaces/routes.interface";
import { TimeframeDropdownOption } from "@core/interfaces/timeframe.interface";
import { TopBarTimeframeDialogComponent } from "../top-bar-timeframe-dialog/top-bar-timeframe-dialog.component";
import { TIMEFRAME_DROPDOWN_OPTIONS } from "../../timeframe.constants";

@Component({
    selector: "edge-top-bar-timeframe",
    templateUrl: "./top-bar-timeframe.component.html",
    styleUrls: ["./top-bar-timeframe.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarTimeframeComponent implements OnInit, OnDestroy {
    previousSetting: TimeframeSettingsOption;
    currentSetting: TimeframeSettingsOption;
    dropdownOptions: TimeframeDropdownOption[] = [];

    private destroy$ = new Subject<void>();

    constructor(
        private datePipe: EdgeDatePipe,
        private timeframeService: TimeframeService,
        private route: ActivatedRoute,
        private router: Router,
        private dialogService: DialogService,
        private cdr: ChangeDetectorRef
    ) {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.initDefaultSettings();
            }
        });
    }

    ngOnInit(): void {
        this.initDefaultSettings();
        this.initCustomSettings();
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    onSelectedSetting(settingId: TimeframeSettingsOption): void {
        this.currentSetting = settingId;
        if (settingId === TimeframeSettingsOption.Custom) {
            const frameInterval = this.timeframeService.getFrameInterval();
            this.dialogService.custom(TopBarTimeframeDialogComponent, {
                data: {
                    content: frameInterval,
                    onConfirm: () => {
                        return (startDate, endDate) => {
                            this.setSettings(settingId, startDate, endDate);
                            this.updateCustomOptionLabel(startDate, endDate);
                        };
                    },
                    onReject: () => {
                        this.currentSetting = this.previousSetting;
                    },
                },
            });
        } else {
            this.setSettings(settingId);
        }
    }

    private initCustomSettings() {
        const from =
            this.route.snapshot.queryParamMap.get("from") ||
            this.route.snapshot.queryParamMap.get("startDate");
        const to =
            this.route.snapshot.queryParamMap.get("to") ||
            this.route.snapshot.queryParamMap.get("endDate");
        const widget = this.route.snapshot.queryParamMap.get("widget");

        if (!widget && from && to) {
            this.setSettings(TimeframeSettingsOption.Custom, new Date(from), new Date(to), true);
            this.currentSetting = TimeframeSettingsOption.Custom;
            this.updateCustomOptionLabel(new Date(from), new Date(to));
        }
    }

    private initDefaultSettings(): void {
        const routerData: EdgeRouterData = this.route.snapshot.firstChild.firstChild.data;
        if (routerData) {
            const { defaultTimeframeOptions } = routerData;
            this.initDropdownOptions(defaultTimeframeOptions);

            const settings = this.timeframeService.getSettings();
            const defaultSettingsOption = routerData.defaultTimeframeOption;
            let userSettingsToUse = defaultSettingsOption;

            if (
                !settings.settings ||
                !defaultTimeframeOptions.includes(settings.settings) ||
                (settings.isDefault && settings.settings !== defaultSettingsOption)
            ) {
                this.timeframeService.setSettings(defaultSettingsOption, null, null, true);
            } else {
                userSettingsToUse = settings.settings;
            }

            this.currentSetting = userSettingsToUse;
            this.previousSetting = userSettingsToUse;
            this.cdr.detectChanges();
        }
    }

    private initDropdownOptions(optionsId: TimeframeSettingsOption[]): void {
        this.dropdownOptions = optionsId.map(optionId => {
            if (optionId === TimeframeSettingsOption.Custom) {
                const frameInterval = this.timeframeService.getSettings();
                return {
                    id: TimeframeSettingsOption.Custom,
                    name: TimeframeLabel.Custom,
                    displayName: this.getCustomOptionLabel(
                        frameInterval?.startDate,
                        frameInterval?.endDate
                    ),
                };
            }
            return TIMEFRAME_DROPDOWN_OPTIONS.find(option => option.id === optionId);
        });
    }

    private getCustomOptionLabel(startDate: Date, endDate: Date): string {
        const formattedStartDate = startDate && this.datePipe.transform(startDate);
        const formattedEndDate = endDate && this.datePipe.transform(endDate);
        return !!formattedStartDate && !!formattedEndDate
            ? `${formattedStartDate} to ${formattedEndDate}`
            : "Custom";
    }

    private updateCustomOptionLabel(startDate: Date, endDate: Date): void {
        const option = this.dropdownOptions.find(
            setting => setting.id === TimeframeSettingsOption.Custom
        );
        option.displayName = this.getCustomOptionLabel(startDate, endDate);
    }

    private setSettings(
        settings: TimeframeSettingsOption,
        startDate?: Date,
        endDate?: Date,
        isDefault = false
    ): void {
        this.timeframeService.setSettings(settings, startDate, endDate, isDefault);
        this.previousSetting = settings;
    }
}
