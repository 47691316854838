<!-- #48000 - Hide functionality until further notice
<div *ngIf="showNewsSection" class="top-bar-news">
    <span class="top-bar-news-heading">LATEST NEWS</span>
    <div *ngFor="let newsItem of news" class="top-bar-news-item">
        <ng-container *ngIf="!newsItem.hidden">
            <div class="top-bar-news-item-content" [innerHTML]="newsItem.messageHtml"></div>
            <a href="javascript:void(0)" class="edge-dialog-close" (click)="close(newsItem)">
                <i aria-hidden="true" class="flaticon2-cross"></i>
            </a>
        </ng-container>
    </div>
</div>
-->

<div class="top-bar">
    <div class="top-bar-heading">
        <img *ngIf="!isUserAuthorized" class="top-bar-logo" src="assets/images/logo-small.gif" routerLink="/login" alt="logo">
        <div class="top-bar-title">
            <button *ngIf="settings?.isPublic && isUserAuthorized"
                    id="top-bar-redirect-home__button"
                    mat-icon-button
                    class="clean"
                    matTooltip="Back to Home"
                    matTooltipPosition="below"
                    matTooltipClass="below"
                    (click)="goHome()"
            >
                <mat-icon svgIcon="arrow_from_right"></mat-icon>
            </button>
            <ng-container *ngIf="settings?.back">
                <button id="top-bar-redirect-back__button"
                        mat-icon-button
                        class="clean"
                        [matTooltip]="settings?.back?.title"
                        matTooltipPosition="below"
                        matTooltipClass="below"
                        (click)="backTo()"
                >
                    <mat-icon svgIcon="arrow_from_right"></mat-icon>
                </button>
            </ng-container>
            <div class="top-bar-group">
                <h1>{{settings?.pageTitle}}</h1>
            </div>
            <ng-container *ngIf="settings?.quickLinks">
                <div *ngFor="let link of settings.quickLinks; let i = index" class="top-bar-group">
                    <button id="top-bar-redirect-to-{{i}}__button"
                            mat-icon-button
                            class="clean"
                            [matTooltip]="link.title"
                            matTooltipPosition="below"
                            matTooltipClass="below"
                            (click)="goTo(link)"
                    >
                        <mat-icon [svgIcon]="link.icon"></mat-icon>
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
    <div class="top-bar-items">
        <edge-top-bar-timeframe *ngIf="!settings?.isTimeSettingHidden"></edge-top-bar-timeframe>
        <edge-top-bar-organization class="top-bar-item" *ngIf="!settings?.isPublic"></edge-top-bar-organization>
        <ng-container *ngIf="hasHistoryAccess && !settings?.isPublic">
            <edge-top-bar-tickets class="top-bar-item"
                                  *ngIf="isUserAuthorized"
                                  [data]="tickets$ | async"
                                  [isLoading]="isTicketsLoading"
            ></edge-top-bar-tickets>
        </ng-container>
        <ng-container *ngIf="hasAlarmsAccess && !settings?.isPublic">
            <edge-top-bar-alarms class="top-bar-item"
                                 *ngIf="isUserAuthorized"
                                 [data]="alarms$ | async"
                                 [isLoading]="isAlarmsLoading"
            ></edge-top-bar-alarms>
        </ng-container>
        <edge-top-bar-userinfo class="top-bar-group" *ngIf="isUserAuthorized"></edge-top-bar-userinfo>
        <div class="top-bar-group" *ngIf="isUserAuthorized">
            <button mat-icon-button
                    button-loading
                    class="clean"
                    matTooltip="Log Out"
                    matTooltipPosition="below"
                    matTooltipClass="below"
                    id="top-btnLogOff"
                    [loading]="isLogoutInProgress"
                    (click)="logout()"
            >
                <mat-icon svgIcon="logout"></mat-icon>
            </button>
        </div>
    </div>
</div>
