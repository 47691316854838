export enum GlobalCacheKeys {
    Roles = "roles",
    Tags = "tags",
    DCLocations = "dc_locations",
    SupportTicketsHistory = "sup_ticketsHistory",
    CardholderAccessList = "cardholder-access-list",
    DCAccessReport = "dc-access-report",
    OpenTickets = "open-tickets",
    AlarmHistory = "alarm-history",
    BillablePortsAssets = "billablePortsAssets",
    ZColoPorts = "zColoPorts",
    Prefixes = "prefixes",
    Locations = "locations",
    IPPrefixes = "ip-prefixes",
    ThreatList = "threat-list",
    PowerMetrics = "power-metrics",
}
