import { Component, Input } from "@angular/core";

export enum InfoWidgetType {
    classic = "classic",
    linkBased = "link-based",
}

export enum InfoWidgetColorType {
    default = "default", // ORANGE color
    info = "info", // BLUE color
    error = "error", // RED color
    success = "success", // GREEN color
    primary = "primary", // PRIMARY color
    disable = "disable", // GRAY color
    dark = "dark", // DARK color
    lightBlue = "light-blue", // LIGHT-BLUE color
    turquoise = "turquoise", // TURQUOISE color
}

@Component({
    selector: "edge-info-widget",
    templateUrl: "./info-widget.component.html",
})
export class InfoWidgetComponent {
    @Input() type: InfoWidgetType = InfoWidgetType.classic;
    @Input() colorType: InfoWidgetColorType = InfoWidgetColorType.default;
    @Input() clickable = false;
    @Input() small = false;

    public typeEnum = InfoWidgetType;
    public colorTypeEnum = InfoWidgetColorType;
}
