import { Injectable, TemplateRef } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class SubTopBarService {
    set template(value: TemplateRef<any> | null) {
        this.template$$.next(value);
    }

    private template$$ = new BehaviorSubject<TemplateRef<any> | null>(null);

    template$ = this.template$$.asObservable();

}
