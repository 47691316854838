<div [loading]="(data.loading$ | async)?.loading">
    <div class="edge-dialog-header">
        <span class="edge-dialog-title flexible">{{data.title}}</span>
        <a href="javascript:void(0)" class="edge-dialog-close" mat-dialog-close>
            <mat-icon svgIcon="close"></mat-icon>
        </a>
    </div>
    <div class="edge-dialog-content" mat-dialog-content [innerHtml]="data.body">
    </div>
    <div class="edge-dialog-footer" mat-dialog-actions>
        <button mat-stroked-button *ngIf="!data.hideCancelButton"
                color="primary"
                (click)="reject()"
                mat-dialog-close
        >{{data.cancelButton}}</button>
        <button mat-flat-button
                color="primary"
                (click)="confirm()"
                cdkFocusInitial
        >{{data.okButton}}</button>
    </div>
</div>
