import { Injectable } from "@angular/core";
import { webApiUrls } from "@core/constants/url.constant";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ProductAlarms } from "../interfaces/product-alarms.interface";

@Injectable({
    providedIn: "root",
})
export class ProductAlarmsApiService {
    constructor(private http: HttpClient) {}

    public getProductAlarms(): Observable<ProductAlarms> {
        return this.http.get<ProductAlarms>(`${webApiUrls.productManagement}/ProductsAlarms`);
    }
}
