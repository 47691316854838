export enum OrganizationDataAvailabilityFlags {
    Any = "ANY",
    Colocation = "COLOCATION",
    Network = "NETWORK",
    DatacenterPhysicalAccess = "DATACENTER_PHYSICAL_ACCESS",
    DatacenterPhysicalAccessTabs = "DATACENTER_PHYSICAL_ACCESS_TABS",
    ManagedSystems = "MANAGED_SYSTEMS",
    ManagedSystemsVpc = "MANAGED_SYSTEMS_VPC",
    ManagedSystemsLicensing = "MANAGED_SYSTEMS_LICENSING",
    ManagedSystemsServers = "MANAGED_SYSTEMS_SERVERS",
    ManagedSystemsFirewall = "MANAGED_SYSTEMS_FIREWALL",
    ManagedSystemsLoadBalancers = "MANAGED_SYSTEMS_LOAD_BALANCER",
    ManagedSystemsStorage = "MANAGED_SYSTEMS_STORAGE",
    ManagedServicesCloudflare = "MANAGED_SERVICES_CLOUDFLARE",
    Security = "SECURITY",
    Analytics = "ANALYTICS",
    AnalyticsMonitoringDashboard = "ANALYTICS_MONITORING_DASHBOARD",
    MyAccount = "MY_ACCOUNT",
}
