import { ChangeDetectionStrategy, Component } from "@angular/core";
import { NavigationIndicatorService } from "@core/services/navigation-indicator.service";

@Component({
    selector: "edge-navigation-indicator",
    templateUrl: "./navigation-indicator.component.html",
    styleUrls: ["./navigation-indicator.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NavigationIndicatorComponent {
    public isProgressBarActive$ = this.navIndicatorService.isActive$;

    constructor(private navIndicatorService: NavigationIndicatorService) {}
}
