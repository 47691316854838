import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "edgeNumbersName" })
export class EdgeNumbersNamePipe implements PipeTransform {
    transform(value: number, decimals = 0, removeZeros = false): string {
        let exp = 0;
        const suffixes = ["", "K", "M", "G", "T", "P", "E"];

        if (!value) {
            return null;
        }

        let result = null;

        if (value < 1000) {
            result = value && value.toFixed(decimals);
        } else {
            exp = Math.floor(Math.log(value) / Math.log(1000));
            // eslint-disable-next-line no-restricted-properties
            result = (value / Math.pow(1000, exp)).toFixed(decimals);
        }
        if (removeZeros) {
            result = +result.toString();
        }

        return result + suffixes[exp];
    }
}
