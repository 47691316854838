/* eslint-disable no-bitwise */
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { IOrganizationPermission } from "@core/interfaces/organization-permission.interface";
import { PERMISSIONS_ACTIONS } from "@core/constants/permission.constant";
import {
    IPermissionCustomDetails,
    PermissionCheckType,
} from "../../interfaces/permission.interface";

@Injectable({
    providedIn: "root",
})
export class PermissionsStorageService {
    /** ************************************************************************
     Data
     ************************************************************************* */
    public data: BehaviorSubject<IOrganizationPermission[]> = new BehaviorSubject(null);
    public permissions: IOrganizationPermission[] = [];

    /** ************************************************************************
     DATA
     ************************************************************************* */
    public setData(value: IOrganizationPermission[] = []): void {
        this.permissions = value;
        // fire event
        this.data.next(value);
    }

    /** ************************************************************************
     Methods
     ************************************************************************* */
    public has(code: number, action: number = PERMISSIONS_ACTIONS.view): boolean {
        return (
            // eslint-disable-next-line no-bitwise
            ((
                (this.permissions || []).find(item => item.id === code) ?? {
                    permission: PERMISSIONS_ACTIONS.none,
                }
            ).permission &
                action) ===
            action
        );
    }

    public hasAll(codes: number[] = [], action: number = PERMISSIONS_ACTIONS.view): boolean {
        return codes.every(permission => this.has(permission, action));
    }

    public hasAny(codes: number[] = [], action: number = PERMISSIONS_ACTIONS.view): boolean {
        return codes.some(permission => this.has(permission, action));
    }

    public hasView(code: number): boolean {
        return this.has(code, PERMISSIONS_ACTIONS.view);
    }

    public hasAdd(code: number): boolean {
        return this.has(code, PERMISSIONS_ACTIONS.add);
    }

    public hasEdit(code: number): boolean {
        return this.has(code, PERMISSIONS_ACTIONS.edit);
    }

    public hasDelete(code: number): boolean {
        return this.has(code, PERMISSIONS_ACTIONS.delete);
    }

    public checkCustomPermission(permissionsDetails: IPermissionCustomDetails): boolean {
        if (!permissionsDetails.permissions.length) {
            return false;
        }

        if (permissionsDetails.checkType === PermissionCheckType.Every) {
            return permissionsDetails.permissions.every(permission =>
                typeof permission === "object"
                    ? this.has(permission.permissionCode, permission.permissionAction)
                    : this.has(permission)
            );
        }

        return permissionsDetails.permissions.some(permission =>
            typeof permission === "object"
                ? this.has(permission.permissionCode, permission.permissionAction)
                : this.has(permission)
        );
    }

    public checkPermission(permissionCode: number, permissionAction: number): boolean {
        return !(permissionCode && !this.has(permissionCode, permissionAction));
    }
}
