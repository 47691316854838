import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { ActivatedRoute, ActivationStart, NavigationEnd, Router, Scroll } from "@angular/router";
import { LayoutType } from "@core/enums/layout-type.enum";
import { Subject, asapScheduler } from "rxjs";
import { observeOn, takeUntil } from "rxjs/operators";
import { SettingStorageService } from "@core/services/storages/setting-storage.service";
import { RouterHelper } from "@core/helpers/router-helper";
import { AuthStorageService } from "@core/services/storages/auth-storage.service";
import { DOCUMENT } from "@angular/common";
import { SubTopBarService } from "@shared/modules/sub-top-bar/sub-top-bar.service";

@Component({
    selector: "edge-portal-layout",
    templateUrl: "./portal-layout-shell.component.html",
    styleUrls: ["./portal-layout-shell.component.scss"],
})
export class PortalLayoutShellComponent implements OnInit, OnDestroy {
    currentLayout: LayoutType;
    isPublic$ = this.settings.isPublic();
    subTopBarTemplate$ = this.subTopBarService.template$.pipe(
        observeOn(asapScheduler)
    );
    
    private destroy$ = new Subject<void>();

    private sideBarMenuStatus = true;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private auth: AuthStorageService,
        private settings: SettingStorageService,
        @Inject(DOCUMENT) document: Document,
        private subTopBarService: SubTopBarService
    ) {
        this.router.events.pipe(takeUntil(this.destroy$)).subscribe(event => {
            if (event instanceof ActivationStart) {
                this.currentLayout = event.snapshot.data.layout;
            }

            if (event instanceof NavigationEnd) {
                const params = this.router.getCurrentNavigation();
                this.settings.updateSetting({
                    isPublic: RouterHelper.hasRouteDataValue("isPublic", this.route.snapshot)
                        ? RouterHelper.getRouteDataValue("isPublic", this.route.snapshot)
                        : !this.auth.isAuthorized(),
                    pageTitle:
                        RouterHelper.getRouteDataValue("stateName", this.route.snapshot) ||
                        "UNTITLED",
                    back: RouterHelper.getRouteDataValue("back", this.route.snapshot) || null,
                    quickLinks:
                        RouterHelper.getRouteDataValue("quickLinks", this.route.snapshot) || null,
                    lastUrl: RouterHelper.getRouteDataValue(
                        "skipSavingLastUrl",
                        this.route.snapshot
                    )
                        ? null
                        : {
                              path: event.url,
                              params: params.extras.state,
                              queryParams: params.extras.queryParams,
                          },
                    isTimeSettingHidden: RouterHelper.getRouteDataValue(
                        "isTimeSettingHidden",
                        this.route.snapshot
                    ),
                });
            }

            if (event instanceof Scroll) {
                if (event.anchor) {
                    // Scroll to anchor
                    setTimeout(() => {
                        document
                            .getElementById(event.anchor)
                            ?.scrollIntoView({ behavior: "smooth" });
                    });
                } else {
                    // Scroll to top of the page
                    document
                        .getElementById("portal-container")
                        ?.scrollIntoView({ behavior: "smooth" });
                }
            }
        });
    }

    ngOnInit(): void {
        this.currentLayout = this.route.snapshot.firstChild.data.layout;

        this.settings
            .isSidebarCollapsed()
            .pipe(takeUntil(this.destroy$))
            .subscribe(isCollapsed => {
                this.sideBarMenuStatus = isCollapsed;
            });
    }

    public get sideBarStatusClass(): string {
        return this.sideBarMenuStatus ? "collapsed" : "expanded";
    }

    ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }
}
