/* eslint-disable no-nested-ternary */
import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { IPermissionCustomDetails } from "../interfaces/permission.interface";
import { OrganizationDataAvailabilityStorageService } from "../services/storages/organization-data-availability-storage.service";
import { PermissionsStorageService } from "../services/storages/permissions-storage.service";

@Directive({
    selector: "[edgeAvailability]",
})
export class AvailabilityDirective implements OnInit {
    private permission: number;
    private permissionAction: number;
    private isVisible = false;
    private elseTemplate: TemplateRef<any>;
    private customPermission: IPermissionCustomDetails;
    private organizationFlag: string;

    constructor(
        private templateRef: TemplateRef<any>,
        private viewContainer: ViewContainerRef,
        private permissionStorage: PermissionsStorageService,
        private organizationAvailabilityStorage: OrganizationDataAvailabilityStorageService
    ) {}

    ngOnInit(): void {
        this.updateView();
    }

    @Input()
    set edgeAvailability(permissionCodes: number | IPermissionCustomDetails) {
        if (typeof permissionCodes === "object") {
            this.customPermission = permissionCodes as IPermissionCustomDetails;
        } else {
            this.permission = permissionCodes;
        }
    }

    @Input()
    set edgeAvailabilityFlag(flag: string) {
        this.organizationFlag = flag;
    }

    @Input()
    set edgeAvailabilityAction(action: string | number) {
        this.permissionAction = +action;
    }

    @Input()
    set edgeAvailabilityElse(template: TemplateRef<any>) {
        this.elseTemplate = template;
    }

    private checkPermission(): boolean {
        return this.permissionStorage.has(this.permission, this.permissionAction);
    }

    private checkCustomPermission(): boolean {
        return (
            this.organizationAvailabilityStorage.checkOrganizationFlag(this.organizationFlag) &&
            this.permissionStorage.checkCustomPermission(this.customPermission)
        );
    }

    private updateView() {
        if (!this) {
            return;
        }
        const isAvailableByPermission = this.customPermission
            ? this.checkCustomPermission()
            : this.checkPermission();

        if (isAvailableByPermission) {
            if (!this.isVisible) {
                this.viewContainer.createEmbeddedView(this.templateRef);
                this.isVisible = true;
            }
        } else if (this.elseTemplate) {
            this.viewContainer.clear();
            this.viewContainer.createEmbeddedView(this.elseTemplate);
            this.isVisible = false;
        } else {
            this.isVisible = false;
            this.viewContainer.clear();
        }
    }
}
