export const METADATA = {
    itemsPerPage: 6,

    defaultValue: {
        totalItemsCount: 0,
        paginatedItems: [],
    },

    ticketColumns: [
        { value: "ticketNumber", title: "Ticket" },
        { value: "openedDate", title: "Date" },
        { value: "assignedTo", title: "Opened By" },
        { value: "summary", title: "Summary" },
    ],

    ticketReqParams: {
        PagingFilter: {
            page: 1,
            itemsPerPage: 6,
        },
    },
};
