import { Component, OnInit, ChangeDetectionStrategy, Inject } from "@angular/core";
import { UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { TIME_FRAME_MAX_DAYS_RANGE } from "@shared/constants/datetime.constants";
import { take } from "rxjs/operators";
import { IntervalSettings } from "@core/interfaces/timeframe.interface";

@Component({
    selector: "edge-top-bar-timeframe-dialog",
    templateUrl: "./top-bar-timeframe-dialog.component.html",
    styleUrls: ["./top-bar-timeframe-dialog.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarTimeframeDialogComponent implements OnInit {
    public dateForm: UntypedFormGroup;
    public pastDays: number = TIME_FRAME_MAX_DAYS_RANGE;

    constructor(
        public ref: MatDialogRef<TopBarTimeframeDialogComponent>,
        @Inject(MAT_DIALOG_DATA)
        public data: {
            [key: string]: any;
            content: IntervalSettings;
        }
    ) {}

    ngOnInit(): void {
        this.dateForm = new UntypedFormGroup({
            dateFrom: new UntypedFormControl(this.data.content?.startDate || null, [Validators.required]),
            dateTo: new UntypedFormControl(this.data.content?.endDate || null, [Validators.required]),
        });

        this.ref
            .afterClosed()
            .pipe(take(1))
            .subscribe(() => {
                this.data.onReject();
            });
    }

    public get selectedToValue(): Date {
        const dateToValue = this.dateForm.controls.dateTo.value;
        return dateToValue || this.maxDate;
    }

    public get selectedFromValue(): Date {
        const dateFromValue = this.dateForm.controls.dateFrom.value;
        return dateFromValue || this.minDate;
    }

    public get minDate(): Date {
        const minDate = new Date();
        const minDateMilliseconds = minDate.setDate(minDate.getDate() - this.pastDays);
        return new Date(minDateMilliseconds);
    }

    public get maxDate(): Date {
        return new Date();
    }

    public submit(): void {
        if (this.dateForm.valid) {
            this.data.onConfirm()(this.selectedFromValue, this.selectedToValue);
            this.ref.close();
        }
        this.dateForm.markAllAsTouched();
    }

    public close(): void {
        this.data.onReject();
        this.ref.close();
    }
}
