// TODO: Add organization service and uncomment all lines below
import { Pipe, PipeTransform } from "@angular/core";
import { CurrencyPipe } from "@angular/common";
import { META_DATA } from "@shared/metadata/organization.metadata";
import { OrganizationStorageService } from "@core/services/storages/organization-storage.service";

@Pipe({ name: "edgeCurrency" })
export class EdgeCurrencyPipe implements PipeTransform {
    private metadata = META_DATA;

    constructor(
        private organizations: OrganizationStorageService,
        private currencyPipe: CurrencyPipe,
    ) {
    }

    transform(
        value: any,
        currentCulture: string = this.organizations.currentCulture || "en-US",
        maxFractionDigits?: string
    ): string {
        const digitsInfo = maxFractionDigits ? `1.2-${maxFractionDigits}` : "1.2-2";
        const culture = this.metadata.cultureData.find((val) => val.currentCulture === currentCulture) || this.metadata.defaultCultureData;
        let str = this.currencyPipe.transform(value, culture.code, "symbol-narrow", digitsInfo, culture.currentCulture);

        if (typeof str === "string" && str.charAt(0) === "-" && culture.currentCulture === this.metadata.defaultCultureData.currentCulture)
            str = "(" + str.substring(1, str.length) + ")";

        return str;
    }
}
