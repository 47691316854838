import { Injectable } from "@angular/core";
import {
    Event,
    NavigationCancel,
    NavigationEnd,
    NavigationError,
    NavigationStart,
    Router,
} from "@angular/router";
import { BehaviorSubject } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class NavigationIndicatorService {
    private isActiveSbj = new BehaviorSubject(false);
    // It shows if navigation currently active or not
    public isActive$ = this.isActiveSbj.asObservable();

    set isActive(value: boolean) {
        this.isActiveSbj.next(value);
    }

    constructor(private router: Router) {
        this.router.events.subscribe((event: Event) => {
            if (event instanceof NavigationStart) {
                this.isActive = true;
            } else if (
                event instanceof NavigationEnd ||
                event instanceof NavigationCancel ||
                event instanceof NavigationError
            ) {
                this.isActive = false;
            }
        });
    }
}
