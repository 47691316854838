import { IUnsavedChanges } from "@core/interfaces/unsaved-changes.interface";

/*
    This object stores references to all Angular components decorated with UnsavedChangesDecorator
*/
const activeComponents = new Map<Symbol, unknown>();

/*
    Class decorator. 
    It saves reference to the decorated Angular component, 
    so we can use 'componentWithUnsavedChangesExist' function later
*/
export function UnsavedChangesDecorator<T extends Function>(target: T): T {
    const compId = Symbol("Component ID");

    const originalOnInit = target.prototype.ngOnInit;
    target.prototype.ngOnInit = function () {
        activeComponents.set(compId, this);
        originalOnInit?.apply(this);
    };

    const originalOnDestroy = target.prototype.ngOnDestroy;
    target.prototype.ngOnDestroy = function () {
        activeComponents.delete(compId);
        originalOnDestroy?.apply(this);
    };

    return target;
}

/*
    Call to check if there is any component with unsaved changes
*/
export function componentWithUnsavedChangesExist(): boolean {
    for (const value of Array.from(activeComponents)) {
        const comp = value[1];

        if (implementsUnsavedChanges(comp) && comp.hasUnsavedChanges()) {
            // Must return only if true
            return true;
        }
    }
    return false;
}

export function implementsUnsavedChanges(comp: unknown): comp is IUnsavedChanges {
    if (typeof comp === "object" &&
        typeof comp["hasUnsavedChanges"] === "function"
    ) {
        return true;
    }

    throw new Error(`Component ${comp?.constructor?.name} must implement IUnsavedChanges interface!`);
}
