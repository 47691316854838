import { VIRTUAL_SCROLL_STRATEGY } from "@angular/cdk/scrolling";
import { Directive, Input, OnChanges, SimpleChanges, forwardRef } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { ListItem, MultiselectListComponent } from "./multiselect-list.component";
import { MultiselectVirtualScrollStrategy } from "./multiselect-virtual-scroll-strategy";

@UntilDestroy()
@Directive({
    selector: "[edgeMultiselectVirtualScroll]",
    providers: [
        {
            provide: VIRTUAL_SCROLL_STRATEGY,
            useFactory: (d: MultiselectVirtualScrollDirective) => d.scrollStrategy,
            deps: [forwardRef(() => MultiselectVirtualScrollDirective)]
        }
    ]
})
export class MultiselectVirtualScrollDirective implements OnChanges {
    scrollStrategy: MultiselectVirtualScrollStrategy;

    @Input() items: ListItem[];

    constructor(multiselect: MultiselectListComponent) {
        this.scrollStrategy = new MultiselectVirtualScrollStrategy(
            multiselect.idField, multiselect.averageRowHeight
        );
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.items.currentValue) {
            this.scrollStrategy.updateItems(changes.items.currentValue);
        }
    }
}
