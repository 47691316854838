import { Injectable } from "@angular/core";
import { MatSnackBar, MatSnackBarConfig } from "@angular/material/snack-bar";
import {
    NotificationComponent,
    META_DATA,
} from "../components/notification/notification.component";

@Injectable({
    providedIn: "root",
})
export class NotificationService {
    constructor(public notification: MatSnackBar) {}

    public info(data: string = META_DATA.info.data): void {
        this.open({ ...META_DATA.info, data });
    }

    public warning(data: string = META_DATA.warning.data): void {
        this.open({ ...META_DATA.warning, data });
    }

    public success(data: string = META_DATA.success.data): void {
        this.open({ ...META_DATA.success, data });
    }

    public error(data: string = META_DATA.error.data): void {
        this.open({ ...META_DATA.error, data });
    }

    // Custom notification, based on MatSnackBarConfig
    public custom(config: MatSnackBarConfig = { ...META_DATA.info }): void {
        this.open(config);
    }

    private open(config: MatSnackBarConfig): void {
        this.notification.openFromComponent(NotificationComponent, config);
    }
}
