import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { MatButtonModule } from "@angular/material/button";
import { MatIconModule } from "@angular/material/icon";
import { MatPaginatorModule } from "@angular/material/paginator";

import { PaginatorComponent } from "./paginator.component";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
    declarations: [PaginatorComponent],
    imports: [
        CommonModule,
        MatPaginatorModule,
        MatButtonModule,
        MatIconModule,
        MatSelectModule,
        MatFormFieldModule
    ],
    exports: [PaginatorComponent],
    providers: [],
})
export class PaginatorControlModule { }
