export enum Severity {
    Black = 7,
    Gray = 6,
    Blue = 5,
    High = 4,
    Medium = 3,
    Low = 2,
    Critical = 1,
    Healthy = 0,
}
