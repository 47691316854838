import { IPermissionCustom, PermissionCheckType } from "../interfaces/permission.interface";
import { PERMISSIONS } from "./permission.constant";

export const PERMISSIONS_CUSTOM: IPermissionCustom = {
    AccountManagementPage: {
        permissions: [
            PERMISSIONS.AccountManagementDashboardTicketsByPerson,
            PERMISSIONS.AccountManagementDashboardTicketsByPriority,
            PERMISSIONS.AccountManagementDashboardTicketsByCategory,
            PERMISSIONS.AccountManagementDashboardEvents,
            PERMISSIONS.AccountManagementDashboardAlarms,
            PERMISSIONS.AccountManagementDashboardBackups,
            PERMISSIONS.AccountManagementDashboardRestores,
        ],
        checkType: PermissionCheckType.Some,
    },
    AccountManagementTickets: {
        permissions: [
            PERMISSIONS.AccountManagementDashboardTicketsByPerson,
            PERMISSIONS.AccountManagementDashboardTicketsByPriority,
            PERMISSIONS.AccountManagementDashboardTicketsByCategory,
        ],
        checkType: PermissionCheckType.Some,
    },
    AccountManagementAlarms: {
        permissions: [
            PERMISSIONS.AccountManagementDashboardEvents,
            PERMISSIONS.AccountManagementDashboardAlarms,
        ],
        checkType: PermissionCheckType.Some,
    },
    MyAccount: {
        permissions: [
            PERMISSIONS.MyAccountManageUsers,
            PERMISSIONS.MyAccountManageRoles,
            PERMISSIONS.MyAccountManageTags,
            PERMISSIONS.MyProfilePage,
            PERMISSIONS.MyAccountAccountOverviewPage,
        ],
        checkType: PermissionCheckType.Some,
    },
    ManagedSystems: {
        permissions: [PERMISSIONS.SystemsPage, PERMISSIONS.LoadBalancerPage],
        checkType: PermissionCheckType.Some,
    },
    IPsIDs: {
        permissions: [PERMISSIONS.SecurityPage, PERMISSIONS.SecurityPageIpsIdsAttackTrend],
        checkType: PermissionCheckType.Some,
    },
    DashboardDeviceAlarmsPermissions: {
        permissions: [
            PERMISSIONS.DashboardDeviceAlarmsServers,
            PERMISSIONS.DashboardDeviceAlarmsFirewalls,
            PERMISSIONS.DashboardDeviceAlarmsStorageNasSan,
            PERMISSIONS.DashboardDeviceAlarmsVIPS,
        ],
        checkType: PermissionCheckType.Some,
    },
    HomeDeviceAlarmsPermissions: {
        permissions: [
            PERMISSIONS.HomePagePageBodyDeviceAlarmsServers,
            PERMISSIONS.HomePagePageBodyDeviceAlarmsFirewalls,
            PERMISSIONS.HomePagePageBodyDeviceAlarmsStorageNasSan,
            PERMISSIONS.HomePagePageBodyDeviceAlarmsVips,
        ],
        checkType: PermissionCheckType.Some,
    },
    DataProtectedBackupsPermissions: {
        permissions: [PERMISSIONS.DataProtectedPage, PERMISSIONS.DataProtectedBackups],
        checkType: PermissionCheckType.Every,
    },
    InternalVulnerabilityScansPermissions: {
        permissions: [PERMISSIONS.CompliancePageInternalVulnerabilityScans, PERMISSIONS.CompliancePageAgents],
        checkType: PermissionCheckType.Some,
    },
};
