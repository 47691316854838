import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatMenuModule } from "@angular/material/menu";
import { MatTabsModule } from "@angular/material/tabs";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatBadgeModule } from "@angular/material/badge";
import { MatIconModule } from "@angular/material/icon";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatInputModule } from "@angular/material/input";
import { MatButtonModule } from "@angular/material/button";
import { MultiselectListComponent } from "./multiselect-list.component";
import { ValidationErrorModule } from "../validation-error/validation-error.module";
import { PaginatorControlModule } from "../paginator/paginator.module";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MultiselectVirtualScrollDirective } from "./multiselect-virtual-scroll.directive";

@NgModule({
    declarations: [MultiselectListComponent, MultiselectVirtualScrollDirective],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        ValidationErrorModule,
        MatMenuModule,
        MatCheckboxModule,
        MatTabsModule,
        MatFormFieldModule,
        MatInputModule,
        MatBadgeModule,
        MatIconModule,
        MatTooltipModule,
        MatButtonModule,
        PaginatorControlModule,
        ScrollingModule,
    ],
    exports: [MultiselectListComponent],
    providers: [],
})
export class MultiselectListControlModule {}
