import { Component, ChangeDetectionStrategy } from "@angular/core";
import { UserStorageService } from "@core/services/storages/user-storage.service";

@Component({
    selector: "edge-top-bar-userinfo",
    templateUrl: "./top-bar-userinfo.component.html",
    styleUrls: ["./top-bar-userinfo.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TopBarUserinfoComponent {
    constructor(public userInfo: UserStorageService) {}

    public imageErrorHandler(event: Event): void {
        (event.target as HTMLImageElement).src =
            "/assets/icons/person_placeholder.svg";
    }
}
