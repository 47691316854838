<div class="edge-timeline">
    <div class="edge-timeline-label">
        <div class="edge-date">
            {{ time | edgeDate }}
        </div>
        <div class="edge-time">
            {{ time | edgeTime }}
        </div>
    </div>
    <ng-container *ngIf="!simpleView">
        <div class="edge-timeline-separator" [ngClass]="{'small-icon': iconSize === 'small'}">
            <div class="edge-timeline-icon">
                <ng-content select="[icon]"></ng-content>
            </div>
        </div>
        <div class="edge-timeline-content">
            <ng-content select="[body]"></ng-content>
        </div>
    </ng-container>
</div>
