<div>
    <div class="edge-dialog-header">
        <span class="edge-dialog-title flexible">Custom Settings</span>
        <a id="top-bar-close__link" href="javascript:void(0)" class="edge-dialog-close" mat-dialog-close>
            <mat-icon svgIcon="close"></mat-icon>
        </a>
    </div>
    <div class="edge-dialog-content" mat-dialog-content>
        <div class="edge-form-group column top-bar-date" [formGroup]="dateForm">
            <div class="form-group-ctrl">
                <mat-form-field>
                    <mat-label>Start Date</mat-label>
                    <input id="top-bar-date-from__input" matInput formControlName="dateFrom" placeholder="Select Start Date"
                           [matDatepicker]="pickerFrom" [min]="minDate" [max]="selectedToValue" required>
                    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
                    <mat-datepicker #pickerFrom></mat-datepicker>
                    <mat-error>
                        <validation-error [control]="dateForm.controls.dateFrom"
                            [prioritizedErrors]="['matDatepickerParse']"></validation-error>
                    </mat-error>
                </mat-form-field>
            </div>
            <div class="form-group-ctrl">
                <mat-form-field>
                    <mat-label>End Date</mat-label>
                    <input id="top-bar-date-to__input" matInput formControlName="dateTo" placeholder="Select End Date"
                           [matDatepicker]="pickerTo" [min]="selectedFromValue" [max]="maxDate" required>
                    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                    <mat-datepicker #pickerTo></mat-datepicker>
                    <mat-error>
                        <validation-error [control]="dateForm.controls.dateTo"
                            [prioritizedErrors]="['matDatepickerParse']"></validation-error>
                    </mat-error>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div class="edge-dialog-footer" mat-dialog-actions>
        <button id="top-bar-date-cancel__button" mat-flat-button color="primary" (click)="close()">Cancel</button>
        <button id="top-bar-date-apply__button" mat-flat-button color="primary"
                [disabled]="dateForm.invalid"
                (click)="submit()">
            Apply
        </button>
    </div>
</div>
