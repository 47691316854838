import { Injectable } from "@angular/core";
import { EdgeDatePipe } from "@core/pipes/edge-date.pipe";
import { EdgeTimePipe } from "@core/pipes/edge-time.pipe";
import { SupportCallEvent, SupportTicket } from "@shared/interfaces/support.interface";
import { SupportMapService } from "../../portal/support/services/support-map.service";

export interface SupportTicketMapped {
    supportCallId: string;
    ticketNumber: string;
    _ticketNumber: number;
    status: string;
    openedBy: string;
    openedDate: string;
    lastUpdate: string;
    updatedBy: string;
    system: string[];
    summary: string;
    collapsedData: SupportTicketCollapsedData;
    hasAttachments: boolean;
    location: string;
}

export interface SupportTicketCollapsedData {
    billableHours: number;
    isLoading: boolean;
    priority: string;
    category: string;
    contact: string;
    email: string;
    summary: string;
    devices: string[];
    lastEvents: SupportCallEvent[];
    lastUpdated: string;
    lastUpdatedBy: string;
}

@Injectable()
export class TicketsHistoryMapService {
    constructor(
        private datePipe: EdgeDatePipe,
        private timePipe: EdgeTimePipe,
        private supportMapService: SupportMapService
    ) {}

    public getMappedData(data: SupportTicket[]): SupportTicketMapped[] {
        const newDataset: SupportTicketMapped[] = [];
        data.forEach(item => {
            newDataset.push({
                supportCallId: item.supportCallId,
                ticketNumber: `<a>${item.ticketNumber}</a>`,
                _ticketNumber: item.ticketNumber,
                status: item.statusName,
                openedBy: item.openByUserFullName,
                openedDate: this.getMappedDate(item.openDate),
                lastUpdate: this.getMappedDate(item.lastEventOpenDate),
                updatedBy: item.lastEventOpenByName,
                system: item.products.map(p => p.assetNumber),
                summary: item.summary,
                collapsedData: this.getCollapsedData(item),
                hasAttachments: item.hasAttachments,
                location: item.location
                    ? this.supportMapService.getDataCenterLabel(item.location)
                    : null,
            });
        });
        return newDataset;
    }

    private getMappedDate(dateStr: string): string {
        const date = this.datePipe.transform(dateStr);
        const time = this.timePipe.transform(dateStr);
        return `<div class='edge-date'>${date}</div><div class='edge-time'>${time}</div>`;
    }

    private getCollapsedData(item: SupportTicket): SupportTicketCollapsedData {
        return {
            billableHours: item.billableHours,
            priority: item.priorityEntry,
            category: item.category,
            contact: item.openByUserFullName, // repeated field
            email: `<a href='mailto:${item.openedByUserEmail}'>${item.openedByUserEmail}</a>`,
            summary: item.summary, // repeated field
            devices: item.products.map(p => p.assetNumber), // repeated field
            lastEvents: null,
            isLoading: false,
            lastUpdated: item.lastEventOpenDate,
            lastUpdatedBy: item.lastEventOpenByName,
        };
    }
}
