import { Injectable } from "@angular/core";
import { Observable } from "rxjs";

import { HttpClient } from "@angular/common/http";
import { webApiUrls } from "@core/constants/url.constant";
import { tap } from "rxjs/operators";
import { UserStorageService } from "@core/services/storages/user-storage.service";
import { OrganizationStorageService } from "@core/services/storages/organization-storage.service";
import { ExportFileTypes, PaginatedData } from "@shared/interfaces/api.interface";
import {
    GetVideoTutorialsRequestDTO,
    GetVideoTutorialsResponseDTO,
} from "../../portal/support/interfaces/video-tutorials.dto";
import {
    DataForTicketsHistoryFilters,
    TicketsHistoryReqParams,
} from "../../portal/support/interfaces/tickets-history.interface";
import {
    DataCenter,
    ILocation,
    Person,
    Priority,
    SupportCallEvent,
    SupportTicket,
} from "../interfaces/support.interface";
import {
    INewSupportTicket,
    SupportTicketLookupInfo,
} from "../../portal/support/interfaces/add-new-ticket.interface";
import {
    IAllLocationItem,
    IRequestRemoteHandsToApi,
} from "../../portal/support/interfaces/request-remote-hands.interface";
import {
    TicketDetails,
    TicketDetailsEvent,
    TicketEventReqForm,
} from "../../portal/support/interfaces/ticket-details.interface";
import { INewReserveConfRoom } from "../../portal/support/interfaces/reserve-conf-room.interface";
import { INewDeliveryTicket } from "../../portal/support/interfaces/new-delivery-ticket.interface";
import { FeedbackRequestParams } from "../interfaces/support-feedback.interface";
import { DownloadService } from "./download.service";

@Injectable()
export class SupportApiService {
    constructor(
        private http: HttpClient,
        private organizationService: OrganizationStorageService,
        private userStorageService: UserStorageService,
        private downloadService: DownloadService
    ) {}

    /** ********************* Tickets History ********************** */

    public getTicketsHistory(
        params: TicketsHistoryReqParams
    ): Observable<PaginatedData<SupportTicket>> {
        const path = `${webApiUrls.eventManagement}/TicketHistory`;
        return this.http.post<PaginatedData<SupportTicket>>(path, params);
    }

    public getFiltersForTicketsHistory(): Observable<DataForTicketsHistoryFilters> {
        const path = `${webApiUrls.eventManagement}/TicketHistory/FilterOptions`;
        return this.http.get<DataForTicketsHistoryFilters>(path);
    }

    public getTicketEvents(supportCallId: string): Observable<SupportCallEvent[]> {
        const path = `${webApiUrls.eventManagement}/TicketHistory/${supportCallId}/last5events`;
        return this.http.get<SupportCallEvent[]>(path);
    }

    public exportTicketsHistory(
        fileType: ExportFileTypes,
        options: TicketsHistoryReqParams
    ): Observable<any> {
        const path = `${webApiUrls.eventManagement}/TicketHistory/Export`;

        return this.http.post(path, { ...options, fileType }, { responseType: "blob" }).pipe(
            tap((file: Blob) => {
                if (file.size) this.downloadService.downloadFile(file, "support-calls", fileType);
            })
        );
    }

    public getAllDataCenters(): Observable<DataCenter[]> {
        const path = `${webApiUrls.accountManagement}/Users/DataCenters/All`;
        return this.http.get<DataCenter[]>(path);
    }

    /** ********************* New Ticket ********************** */

    public getDataCentersByCurrentUser(): Observable<DataCenter[]> {
        const path = `${webApiUrls.productManagement}/Products/DataCenters`;
        return this.http.get<DataCenter[]>(path);
    }

    public getNewSupportTicketLookupInfo(): Observable<SupportTicketLookupInfo> {
        const path = `${webApiUrls.eventManagement}/Tickets/TicketCreationData`;
        return this.http.get<SupportTicketLookupInfo>(path);
    }

    public saveNewSupportTicket(data: INewSupportTicket, attachedFiles: File[]): Observable<any> {
        const path = `${webApiUrls.eventManagement}/Tickets`;
        return this.http.post(path, this.transformIntoFormData(data, attachedFiles));
    }

    /** ********************* New Delivery Ticket && Reserve Conf Room********************** */

    public getLocations(): Observable<ILocation[]> {
        const path = `${webApiUrls.eventManagement}/DatacenterAccess/locations`;
        return this.http.get<ILocation[]>(path);
    }

    public getUsersForReserveConfRoom(): Observable<Person[]> {
        const path = `${webApiUrls.eventManagement}/Tickets/GetUsers`;
        return this.http.get<Person[]>(path);
    }

    public createNewDeliveryTicket(data: INewDeliveryTicket): Observable<{ number: number }> {
        const path = `${webApiUrls.eventManagement}/Tickets/NewDeliveryTicket`;
        return this.http.post<{ number: number }>(path, data);
    }

    public createNewReserveConfRoomTicket(data: INewReserveConfRoom): Observable<any> {
        const path = `${webApiUrls.eventManagement}/Tickets/ReserveConferenceRoom`;
        return this.http.post(path, data);
    }

    /** ********************* Ticket Details ********************** */

    public getTicketDetails(ticketNumber: number): Observable<TicketDetails> {
        const path = `${webApiUrls.eventManagement}/Ticket?ticketNumber=${ticketNumber}`;
        return this.http.get<TicketDetails>(path);
    }

    public getTicketDetailsEvents(supportCallId: string): Observable<TicketDetailsEvent[]> {
        const path = `${webApiUrls.eventManagement}/Ticket/SupportCallEvents/${supportCallId}`;
        return this.http.get<TicketDetailsEvent[]>(path);
    }

    public downloadAttachment(attachmentId: string, fileName: string): Observable<Blob> {
        const token = encodeURIComponent(this.userStorageService.getFileToken());
        const orgId = this.organizationService.activeOrganization?.id;
        const path = `${webApiUrls.eventManagement}/Ticket/Attachment/${attachmentId}?token=${token}&organizationId=${orgId}`;
        return this.http
            .get(path, { responseType: "blob" })
            .pipe(tap((file: Blob) => this.downloadService.downloadFile(file, fileName)));
    }

    public changeTicketStatus(supportCallId: string, statusId: string): Observable<void> {
        const path = `${webApiUrls.eventManagement}/Ticket/${supportCallId}/Status/${statusId}`;
        return this.http.put<void>(path, null);
    }

    public submitTicketEvent(data: TicketEventReqForm, files?: File[]): Observable<void> {
        const path = `${webApiUrls.eventManagement}/Ticket`;
        return this.http.post<void>(path, this.transformIntoFormData(data, files));
    }

    public getPersonPhotoUrl(personId: string): string {
        const fileToken = this.userStorageService.getFileToken();
        return `${webApiUrls.accountManagement}/UserPhoto/?token=${encodeURIComponent(
            fileToken
        )}&userId=${personId}`;
    }

    /** ********************* Request Remote Hands ********************** */

    public getPriorities(): Observable<Priority[]> {
        const path = `${webApiUrls.eventManagement}/Tickets/GetPriorities`;
        return this.http.get<Priority[]>(path);
    }

    public getAllLocations(): Observable<IAllLocationItem[]> {
        const path = `${webApiUrls.eventManagement}/DatacenterAccess/allLocations`;
        return this.http.get<IAllLocationItem[]>(path);
    }

    public createNewRequestRemoteHandsTicket(
        data: IRequestRemoteHandsToApi,
        files?: File[]
    ): Observable<{ number: string }> {
        const path = `${webApiUrls.eventManagement}/Tickets/RequestRemoteHands`;
        return this.http.post<{ number: string }>(path, this.transformIntoFormData(data, files));
    }

    public getSummaryByTicketTemplate(templateId: string): Observable<string> {
        const path = `${webApiUrls.eventManagement}/Tickets/getSummaryByTicketTemplate`;
        return this.http.get<string>(path, {
            params: {
                supportCallTemplateId: templateId,
            },
        });
    }

    /** ********************* Feedback ********************** */
    public postFeedback(params: FeedbackRequestParams, supportId: string): Observable<void> {
        return this.http.post<void>(
            `${webApiUrls.eventManagement}/Tickets/${supportId}/Feedback`,
            params
        );
    }

    /** ********************* Billing Inquiry ********************** */
    public crateBillingInquiryTicket(
        data: INewSupportTicket,
        attachedFiles: File[]
    ): Observable<any> {
        const path = `${webApiUrls.eventManagement}/Tickets/BillingInquiry`;
        return this.http.post(path, this.transformIntoFormData(data, attachedFiles), {
            responseType: "json",
        });
    }

    public getVideoTutorials(): Observable<GetVideoTutorialsResponseDTO> {
        const url = `${webApiUrls.accountManagement}/VideoTutorial/AllWithVotes`;

        return this.http.get<GetVideoTutorialsResponseDTO>(url);
    }

    public sendVideoVote(requestBody: GetVideoTutorialsRequestDTO): Observable<null> {
        const url = `${webApiUrls.accountManagement}/VideoTutorial/Vote`;

        return this.http.post<null>(url, requestBody);
    }

    private transformIntoFormData(data: Object, attachedFiles: File[]): FormData {
        const formData = new FormData();
        if (attachedFiles) {
            attachedFiles.forEach(file => {
                formData.append("attachedFiles", file, file.name);
            });
        }
        formData.append("data-json", JSON.stringify(data));
        return formData;
    }
}
